const TOGGLE_LOADING = 'TOGGLE_LOADING'
const SET_MARCO_DATA = 'SET_MARCO_DATA'

export const state = () => ({
  marcoData: {},
  isLoading: false
})

export const mutations = {
  [TOGGLE_LOADING](state, data) {
    state.isLoading = data
  },
  [SET_MARCO_DATA](state, data) {
    state.marcoData = data
  }
}

export const getters = {
  getMarcoList: state => () => {
    const list = state.marcoData ? Object.keys(state.marcoData) : []
    return { list }
  }
}

export const actions = {
  async fetchMarcoData({ commit }, params) {
    commit(TOGGLE_LOADING, true)
    let marcoData
    try {
      marcoData = await this.$axios.$get('getAllMacro', {})
    } catch (exp) {
      console.log(exp)
    }
    const { code, data } = marcoData
    const sets =
      code === 200
        ? data || {}
        : {
            code: 200,
            msg: 'success',
            data: {
              '{ad_type}': '广告类型',
              '{affiliate_id}':
                'Mintegral 平台子渠道 ID,传递 此值有利于后续投放质量优化',
              '{android_id_md5}':
                'Android ID MD5 加密(32 位小写),与{android_id}二选一',
              '{android_id}': 'Android 设备独有 Android Id',
              '{click_id}': 'Mintegral 平台定义的点击唯一标志',
              '{country_code}': 'ISO 3166-1国际标准,二位字母代码(alpha-2)',
              '{creative_id}': '素材 ID',
              '{creative_name}': '素材名称',
              '{device_id}': '设备广告标识符, iOS 传递 idfa, Android 传递 gaid',
              '{device_model}': '设备型号',
              '{device_os}': 'Android or iOS',
              '{gaid_md5}': 'GAID MD5 加密(32 位小写),与 {gaid} 二选一',
              '{gaid}':
                'Google 广告 ID, 可以唯一标识一台Android设备, 推广海外地区 Android 应用必传',
              '{idfa_md5}': 'IDFA MD5 加密(32 位小写),与 {idfa} 二选一',
              '{idfa}': 'iOS 设备广告标识符, 投放 iOS 广告必选',
              '{imei_md5}': 'IMEI MD5 加密(32 位小写),与 {imei} 二选一',
              '{imei}':
                'Android 设备标识符, 推广中国地区 Android 应用必传,Android Q(10)版本之后禁止使用',
              '{impression_id}': 'Mintegral 平台定义的展示唯一标志',
              '{ip}':
                '设备网络IP地址, 支持指纹识别归因(Fingerprint attribution ip+ua)必选',
              '{mac_mad5}': 'MAC MD5 加密(32 位小写),与 {mac} 二选一',
              '{mac}':
                '它是一个用确认网络设备位置的位址,以 MAC 地址匹配归因的必传',
              '{offer_name}': '客户在 Mintegral 自定义的广告单元名称',
              '{os_platform}': '系统平台',
              '{os_version}': '系统版本',
              '{package_name}': '渠道包名',
              '{ua}':
                '用户UA信息, 支持指纹识别归因(Fingerprint attribution ip+ua)必选',
              '{uuid}': 'Mintegral 平台定义的广告推广唯一标志'
            }
          }
    commit(SET_MARCO_DATA, sets)
    commit(TOGGLE_LOADING, false)
    return { code, data }
  }
}
