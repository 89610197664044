import Cookies from 'js-cookie'

const localeCookie = {
  key: 'language',
  get() {
    return Cookies.get(this.key)
  },
  getMainDomain() {
    const { hostname } = window.location
    const lastDotIndex = hostname.lastIndexOf('.')
    if (-1 < lastDotIndex) {
      // get main domain
      return hostname.substring(hostname.lastIndexOf('.', lastDotIndex - 1))
    }
    return hostname
  },
  set(locale) {
    const date = new Date()
    const domain = this.getMainDomain()
    const time = date.getTime() + 365 * 60 * 60 * 24 * 1000 // 1 year later
    Cookies.set(this.key, locale, {
      expires: new Date(time),
      domain
    })
    // clear nuxt-i18n auto set cookies with full domain
    setTimeout(() => localeCookie.removeFullDomain(), 0)
  },
  removeFullDomain(config) {
    const domain = this.getMainDomain()
    const { hostname } = window.location
    if (hostname != domain) {
      Cookies.remove(this.key)
    }
  }
}

export default function({ app, route, redirect }) {
  // set init value
  const locales = app.i18n.locales.map(item => item.code)
  let locale = localeCookie.get()
  const isIndex = '/' === route.path && !Object.keys(route.query).length
  if (isIndex && (!locale || -1 == locales.indexOf(locale))) {
    // no cookies, detect default locale
    const browserLang =
      navigator &&
      navigator.language
        .toLocaleLowerCase()
        .substring(0, 2)
        .replace('zh', 'cn') // use cn as zh
    locale = browserLang || app.i18n.defaultLocale
    localeCookie.set(locale)
  } else if (!isIndex && locale !== app.i18n.locale) {
    localeCookie.set(app.i18n.locale)
  }

  // beforeLanguageSwitch called right before setting a new locale
  app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale) => {
    // update cookies
    localeCookie.set(newLocale)
    app.store.commit('resetDataOptions')
    // re-fetch options
    // app.store.dispatch('fetchOptions', true)
  }

  document.addEventListener('visibilitychange', function() {
    if (document.visibilityState === 'visible') {
      // 标签页变为可见状态，用户正在查看
      // console.log('当前标签页可见')
      const lang = localeCookie.get()
      // console.log(lang)
      if (lang !== app.i18n.locale) {
        // console.log('重新设置cookie')
        localeCookie.set(app.i18n.locale)
      }
    } else {
      // 标签页变为隐藏状态，用户切换到其他标签页或最小化了当前窗口
      // console.log('当前标签页不可见')
    }
  })
}
