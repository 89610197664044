import SESDK from './web-sesdk'
import parametersPlugin from './sesdk-parameters-plugin'

export default () => {
  if (process.env.BUILD_ENV !== 'production') return undefined
  SESDK.use(parametersPlugin)
  SESDK.setRemoteDefaultConfig([
    [
      {
        name: 'abtest', // 配置项的名称，对应fastFetchRemoteConfig接口的参数 key
        type: 1, // 配置项的类型 1 string、2 number、3 boolean、4 json
        value: 'test' // 配置项的值
      }
    ]
  ])
  SESDK.init({
    userId: '5c5a2f0fd35d9296',
    appKey: '4b3c59aef3dbd74f',
    config: {
      remoteConfig: {
        enable: true,
        pollingInterval: 30
      },
      autoTrackConfig: {
        autoTrack: true,
        autoTrackPageView: true,
        autoTrackPageClick: true,
        isTrackSinglePage: true
      }
    }
  })
  SESDK.ready(function() {
    const a = SESDK.fastFetchRemoteConfig('abtest')
    console.log('[config]:', a)
  })
}
