export default class AccountService {
  constructor(context) {
    this.$axios = context.$axios
    this.cache = context.cache
  }

  /**
   * 开票
   */
  // async applyInvoice(params) {
  async applyInvoice(params) {
    try {
      const { data, code, msg } = await this.$axios.$post('applyInvoice', {
        id: params.id,
        invoice_company: params.invoice_company,
        invoice_address: params.invoice_address
      })
      if (code === 200) {
        return [null, data]
      } else {
        return [msg, null]
      }
    } catch (err) {
      return [err, null]
    }
  }
}
