// TODO: Creative Set 重复逻辑，暂时写在这

/**
 * 
 * A. creative set name为文本框，输入字符类型不限，文本框失焦，需要进行如下校验：

最多只能输入100个字符，若输入不满足要求，文本框失焦后，标红文本框边框，并在下方显示文案Maxim length of Creative set name is 100.（素材组命名不可超过100个字符）
不可与offer中已有的素材组名重复，若输入不满足要求，文本框失焦后，标红文本框边框，并在下方显示文案Creative set name is already existed in offer（素材组名在广告单元中已存在）
 * @param {*} value 
 * @return {Boolean} 
 */
export function validateCreativeSetName(value) {
  if (value && value.length <= 100) {
    return true
  }
  return false
}

/**
 * 检查包名是否符合Android google play包名规范
 * @param {*} packageName
 * @return {Boolean}
 */
export function isAndroidPackageName(packageName) {
  if (packageName && packageName.length > 0) {
    const wordRegexp = /^[A-Za-z-_]/
    const specialChartRegexp = /[`~!@#$%^&*()\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im
    if (
      wordRegexp.test(packageName[0]) &&
      packageName.indexOf('.') > 0 &&
      !specialChartRegexp.test(packageName)
    ) {
      return true
    }
    return false
  } else {
    return false
  }
}

/**
 * 检查包名是否符合iOS appstore包名规范
 * @param {*} packageName
 * @return {Boolean}
 */
export function isIOSPackageName(packageName) {
  const reg = /^id\d+$/
  if (reg.test(packageName)) return true
  return false
}
