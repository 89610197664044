/**
 * @author Penny
 * @description growing io monitor
 * @see https://growingio.gitbook.io/docs/sdk-integration/web-js-sdk/web-js-sdk-api
 */

import md5 from 'md5'
import moment from 'moment'
import { PT_ACTIVITY_ID } from '@/config/constant.js'
const gio = window.gio

export const loginMonitor = {
  setUserId(id) {
    if (id) {
      // to decode md5, plz visit https://www.somd5.com/
      // const secretId = md5(id + '')
      // gio('setUserId', secretId)
      gio('setUserId', id || 'unknown')
    } else {
      gio('clearUserId')
    }
  }
}

export const apiMonitor = {
  key: 'api_monitor',
  record: {},
  onRequest(url) {
    if (!url || 'string' != typeof url) return
    const startTime = +new Date()
    if (this.record[url]) {
      console.warn('The same API start', url)
    }
    this.record[url] = startTime
  },
  onResponse(url, code) {
    if (!url || 'string' != typeof url) return
    const endTime = +new Date()
    const startTime = this.record[url]
    if (!startTime) return console.warn('API start time not found', url)
    delete this.record[url]
    const time = parseInt(endTime - startTime)
    const duration = isNaN(time) ? '--' : moment(time).format('ss:SSS')
    const data = {
      status_code: code || '',
      api_path: url,
      api_duration_ms: time,
      api_duration: duration
    }
    gio('track', this.key, data)
  }
}

export const attractTrafficMonitor = {
  key: 'attract_traffic_click',
  userid: undefined,
  setUserId(id) {
    if (id) {
      const secretId = md5(id + '')
      this.userid = secretId
    } else {
      gio('clearUserId')
      this.userid = null
    }
  },
  onClick(source) {
    const date = moment().format('YYYY-MM-DD')
    const data = {
      source,
      date,
      pt_activity_id: PT_ACTIVITY_ID
    }
    gio('track', this.key, data)
    // @TODO:  logging
  },
  pv(source) {
    const date = moment().format('YYYY-MM-DD')
    const key = 'attract_traffic_visible'
    const data = {
      date: date,
      source: source,
      userid: this.userid,
      pt_activity_id: PT_ACTIVITY_ID
    }
    gio('track', key, data)
  }
}
