<template>
  <div>
    <el-input
      :value="value"
      class="f-hiden-tag"
      @input="$emit('input', arguments[0])"
    />
    <template v-if="isShowReCaptcha">
      <tencent-re-captcha
        v-if="!fallback"
        ref="newReCaptcha"
        @verify="$emit('input', arguments[0])"
        @tencent-error="fallbackToGoogle"
      />
      <vue-recaptcha
        v-else
        ref="reCaptcha"
        :sitekey="mxEnum.ReCaptcha.key"
        @verify="
          $emit('input', { captcha_type: 'google', captcha: arguments[0] })
        "
      />
    </template>
  </div>
</template>

<script>
import TencentReCaptcha from './TencentReCaptcha.vue'
import VueRecaptcha from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha,
    TencentReCaptcha
  },
  props: {
    value: {
      type: [Object],
      default: undefined
    }
  },
  data() {
    return {
      isShowReCaptcha: false,
      fallback: false
    }
  },
  head() {
    return {
      script: [
        {
          src: this.$root.mxEnum.ReCaptcha.script,
          async: true,
          defer: true
        }
      ]
    }
  },
  mounted() {
    this.isShowReCaptcha = true
  },
  methods: {
    reset() {
      if (this.$refs.reCaptcha) {
        this.$refs.reCaptcha.reset()
      }
      if (this.$refs.newReCaptcha) {
        this.$refs.newReCaptcha.reset()
      }
    },
    fallbackToGoogle() {
      this.fallback = true
    }
  }
}
</script>
