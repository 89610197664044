export function getObjectLength(obj) {
  let count = 0
  function dfs(o) {
    Object.keys(o).forEach(k => {
      if (typeof o[k] === 'object') {
        dfs(o[k])
      } else {
        count++
      }
    })
  }

  if (typeof obj === 'object') {
    dfs(obj)
    return count
  } else {
    throw new Error('argument is not an object')
  }
}
