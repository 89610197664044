<template>
  <el-dropdown :show-timeout="0">
    <span class="f-pointer" :class="theme === 'white' ? 'text-white' : ''">
      <!-- <i class="ss-v2-iconfont ss-v2-icon-common-globe bg-gray-100 font-22"></i> -->
      <span>
        <span class="hidden-md-and-down">{{ dataLocale.current.name }}</span>
        <span class="hidden-lg-and-up">
          {{ dataLocale.current.shortName }}
        </span>
      </span>
      <i class="el-icon-arrow-down el-icon--right" />
    </span>
    <template v-slot:dropdown>
      <el-dropdown-menu class="elm-user-dropdown-lang">
        <el-dropdown-item v-for="locale in dataLocale.list" :key="locale.code">
          <nuxt-link :to="switchLocalePath(locale.code)">
            <span class="hidden-md-and-down">{{ locale.name }}</span>
            <span class="hidden-lg-and-up">{{ locale.shortName }}</span>
          </nuxt-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {}
  },
  computed: {
    dataLocale() {
      const obj = {
        current: {},
        list: []
      }
      this.$i18n.locales.forEach(locale => {
        if (locale.code == this.$i18n.locale) {
          obj.current = locale
        } else {
          obj.list.push(locale)
        }
      })
      return obj
    }
  }
}
</script>

<style lang="scss" scoped>
.elm-side-dropdown {
  color: $header-text-color;
}

.elm-user-dropdown-lang /deep/ {
  overflow: hidden;

  .el-dropdown-menu__item {
    padding: 0;

    a {
      padding: 0 px2rem(20px);
    }
  }
}
</style>
