<template>
  <div
    class="flex text-[12px] text-[#A1A7C4] justify-center items-center gap-[16px]"
  >
    Copyright {{ currentYear }} Mintegral All rights reserved.
    <div class="h-[16px] w-[1px] bg-[rgba(0,0,0,0.08)]"></div>
    {{ mxVm$t('attractTraffic.friendLink') }}
    <a
      v-attract-click="'copyright_xmp'"
      class="friend-link xmp"
      :href="mxVm$t('attractTraffic.xmp.link')"
      target="_blank"
    ></a>
    <a
      v-if="hasLogin"
      v-attract-click="'copyright_pt'"
      class="friend-link pt"
      href="javascript: void(0)"
      @click="turnToPlayturbo"
    ></a>
    <a
      v-else
      v-attract-click="'copyright_pt'"
      class="friend-link pt"
      :href="mxVm$t('attractTraffic.pt.link')"
      target="_blank"
    ></a>
  </div>
</template>

<script>
export default {
  props: {
    currentYear: {
      default: '',
      type: [String, Number]
    },
    hasLogin: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    async turnToPlayturbo() {
      this.$store.commit('account/setIsLoading', true)
      const { id } = this.$store.state.account.basicInfo
      const { code, data } = await this.$axios.$get('accountPlayturbo', {
        params: { adv_user_id: id }
      })
      this.$store.commit('account/setIsLoading', false)
      if (code == 200 && data) {
        const { redirect_url } = data
        window.open(redirect_url, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.friend-link {
  height: 14px;
  cursor: pointer;
  transition: background 0.3s;
  &.xmp {
    &:hover {
      background: url('~@/assets/img/link/cr_xmp_highlight.svg?ignore')
        no-repeat;
    }
    background: url('~@/assets/img/link/cr_xmp.svg?ignore') no-repeat;
    width: 25px;
  }
  &.pt {
    &:hover {
      background: url('~@/assets/img/link/cr_pt_highlight.svg?ignore') no-repeat;
    }
    background: url('~@/assets/img/link/cr_pt.svg?ignore') no-repeat;
    width: 68px;
  }
}
</style>
