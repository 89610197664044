<template>
  <div>
    <div
      v-if="visible"
      class="fixed z-[2000] w-[200px] right-4 bottom-0 text-[12px] bg-white pb-3 version-alert"
    >
      <div class="bg-[#00a68f] text-white py-1 px-2">
        {{ mxVm$t('versionDetect.prompt') }}
      </div>
      <div class="py-2 px-2 ">
        {{ mxVm$t('versionDetect.newVersion') }}
      </div>
      <div class=" text-center">
        <el-button type="primary" size="mini" @click="onReload">
          {{ mxVm$t('versionDetect.refreshApp') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash-es'
const Interval = {
  timer: null,
  setInterval: function(callback, interval) {
    let startTime = new Date().valueOf()
    let endTime = new Date().valueOf()
    const self = this
    const loop = function() {
      self.timer = requestAnimationFrame(loop)
      endTime = new Date().valueOf()
      if (endTime - startTime >= interval) {
        endTime = startTime = new Date().valueOf()
        callback && callback()
      }
    }
    this.timer = requestAnimationFrame(loop)
    return this.timer
  },
  clearInterval: function() {
    cancelAnimationFrame(this.timer)
  }
}

export default {
  data() {
    return {
      visible: false
    }
  },
  created() {
    this.version = process.env.version
    this.initPoll()
  },
  methods: {
    initPoll() {
      Interval.setInterval(() => {
        this.fetchVersion()
      }, 1000 * 60)
    },
    onReload() {
      window.location.reload()
    },
    alert() {
      this.visible = true
    },
    fetchVersion() {
      const url = process.env.versionDetectUrl
      if (typeof url !== 'string') return

      fetch(url)
        .then(res => res.json())
        .then(res => {
          const ver = get(res, 'version')
          if (!ver) return
          if (`v${ver}` === this.version) {
          } else {
            const needAlert = get(res, 'needAlert')
            if (needAlert) {
              this.alert()
              Interval.clearInterval()
            }
          }
        })
        .catch(err => {
          console.log('[version detect err]:', err)
        })
    }
  }
}
</script>

<style>
.version-alert {
  animation: versionAlert 2s ease-in-out infinite;
}
@keyframes versionAlert {
  0% {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  50% {
    box-shadow: 0 2px 18px 6px rgba(0, 0, 0, 0.3);
  }

  100% {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}
</style>
