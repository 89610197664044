import Vue from 'vue'

const hasPermission = function(permissions, key) {
  if (permissions?.[key] === 1) return true
  return false
}
Vue.directive('permission', {
  inserted(el, binding, vnode) {
    const actionPermissions = vnode.context.$store?.state?.account?.action
    if (!hasPermission(actionPermissions, binding.value))
      el.parentNode.removeChild(el)
  }
})

Vue.prototype.$hasPermission = function(key) {
  const store = this.$store?.state?.account?.action
  return hasPermission(store, key)
}
