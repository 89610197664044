const browser = (() => {
  const ua = navigator.userAgent.toLowerCase()
  return {
    isChrome: /chrome/.test(ua),
    isFirefox: /firefox/.test(ua),
    isSafari: /safari/.test(ua) && !/chrome/.test(ua),
    isIE: /msie /.test(ua)
  }
})()
const isMobile = 'undefined' !== typeof window.orientation
const device = {
  browser,
  isMobile,
  isSupported:
    browser.isMobile ||
    browser.isChrome ||
    browser.isFirefox ||
    browser.isSafari ||
    browser.isIE,
  recommend: 'Google Chrome',
  downloadUrl: 'https://www.google.com/chrome/'
}

export default device
