<template>
  <div v-loading="isLoading" class="smart-tree">
    <el-row class="f-mb-10">
      <el-button
        size="mini"
        round
        :title="mxVm$t('common.freezeTips')"
        @click="onSelectAll(true)"
        >{{ mxVm$t('common.selectAll') }}</el-button
      >
      <el-button
        size="mini"
        round
        :title="mxVm$t('common.freezeTips')"
        style="margin-left: 5px;"
        @click="onSelectAll(false)"
      >
        {{ mxVm$t('common.clearAll') }}
      </el-button>
      <el-button
        size="mini"
        round
        :title="mxVm$t('common.freezeTips')"
        style="margin-left: 5px;"
        @click="onExclude"
      >
        {{ mxVm$t('common.exclude') }}
      </el-button>
    </el-row>
    <el-row>
      <el-tree ref="tree" v-bind="$attrs" v-on="$listeners">
        <template v-for="slot in Object.keys($slots)" :slot="slot">
          <slot :name="slot" />
        </template>
      </el-tree>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'SmartTree',
  props: {
    triggerChange: {
      type: Function,
      default: undefined
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    // get all leaf node
    leafKeys() {
      const leaf = [] // check node should expand or not
      const data = this.$attrs.data || []
      data.forEach(root => {
        const queue = [root]
        while (queue.length) {
          const node = queue.shift()
          if (node.c && node.c.length) {
            queue.push(...node.c)
          } else {
            leaf.push(node.k)
          }
        }
      })
      return leaf
    }
  },
  created() {
    this.proxyMethods()
  },
  methods: {
    // init methods in el-tree
    proxyMethods() {
      const methods = [
        'filter',
        'updateKeyChildren',
        'getCheckedNodes',
        'setCheckedNodes',
        'getCheckedKeys',
        'setCheckedKeys',
        'setChecked',
        'getHalfCheckedNodes',
        'getHalfCheckedKeys',
        'getCurrentKey',
        'getCurrentNode',
        'setCurrentKey',
        'setCurrentNode',
        'getNode',
        'remove',
        'append',
        'insertBefore',
        'insertAfter'
      ]
      const self = this
      methods.forEach(method => {
        this[method] = function() {
          const { tree } = self.$refs
          return tree[method].apply(tree, arguments)
        }
      })
    },
    // select multi node
    onSelectMulti(result = []) {
      const { tree } = this.$refs
      this.isLoading = true
      setTimeout(() => {
        tree.setCheckedKeys(result)
        this.triggerChange && this.triggerChange(result)
        this.isLoading = false
      }, 500)
    },
    /**
     * @param {Boolean} selectAll true is select all , false is clear all
     */
    onSelectAll(selectAll) {
      let result = []
      if (selectAll) {
        result = [...this.leafKeys]
      }
      this.onSelectMulti(result)
    },
    // select all tree data
    onExclude() {
      const { tree } = this.$refs
      const selectLeafs = tree.getCheckedKeys(true)
      const selectLeafsMap = {}
      selectLeafs.forEach(item => (selectLeafsMap[item] = true))
      const excludeLeafs = this.leafKeys.filter(item => !selectLeafsMap[item])
      if (excludeLeafs.length) {
        this.onSelectMulti(excludeLeafs)
      } else {
        this.onSelectAll(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.f-mb-10 {
  margin-bottom: 10px;
}
</style>
