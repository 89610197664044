<template>
  <div class="text-[12px] text-[#5A607F]">
    <div
      v-for="item in apps"
      v-attract-click="`card_${item.key}`"
      :key="item.key"
      class=" p-[16px] my-[12px] cursor-pointer rounded-lg transition-all bgicon min-w-[140px] max-w-[280px] flex justify-between items-center last:mb-0"
      :class="{
        [item.key]: true,
        [item.bg]: true
      }"
      @click="jumpTo(item)"
    >
      <div>
        <div class="h-[18px]">
          <svg
            class="h-full"
            :style="{
              width: item.iconWidth
            }"
            aria-hidden="true"
          >
            <use :xlink:href="item.icon"></use>
          </svg>
        </div>
        <p class="mt-[4px] leading-[20px] text-[12px]">
          {{ mxVm$t(item.desc) }}
        </p>
      </div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.74408 14.7559C8.04448 15.0563 8.51717 15.0794 8.84408 14.8252L8.92259 14.7559L13.0893 10.5893C13.3897 10.2889 13.4128 9.81616 13.1586 9.48925L13.0893 9.41074L8.92259 5.24408C8.59715 4.91864 8.06951 4.91864 7.74408 5.24408C7.44367 5.54448 7.42057 6.01717 7.67475 6.34408L7.74408 6.42259L11.3208 10L7.74408 13.5774C7.44367 13.8778 7.42057 14.3505 7.67475 14.6774L7.74408 14.7559Z"
            fill="#7E84A3"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { PT_ACTIVITY_PERMISSION } from '@/config/constant.js'
import '~/assets/img/link/card_xmp.svg'
import '~/assets/img/link/card_pt.svg'
export default {
  props: {},
  data() {
    return {
      apps: [
        {
          key: 'xmp',
          desc: 'attractTraffic.xmp.desc',
          link: 'attractTraffic.xmp.link',
          icon: '#card_xmp',
          bg: 'hover:bg-[#2650FF]/20 bg-[#2650FF]/10',
          iconWidth: '29px'
        },
        {
          key: 'pt',
          desc: 'attractTraffic.pt.desc',
          link: 'attractTraffic.pt.link',
          bg: 'hover:bg-[#FEE7BA] bg-[#FFF1D9]',
          icon: '#card_pt',
          iconWidth: '77px'
        }
      ]
    }
  },
  methods: {
    jumpTo(item) {
      if (item.key === 'pt') {
        this.turnToPlayturbo()
      } else {
        window.open(this.mxVm$t(item.link), '_blank')
      }
    },
    async turnToPlayturbo() {
      if (this.$store.state.account.action[PT_ACTIVITY_PERMISSION]) {
        this.$store.commit('account/setIsLoading', true)
        const { code, data } = await this.$axios.$get('playturboSsid')
        this.$store.commit('account/setIsLoading', false)
        if (code == 200 && data) {
          const activityUrl = `${process.env.playableActivityUrl}?ss_id=${data.ss_id}&lang=${this.$i18n.locale}&place=3`
          window.open(activityUrl, '_blank')
        }
      } else {
        this.$store.commit('account/setIsLoading', true)
        const { id } = this.$store.state.account.basicInfo
        const { code, data } = await this.$axios.$get('accountPlayturbo', {
          params: { adv_user_id: id }
        })
        this.$store.commit('account/setIsLoading', false)
        if (code == 200 && data) {
          const { redirect_url } = data
          window.open(redirect_url, '_blank')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bgicon {
  background-repeat: no-repeat;
  background-position: right bottom;
}
.xmp {
  background-image: url('~@/assets/img/link/xmp_bg.svg?ignore');
}
.pt {
  background-image: url('~@/assets/img/link/pt_bg.svg?ignore');
}
</style>
