<template>
  <div :class="`lang-${$i18n.locale} m-particles`">
    <div class="absolute top-[12px] right-[16px] h-[24px] leading-[24px]">
      <the-dropdown-lang theme="white" />
    </div>
    <nuxt />
    <div id="particles_js_bg" class="m-particles-background" />
  </div>
</template>
<script>
import 'particles.js'
import TheDropdownLang from '~/components/layouts/TheDropdownLang.vue'
const particlesConfig = {
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 800
      }
    },
    color: {
      value: '#ffffff'
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 0,
        color: '#000000'
      },
      polygon: {
        nb_sides: 5
      }
    },
    opacity: {
      value: 0.9311960542540074,
      random: true,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false
      }
    },
    size: {
      value: 10,
      random: true,
      anim: {
        enable: false,
        speed: 12.18119527978683,
        size_min: 0.1,
        sync: false
      }
    },
    line_linked: {
      enable: true,
      distance: 160,
      color: '#ffffff',
      opacity: 0.4,
      width: 1
    },
    move: {
      enable: true,
      speed: 3,
      direction: 'none',
      random: true,
      straight: false,
      out_mode: 'bounce',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 2805.962670675185,
        rotateY: 3607.6662908680946
      }
    }
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: false,
        mode: 'bubble'
      },
      onclick: {
        enable: false,
        mode: 'push'
      },
      resize: true
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1
        }
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3
      },
      repulse: {
        distance: 200,
        duration: 0.4
      },
      push: {
        particles_nb: 4
      },
      remove: {
        particles_nb: 2
      }
    }
  },
  retina_detect: true
}

export default {
  head() {
    return {
      title: this.mxVm$t({
        id: 'common.pageTitle',
        values: { title: this.$store.state.pageTitle }
      })
    }
  },
  components: {
    TheDropdownLang
  },
  mounted() {
    window.particlesJS('particles_js_bg', particlesConfig)
  }
}
</script>

<style lang="scss" scoped>
.m-particles /deep/ {
  position: relative;
  height: 100vh;
  min-height: 580px;
  font-size: 14px;
  color: #fff;

  a {
    color: #fff;
  }
}

.m-particles-background {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    221deg,
    rgba(45, 209, 174, 0.8),
    #24a99e,
    #1f8a8c
  );
}
</style>
