<template>
  <div
    style="height: 100vh; position: relative;"
    :class="`lang-${$i18n.locale}`"
  >
    <!-- TODO use lang setting in html tag,
              but there is bug in el-tooltip of table-body.js of ElementUI,
              which cause deleting lang attr accidentally -->
    <div id="ad_block_detect" class="google-ad f-hiden-tag" />
    <el-container v-if="$store.state.account.isAuth" class="h-full">
      <h1 class="f-hiden-tag">{{ title }}</h1>
      <el-header
        class="w-full bg-white flex items-center px-8 justify-between text-[#5A607F] shadow-[0_1px_4px_0_rgba(19,21,35,0.08)]"
        height="40px"
        style="line-height: 40px; z-index: 1000;"
      >
        <brand-logo class="elm-brand-logo" theme="white" />
        <the-drawer class="hidden-md-and-up" />
        <the-user-bar />
      </el-header>
      <el-container class="flex-1 overflow-hidden">
        <el-aside
          class="bg-[#fff] shadow-[1px_0_0_0_#E6E9F4] h-full hidden-sm-and-down flex flex-col aside"
          width="255px"
        >
          <the-menu-v2
            class="elm-menu flex-1 min-h-0 overflow-y-auto"
            mode="vertical"
          />
          <div class="p-[16px]">
            <friend-link-cards />
          </div>
        </el-aside>

        <el-main class="px-[28px] py-[17px]">
          <div class="m-warning">
            <div class="">
              <ul>
                <li v-if="isShowAdBlockTips">
                  <i class="el-icon-warning" />
                  {{ this.mxVm$t('common.adBlockTips') }}
                  <el-button
                    type="text"
                    icon="el-icon-close"
                    @click="isShowAdBlockTips = false"
                  />
                </li>
                <li
                  v-if="
                    mxEnum.Yes ==
                      $store.state.account.balanceInfo.show_less_balance &&
                      false !== isShowBalanceTips
                  "
                >
                  <i class="el-icon-warning" />
                  {{ this.mxVm$t('account.insufficientBalance') }}
                  <el-button
                    type="text"
                    icon="el-icon-close"
                    @click="isShowBalanceTips = false"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div v-if="!isShowNoPermission" class="">
            <div class="m-page-info">
              <h2 class="leading-9 text-[24px] m-0 font-medium">
                {{ $store.state.pageTitle }}
              </h2>
              <el-breadcrumb separator="/" class="mt-1 text-[12px] leading-5">
                <el-breadcrumb-item
                  v-for="(item, index) in navList"
                  :key="item.key"
                >
                  <nuxt-link
                    v-if="index < navList.length - 1 && item.path"
                    :to="mxVmLocalePath(item.path)"
                  >
                    {{ mxVm$t(item.i18n) }}
                  </nuxt-link>
                  <span v-else>{{ mxVm$t(item.i18n) }}</span>
                </el-breadcrumb-item>
              </el-breadcrumb>
              <div class="m-page-info__buttons"></div>
            </div>
            <nuxt />
            <el-footer class="g-container" height="40px">
              <copyright :currentYear="currentYear" />
            </el-footer>
          </div>
          <no-permission-dialog v-else />
        </el-main>
      </el-container>
    </el-container>
    <basic-info-dialog :visible.sync="dialogConfig.basicInfoDialog.visible" />
    <el-dialog
      :visible.sync="dialogConfig.newYearDialog.visible"
      :title="mxVm$t('newYear.title')"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <p v-html="mxVm$t('newYear.content')"></p>
    </el-dialog>
    <layout-loading></layout-loading>
    <notification-dialog />
    <common-notification />
    <version-update-alert v-if="isProd" />
  </div>
</template>

<script>
import LayoutLoading from '~/components/layouts/LayoutLoading.vue'
import FriendLinkCards from '~/components/layouts/FriendLinkCards.vue'
import Copyright from '~/components/layouts/Copyright.vue'
import BrandLogo from '~/components/widgets/BrandLogo.vue'
import ContactUsBar from '~/components/widgets/ContactUsBar.vue'
import TheMenuV2 from '~/components/layouts/TheMenuV2.vue'
import TheUserBar from '~/components/layouts/TheUserBar.vue'
import TheDrawer from '~/components/layouts/TheDrawer.vue'
import NoPermissionDialog from '~/components/offers/NoPermissionDialog.vue'
import BasicInfoDialog from '~/components/account/BasicInfoDialog.vue'
import NotificationDialog from '~/components/layouts/NotificationDialog.vue'
import CommonNotification from '~/components/layouts/CommonNotification.vue'
import VersionUpdateAlert from '~/components/common/version-update-alert'

export default {
  components: {
    LayoutLoading,
    VersionUpdateAlert,
    FriendLinkCards,
    Copyright,
    BrandLogo,
    ContactUsBar,
    TheMenuV2,
    TheUserBar,
    TheDrawer,
    NoPermissionDialog,
    BasicInfoDialog,
    NotificationDialog,
    CommonNotification,
    VersionUpdateAlert
  },
  middleware: ['auth', 'webContent'],
  head() {
    return {
      title: this.mxVm$t({
        id: 'common.pageTitle',
        values: { title: this.$store.state.pageTitle }
      })
    }
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      isShowAdBlockTips: false,
      isShowBalanceTips: undefined,
      dialogConfig: {
        basicInfoDialog: { visible: false },
        newYearDialog: { visible: false }
      },
      isProd: process.env.BUILD_ENV === 'production'
    }
  },
  computed: {
    isShowBalance() {
      const apiFlag =
        undefined !== this.$store.state.account.balanceInfo.balance
      if (!apiFlag) return false

      const { name } = this.$route
      const list = ['index', 'add-funds', 'billing-management']
      if (name) {
        for (let i = 0; i < list.length; ++i) {
          if (-1 < name.indexOf(list[i])) return false
        }
      }
      return true
    },
    navList() {
      const navPaths = []
      const routePath = (this.$route.matched[0] || {}).path
      const { locale } = this.$i18n || {}

      const getNavPath = function({ key, i18n, path, children, more }) {
        if (key) {
          navPaths.push({ key, i18n, path })

          if (path) {
            if (path === '/') {
              path = ''
            }

            // match
            if (path === routePath || `/${locale}${path || ''}` === routePath) {
              throw new Error('break') // break recursion
            }
          }
        }

        // find in children
        const subClass = children || more || []
        subClass.forEach(child => {
          getNavPath(child)
        })

        // unmatch and remove
        navPaths.pop()
      }

      try {
        getNavPath({ children: this.$store.state.account.menu })
      } finally {
        /* eslint no-unsafe-finally: "off" */
        return navPaths
      }
    },
    isShowNoPermission() {
      return !this.navList.length
    },
    title() {
      const { navList = [] } = this
      const item = navList[navList.length - 1] || {}
      return this.mxVm$t(item.i18n)
    }
  },
  watch: {
    '$route.name'(newVal, oldVal) {
      this.$store.dispatch('account/fetchBalanceInfo')
    }
  },
  mounted() {
    this.$store.commit('setPageTitle', this.title)
    this.$store.dispatch('account/fetchBalanceInfo')
    this.detectAdBlock()
    this.checkAcquireUsers()
    const showNewYearAlert = window.sessionStorage.getItem('isShowLoginTips')
    if (showNewYearAlert == '1') {
      window.sessionStorage.setItem('isShowLoginTips', 0)
      this.newYearAlert()
    }
  },
  updated() {
    this.$store.commit('setPageTitle', this.title)
  },
  methods: {
    detectAdBlock() {
      const elm = document.getElementById('ad_block_detect')
      if (!elm || !elm.clientWidth || !elm.clientHeight) {
        this.isShowAdBlockTips = true
      }
    },
    checkAcquireUsers() {
      const { is_acquire_users } = this.$store.state.account.basicInfo
      const {
        mxEnum: { No }
      } = this.$root
      if (is_acquire_users == No) {
        this.dialogConfig.basicInfoDialog.visible = true
      }
    },
    newYearAlert() {
      const endDate = new Date('2023-01-27 23:59:59').getTime()
      const currentDate = new Date().getTime()
      if (currentDate > endDate) return
      this.dialogConfig.newYearDialog.visible = true
    }
  }
}
</script>

<style lang="scss">
.f-no-breadcrumb {
  .m-page-info {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.m-warning /deep/ {
  color: $--color-warning;
  background: rgba($--color-warning, 0.1);
  font-size: 14px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: 8px 0;
    }
  }

  .el-button {
    color: $--color-warning;
    padding: 0;
  }
}

.m-page-info /deep/ {
  position: relative;
  margin-bottom: 15px;

  .el-breadcrumb__inner.is-link,
  .el-breadcrumb__inner a {
    font-weight: 400;
  }
}
.m-page-info__buttons {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  height: 54px;
  line-height: 54px;
}
.aside {
  &.el-aside {
    overflow: hidden;
  }
}
.elm-menu::-webkit-scrollbar {
  width: 4px;
}
</style>
