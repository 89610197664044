<template>
  <div ref="drawer" class="elm-drawer-container f-pointer">
    <i class="el-icon-menu" @click="isShowDrawer = !isShowDrawer" />
    <transition name="slider">
      <div v-show="isShowDrawer" class="elm-drawer el-popper" :style="{ zIndex: zIndex }">
        <transition name="content">
          <div>
            <the-menu-v2 class="elm-side-menu" @select="onSelect" />
            <!-- <div :style="{ paddingLeft: '25px' }">
              <div class="f-pointer" @click="onLogout">
                {{ mxVm$t('common.logout') }}
              </div>
              <the-dropdown-lang class="elm-side-dropdown" />
            </div> -->
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import BrandLogo from '~/components/widgets/BrandLogo.vue'
import TheMenuV2 from '~/components/layouts/TheMenuV2.vue'
import TheDropdownLang from '~/components/layouts/TheDropdownLang.vue'

export default {
  components: {
    BrandLogo,
    TheMenuV2,
    TheDropdownLang
  },
  data() {
    return {
      isShowDrawer: false,
      zIndex: 2001
    }
  },
  watch: {
    isShowDrawer(val) {
      // if (val) {
      //   this.zIndex = this.getMaxZIndex()
      //   console.log(this.zIndex)
      // }
    }
  },
  mounted() {
    window.addEventListener('click', this.handleClickOutSide)
    // this.zIndex = this.getMaxZIndex()
    // console.log(this.zIndex)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickOutSide)
  },
  methods: {
    async onLogout() {
      // TODO Why
      // console.log(this.$i18n) // right
      const path = this.mxVmLocalePath('/login')
      await this.$store.dispatch('account/logout')
      // console.log(this.$i18n) // null
      if (!this.$store.state.account.isAuth) {
        // const path = this.mxVmLocalePath('/login') // error: this.$i18n is null
        this.$router.push({ path })
      }
    },
    handleClickOutSide(event) {
      // if showing drawer and click outside
      if (this.isShowDrawer && !this.$refs.drawer.contains(event.target)) {
        this.isShowDrawer = false
      }
    },
    getMaxZIndex() {
      var arr = [...document.body.querySelectorAll('*')].map(e => +window.getComputedStyle(e).zIndex || 0)
      const zIndex = arr.length ? Math.max(...arr) : 0
      return zIndex + 1
    },
    onSelect() {
      this.isShowDrawer = false
    }
  }
}
</script>

<style lang="scss" scoped>
.elm-drawer-container /deep/ {
  float: right;
  margin-left: 10px;
  font-size: 14px;

  .elm-drawer {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 70%;
    background-color: #fff;
    z-index: 2001;
    // padding: 20px 8px;
    overflow-y: auto;
  }

  // transition class
  .slider-enter-active,
  .slider-leave-active {
    transition: width 0.5s;
  }

  .slider-enter,
  .slider-leave-to {
    width: 0;
  }

  .content-enter-active {
    transition: opacity 1s;
  }

  .content-leave-active {
    transition: opacity 0.1s;
  }

  .content-enter,
  .content-leave-to {
    opacity: 0;
  }

  .elm-side-dropdown {
    color: $header-text-color;
  }
}
</style>
