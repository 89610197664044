export const CREATIVE_NAEM_REGEXP = /^[\u4e00-\u9fa5A-Za-z0-9_.\-=]{1,200}$/u

export const PAGE_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
  DETAIL: 'detail',
  COPY: 'copy'
}

export const IMAGE_CREATIVE_TYPES = [
  {
    k: 11,
    v: '插屏'
  },
  {
    k: 12,
    v: 'Banner'
  },
  {
    k: 13,
    v: '开屏'
  },
  {
    k: 14,
    v: 'logo'
  },
  {
    k: 15,
    v: '原生图片'
  }
]
export const VIDEO_CREATIVE_TYPES = [
  {
    k: 21,
    v: '视频&图片endcard'
  },
  {
    k: 22,
    v: '视频&playable'
  },
  {
    k: 23,
    v: '原生视频'
  }
]

export const CREATIVE_TYPE = {
  options: [
    ...IMAGE_CREATIVE_TYPES,
    ...VIDEO_CREATIVE_TYPES,
    {
      k: 31,
      v: 'Html'
    }
  ],
  tree: [
    {
      k: 1,
      v: '图片',
      c: IMAGE_CREATIVE_TYPES
    },
    {
      k: 2,
      v: '视频',
      c: VIDEO_CREATIVE_TYPES
    },
    {
      k: 3,
      v: 'Html',
      c: [
        {
          k: 31,
          v: 'Html'
        }
      ]
    }
  ]
}
export const PT_ACTIVITY_ID = 1
export const PT_ACTIVITY_PERMISSION = 16005
