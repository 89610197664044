<template>
  <nuxt-link
    :to="url || mxVmLocalePath($store.state.account.isAuth ? '/' : '/login')"
    :class="className"
  >
    <img src="~/assets/img/mintegral-logo.png" width="122px" alt="" srcset="">
  </nuxt-link>
</template>

<script>

export default {
  props: {
    url: {
      type: String,
      default: undefined
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: ''
    }
  },
  data() {
    const classBase = 'elm-brand-logo'
    const themeConfig = [] // ['white']
    const extraClass =
      themeConfig.indexOf(this.theme) > -1 ? ` ${classBase}-${this.theme}` : ''
    return {
      className: classBase + extraClass,
      size: {
        width: this.width || undefined,
        height: this.height || undefined
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.elm-brand-logo {
  svg,
  img {
    width: px2rem(130px);
    height: px2rem(30px);
    display: inline-block;
    vertical-align: middle;
  }
}

</style>
