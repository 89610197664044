import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import placeholder from '~/assets/img/lazyload-placeholder.gif'

export default () => {
  Vue.use(VueLazyload, {
    loading: placeholder,
    attempt: 1
  })
}
