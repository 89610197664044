var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`lang-${_vm.$i18n.locale}`,staticStyle:{"height":"100vh","position":"relative"}},[_c('div',{staticClass:"google-ad f-hiden-tag",attrs:{"id":"ad_block_detect"}}),_vm._v(" "),(_vm.$store.state.account.isAuth)?_c('el-container',{staticClass:"h-full"},[_c('h1',{staticClass:"f-hiden-tag"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('el-header',{staticClass:"w-full bg-white flex items-center px-8 justify-between text-[#5A607F] shadow-[0_1px_4px_0_rgba(19,21,35,0.08)] z-10",staticStyle:{"line-height":"40px"},attrs:{"height":"40px"}},[_c('div',{staticClass:"w-[223px] h-[24px] ml-[16px]"},[_c('img',{attrs:{"src":require("assets/img/mintegral-logo.png"),"width":"122px","alt":"","srcset":""}})]),_vm._v(" "),_c('the-user-bar')],1),_vm._v(" "),_c('el-container',{staticClass:"flex-1 overflow-hidden"},[_c('el-aside',{staticClass:"bg-[#F5F6FA] shadow-[1px_0_0_0_#E6E9F4] h-full scrollbar-none hidden-sm-and-down",attrs:{"width":"255px"}},[_c('the-menu',{staticClass:"elm-menu",attrs:{"mode":"vertical"}})],1),_vm._v(" "),_c('el-main',{staticClass:"px-[28px] py-[17px]"},[_c('div',{staticClass:"m-warning"},[_c('div',{},[_c('ul',[(_vm.isShowAdBlockTips)?_c('li',[_c('i',{staticClass:"el-icon-warning"}),_vm._v("\n                  "+_vm._s(this.mxVm$t('common.adBlockTips'))+"\n                  "),_c('el-button',{attrs:{"type":"text","icon":"el-icon-close"},on:{"click":function($event){_vm.isShowAdBlockTips = false}}})],1):_vm._e(),_vm._v(" "),(
                    _vm.mxEnum.Yes ==
                      _vm.$store.state.account.balanceInfo.show_less_balance &&
                      false !== _vm.isShowBalanceTips
                  )?_c('li',[_c('i',{staticClass:"el-icon-warning"}),_vm._v("\n                  "+_vm._s(this.mxVm$t('account.insufficientBalance'))+"\n                  "),_c('el-button',{attrs:{"type":"text","icon":"el-icon-close"},on:{"click":function($event){_vm.isShowBalanceTips = false}}})],1):_vm._e()])])]),_vm._v(" "),(!_vm.isShowNoPermission)?_c('div',{},[_c('nuxt')],1):_c('no-permission-dialog'),_vm._v(" "),(
              _vm.$store.state.account.basicInfo.pm_name ||
                _vm.$store.state.account.basicInfo.bd_name
            )?_c('contact-us-bar'):_vm._e()],1)],1)],1):_vm._e(),_vm._v(" "),_c('basic-info-dialog',{attrs:{"visible":_vm.dialogConfig.basicInfoDialog.visible},on:{"update:visible":function($event){return _vm.$set(_vm.dialogConfig.basicInfoDialog, "visible", $event)}}}),_vm._v(" "),_c('layout-loading')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }