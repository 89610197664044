<template>
  <div class="elm-user-bar flex justify-between items-center">
    <div>
      <span
        v-if="isShowBalance"
        class="float-left ml-[16px] hidden-sm-and-down"
      >
        <i
          class="ss-v2-iconfont ss-v2-icon-finance-wallet font-22 align-middle"
        ></i>
        <span>{{ mxVm$t({ id: 'account.balance' }) }}</span>
        <span>{{
          mxFormatNumber($store.state.account.balanceInfo.balance, {
            prepend: $store.state.account.basicInfo.currencySymbol
          })
        }}</span>
      </span>
    </div>

    <div class="flex items-center gap-6">
      <attract-dropdown />
      <contact-us-bar
        v-if="
          $store.state.account.basicInfo.pm_name ||
            $store.state.account.basicInfo.bd_name
        "
      />
      <the-dropdown-lang class="inline-block align-top" />
      <div
        v-if="
          $store.state.account.basicInfo.account_state == 2 ||
            (!isStateless && $store.state.account.basicInfo.name)
        "
        class="inline-block align-top"
      >
        <span class="u-text-limit">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ $store.state.account.basicInfo.name }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button type="text" @click="onLogout">{{
                  mxVm$t('common.logout')
                }}</el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </div>
      <span v-else class="inline-block align-top">
        <a
          :href="
            `https://www.mintegral.com/${
              'cn' == $i18n.locale ? 'cn' : 'en'
            }/selfservice`
          "
          target="_blank"
          class="margin-left-8"
        >
          {{ mxVm$t('login.aboutUs') }}
        </a>
        <span class="u-item-separator">|</span>
        <nuxt-link :to="mxVmLocalePath('/login')">
          {{ mxVm$t('login.text') }}
        </nuxt-link>
      </span>
    </div>
  </div>
</template>

<script>
import TheDropdownLang from '~/components/layouts/TheDropdownLang.vue'
import AttractDropdown from '~/components/layouts/AttractDropdown.vue'
import ContactUsBar from '~/components/widgets/ContactUsBar.vue'

export default {
  components: {
    TheDropdownLang,
    AttractDropdown,
    ContactUsBar
  },
  props: {
    isStateless: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isShowBalance() {
      const apiFlag =
        undefined !== this.$store.state.account.balanceInfo.balance
      if (!apiFlag) return false

      const { name } = this.$route
      const list = ['index', 'add-funds', 'billing-management']
      if (name) {
        for (let i = 0; i < list.length; ++i) {
          if (-1 < name.indexOf(list[i])) return false
        }
      }
      return true
    }
  },
  methods: {
    async onLogout() {
      // TODO Why
      // console.log(this.$i18n) // right
      const path = this.mxVmLocalePath('/login')
      await this.$store.dispatch('account/logout')
      // console.log(this.$i18n) // null
      if (!this.$store.state.account.isAuth) {
        // const path = this.mxVmLocalePath('/login') // error: this.$i18n is null
        this.$router.push({ path })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.elm-user-bar {
  flex-grow: 1;
  font-size: 16px;
  text-align: right;
  height: 39px;
}

.el-dropdown {
  display: inline;
  color: inherit;
  font-size: inherit;
}

.u-item-separator {
  margin: 0 px2rem(10px);
}

.u-text-limit {
  max-width: 125px; // 15 chars
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media #{map-get($--breakpoints-spec, 'md-and-down')} {
  .elm-user-bar {
    font-size: 14px;
  }
}

.elm-user-dropdown-lang /deep/ {
  .el-dropdown-menu__item {
    padding: 0;

    a {
      padding: 0 px2rem(20px);
    }
  }
}
</style>
