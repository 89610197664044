import Vue from 'vue'
import Enum from '~/config/enum.js'
import throttle from 'lodash.throttle'
import debounce from 'lodash.debounce'
import { get } from 'lodash-es'
import moment from 'moment'
import { formatQuery } from '~/utils'
import device from '~/utils/device.js'

const mixin = {
  data() {
    return {
      mxEnum: Enum,
      mxMoment: moment,
      mxDevice: device
    }
  },
  methods: {
    mxMomentUnix: moment.unix,
    mxThrottle: throttle,
    mxDebounce: debounce,
    mxGetUrlOrigin() {
      return window.location.origin
    },
    mxGet(...args) {
      return get(...args)
    },
    mxVm$t(i18n) {
      let str = ''
      const $t = this.$t.bind(this)
      if (typeof i18n == 'object') {
        str = $t(i18n.id)
        for (const key in i18n.values) {
          const realKey = key.replace('__id', '')
          const value = i18n.values[key]
          const realValue = realKey != key ? $t(value) : value
          str = str.replace(new RegExp(`{${realKey}}`, 'g'), realValue)
        }
      } else {
        str = $t(i18n)
      }
      // clear useless variable at beginning or end of template, replace useless placeholder
      return str.replace(/{\w+}を?/g, '').trim()
    },
    // 附带单复数识别
    mxVm$tc(i18n, ...args) {
      let str = ''
      const $t = this.$tc.bind(this)
      str = $t(i18n, ...args)
      return str.replace(/{\w+}を?/g, '').trim()
    },
    mxVmLocalePath(router = {}) {
      let routeName = router.name || 'index'
      const path = 'string' == typeof router ? router : router.path
      if (path) {
        routeName =
          path
            .slice(path.indexOf('/') + 1)
            .replace(/:/g, '')
            .replace(/\//g, '-') || 'index'
      }
      const url = this.localePath({
        name: routeName,
        params: router.params,
        query: router.query
      })
      return url
    },
    mxInitFixedCol(getOptions, cb) {
      const detectFixedCol = () => {
        const { ref, minWidth } = getOptions()
        if (!ref || !ref.$el) return
        const width = ref.$el.clientWidth
        if (!width) return
        const isFixCol = width < minWidth
        cb(isFixCol)
      }
      detectFixedCol()
      const handler = this.mxThrottle(detectFixedCol, 200)
      window.addEventListener('resize', handler, false)
      return handler
    },
    mxFlexibleTable(getOptions, cb) {
      const update = this.mxThrottle(() => {
        const { ref, minWidth } = getOptions()
        if (!ref || !ref.$el) return
        const width = ref.$el.clientWidth
        if (!width) return
        const isFixCol = width < minWidth
        cb(isFixCol)
      }, 200)
      const init = () => {
        update()
        window.addEventListener('resize', update, false)
      }
      const remove = () => {
        window.removeEventListener('resize', update)
      }
      return { update, init, remove }
    },
    mxSetQueryUrl(data) {
      const query = formatQuery(data)
      this.$router.push({ query })
    },
    mxCalculateDate(value) {
      if (!value || 'object' != typeof value) return
      const { timezone, days, lastDay, months, format = 'YYYY-MM-DD' } = value
      const date = {}
      const d = new Date()
      const timezoneOffset = timezone
        ? d.getTimezoneOffset() / 60 + timezone
        : 0
      const offset = timezoneOffset * 3600 * 1000
      const now = Date.now()
      let start = new Date(now + offset)
      let end = new Date(now + offset)
      if (undefined != days) {
        // days past
        start.setTime(start.getTime() - 3600 * 1000 * 24 * (days - 1))
      } else if (undefined != lastDay) {
        // day range
        start.setTime(start.getTime() - 3600 * 1000 * 24 * lastDay)
        end.setTime(start.getTime() - 3600 * 1000 * 24 * (lastDay - 1))
      } else if (undefined != months) {
        const { mxMoment } = this.$root
        // months past
        if (0 === months) {
          start = mxMoment(start)
            .date(1)
            .toDate()
        } else {
          const lastMonth = mxMoment(now + offset).subtract(months, 'months')
          start = mxMoment(lastMonth)
            .startOf('month')
            .toDate()
          end = mxMoment(lastMonth)
            .endOf('month')
            .subtract(1, 'seconds')
            .toDate()
        }
      }
      const { mxMoment } = this.$root
      date.startTime = mxMoment(start).format(format)
      date.endTime = mxMoment(end).format(format)
      return date
    },
    mxGetCurrentTime(timezone = 0, format = 'YYYY-MM-DD') {
      const timezoneOffset = timezone
        ? timezone - this.mxGetDefaultTimezone(true)
        : 0
      const now = new Date(Date.now() + timezoneOffset * 3600 * 1000)
      return now
    },
    mxGetTimeByDateAndZone({
      compareDate,
      compareTimezone,
      calculateTimezone
    }) {
      if (compareDate != null && compareTimezone != null) {
        const timestamp = new Date(compareDate).getTime()
        const timezoneOffset = calculateTimezone - compareTimezone
        const calculateDate = new Date(timestamp + timezoneOffset * 3.6e3 * 1e3)

        return calculateDate
      } else {
        return this.mxGetCurrentTime(calculateTimezone)
      }
    },
    mxGetDefaultTimezone(isUseLocale) {
      const date = new Date()
      const timezone = -(date.getTimezoneOffset() / 60)
      if (isUseLocale) {
        return timezone
      } else {
        const accountTimezone = this.$store.state.account.basicInfo.timezone
        return undefined !== accountTimezone ? accountTimezone : timezone
      }
    },
    mxGetDataLabel(field, isBodyOnly) {
      if ('string' != typeof field) return
      const fieldTmp = field.replace(/(adv_)|(_id)|(_name)/g, '')
      if (isBodyOnly) field = fieldTmp
      let key = `data.${fieldTmp}`
      let text = this.mxVm$t(key)
      if (key === text) {
        key = `${fieldTmp}.text`
        text = this.mxVm$t(key)
        if (key === text) {
          text = field
            .split(/[\s_-]+/)
            .map(item => {
              const firstChart = item.slice(0, 1)
              item = item.replace(firstChart, firstChart.toUpperCase())
              return item
            })
            .join(' ')
        }
      }
      let suffix = ''
      if (-1 < field.indexOf('_id')) {
        suffix = ' ID'
      } else if (-1 < field.indexOf('_name')) {
        text = this.mxVm$t({ id: 'common.name', values: { item: text } })
      }
      return text + suffix
    },
    mxFormattedData(field, value) {
      if (!value || 0 == value) return '--'
      const { DataSymbol } = this.$root.mxEnum
      const symbol = this.$store.state.account.basicInfo.currencySymbol
      const formatter = {
        prepend: -1 < DataSymbol.currency.indexOf(field) ? symbol : '',
        append: -1 < DataSymbol.percent.indexOf(field) ? '%' : '',
        hideZero: true
      }
      const isID = -1 < field.indexOf('_id')
      const isName = -1 < field.indexOf('_name')

      let body
      switch (true) {
        case isID:
        case isName:
          body = value
          break
        default:
          body = this.mxFormatNumber(value, formatter)
      }
      return body
    },
    mxInitValue(value = {}, defaultValue = {}, baseOnValue) {
      const result = Object.assign(
        {},
        true === baseOnValue ? value : defaultValue
      )
      for (const key in defaultValue) {
        const val = value[key]
        const deft = defaultValue[key]
        if (undefined == val || '' === val) {
          if (true === baseOnValue) result[key] = deft
          continue
        }
        if (Array.isArray(deft) && 'string' == typeof val) {
          if (0 == val.indexOf('[') && val.length - 1 == val.lastIndexOf(']')) {
            try {
              const data = JSON.parse(val)
              data.forEach(item => {
                item = this.mxInitValue(item)
              })
              result[key] = data
            } catch (error) {
              console.log(`Error in parse field ${key}:`, val)
              console.error(error)
            }
          } else {
            result[key] = val
              ? val.split(',').map(item => (isNaN(item) ? item : +item))
              : []
          }
        } else if ('boolean' == typeof deft) {
          const { Yes } = this.$root.mxEnum
          result[key] = Yes == val
        } else {
          result[key] = val
        }
      }
      return result
    },
    mxFormatValue(value = {}, isPure) {
      const result = Object.assign({}, value)
      for (const key in result) {
        // remove hidden key for submit
        if (0 == key.indexOf('h__')) {
          delete result[key]
          continue
        }
        const val = result[key]
        if (Array.isArray(val)) {
          if (
            val.length &&
            (Array.isArray(val[0]) || 'object' == typeof val[0])
          ) {
            if (0 == Object.keys(val[0]).length) continue
            result[key] = JSON.stringify(result[key])
          } else {
            result[key] += ''
          }
        } else if ('object' == typeof val && val) {
          result[key] = JSON.stringify(val)
        } else if ('boolean' == typeof val) {
          const { Yes, No } = this.$root.mxEnum
          result[key] = val ? Yes : No
        }
        if ('' === result[key] && isPure) {
          result[key] = undefined
        } else if (undefined == result[key] && false === isPure) {
          result[key] = ''
        }
      }
      return result
    },
    mxFormatValueV2(value = {}, isPure) {
      const result = Object.assign({}, value)
      for (const key in value) {
        // remove hidden key for submit
        if (0 == key.indexOf('h__')) {
          delete result[key]
          continue
        }
        const val = result[key]
        if (Array.isArray(val)) {
          if (
            val.length &&
            (Array.isArray(val[0]) || 'object' == typeof val[0])
          ) {
            if (0 == Object.keys(val[0]).length) continue
            result[key] = JSON.stringify(result[key])
          } else {
            // result[key].join(',')
            // result[key] += ''
          }
        } else if (val && 'object' == typeof val) {
          result[key] = JSON.stringify(val)
        } else if ('boolean' == typeof val) {
          const { Yes, No } = this.$root.mxEnum
          result[key] = val ? Yes : No
        }
        if ('' === result[key] && isPure) {
          result[key] = undefined
        } else if (undefined == result[key] && false === isPure) {
          result[key] = ''
        }
      }
      return result
    },

    mxInitQuery(constraint = {}, defaultValue = {}, queryPrefix = '') {
      const { query } = this.$route
      const result = Object.assign({}, defaultValue)
      if ('object' != typeof query || !query) return result
      for (const key in constraint) {
        let value = query[queryPrefix + key]
        if (!value) continue
        value += '' // make sure query value array to string

        // string to number or array
        if ('number' == typeof constraint[key]) {
          result[key] = +value
        } else if ('limit' != key && Array.isArray(constraint[key])) {
          result[key] = value.split(',')
          if ('number' == typeof constraint[key][0]) {
            result[key] = result[key].map(item => (item = +item))
          }
        } else {
          result[key] = value
        }

        // pagination
        if ('page' == key && constraint.page) {
          result[key] =
            constraint.page <= value ? parseInt(value) : defaultValue[key]
        }
        if ('limit' == key && Array.isArray(constraint.limit)) {
          result[key] =
            -1 < constraint.limit.indexOf(+value) ? value : defaultValue[key]
        }
        if ('order' == key) {
          const { descending, ascending } = this.$root.mxEnum.OrderBy
          result[key] =
            -1 < [descending, ascending].indexOf(value)
              ? value
              : defaultValue[key]
        }
      }
      return result
    },
    mxFormatQuery: formatQuery,
    mxGetColWidth(text, extra) {
      const w = this.$i18n.locale == 'ja' ? 15 : 8
      let len = text.length * w
      const max = 300
      const min = 100
      const arrow = 25 // for order arrow
      extra = extra && len + arrow > min ? arrow : 0
      len = max < len ? max : min > len ? min : len
      const padding = 15 * 2
      return len + padding + extra
    },
    mxFormatNumber(number, formatter = {}) {
      if (isNaN(number)) return number
      const { prepend = '', append = '', hideZero } = formatter
      if (!number && hideZero) return '--'
      const num = (number + '').split('.')
      num[0] = num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return prepend + num.join('.') + append
    },
    mxFormatSize(size) {
      if (typeof size !== 'number') {
        console.error('size is not number')
        return 'size param error'
      }
      const unit = ['B', 'KB', 'M', 'GB']
      let index = 0
      while (size >= 1024) {
        size = size / 1024
        index += 1
      }
      return `${size.toFixed(2)} ${unit[index]}`
    },
    async mxValidateByServer(rule, value, callback) {
      if ('function' == typeof rule.skip && rule.skip(value)) {
        return callback()
      }
      if (value && rule.api) {
        const { code, msg, data } = await this.$axios.$get(rule.api, {
          params: { [rule.field]: encodeURI(value) },
          __skipAutoNotify: true
        })
        if (code != 200) {
          return callback(new Error((data && data[rule.field]) || msg))
        }
      }
      return callback()
    },
    mxValidateByRegexp(rule, value, callback) {
      if (!value || !rule.regexp) return callback()
      if (!rule.regexp.test(value)) {
        const error = rule.message || this.mxVm$t('common.error')
        return callback(new Error(error))
      }
      return callback()
    },
    mxValidateNumber(rule, value, callback) {
      if (!value && 0 !== value) return callback()
      const number = +('-' == value ? 0 : value)
      const { decimal, min, max, isInt } = rule
      if (isNaN(number) || number < min || number > max) callback(rule.message)
      if (undefined !== decimal && number != number.toFixed(decimal)) {
        return callback(rule.message)
      }
      if (isInt && value + '' !== parseInt(value) + '') {
        return callback(rule.message)
      }
      return callback()
    },
    mxValidateNum(rule, value, callback) {
      const number = +value
      const decimal = rule.decimal || 2
      if (value && (number <= 0 || number != number.toFixed(decimal))) {
        return callback(
          new Error(
            this.mxVm$t({ id: 'rule.number', values: { num: decimal } })
          )
        )
      }
      return callback()
    },
    mxValidateInt(rule, value, callback) {
      const { min, max } = rule
      const number = +value
      if (
        value &&
        (number < min || number > max || number != parseInt(number))
      ) {
        return callback(
          new Error(this.mxVm$t({ id: 'rule.integer', values: { min, max } }))
        )
      }
      return callback()
    },
    mxValidateVersion(rule, value, callback) {
      if ('function' == typeof rule.skip && rule.skip(value)) {
        return callback()
      }
      const regexp = /^[0-9]{1,3}\.[0-9]{1,3}(\.[0-9]{1,3})?$/
      if (value && !regexp.test(value)) {
        return callback(new Error(this.mxVm$t('rule.version')))
      }
      return callback()
    },
    mxValidateName(rule, value, callback) {
      const regexp = /^\w+$/
      if (value && !regexp.test(value)) {
        return callback(
          new Error(this.mxVm$t('rule.letterNumberUnderlineOnly'))
        )
      }
      return callback()
    },
    mxValidateUrl(rule, value, callback) {
      if (value) {
        /* eslint no-useless-escape: "off" */
        const regexp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
        if (!regexp.test(value)) {
          return callback(new Error(this.mxVm$t('rule.url')))
        }
      }
      if ('function' == typeof rule.action) {
        rule.action(value)
      }
      return callback()
    },
    mxValidatePassword(rule, value, callback) {
      if (value) {
        const regexp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{8,20}$/
        if (!regexp.test(value)) {
          return callback(new Error(this.mxVm$t('rule.password')))
        }
        return callback()
      }
    },
    mxScrollToError() {
      setTimeout(() => {
        if (!document) return
        const elements = document.querySelectorAll('.el-form-item.is-error')
        const element = Array.from(elements).find(
          ele => ele.offsetParent !== null
        )
        element && element.scrollIntoView()
      }, 300)
    },
    mxGetTodayDate() {
      return this.$root.mxMoment().format('YYYY-MM-DD')
    },
    mxResetFormFields(props, form) {
      form = form || this // can bind this with form outside
      if (!form || !form.fields || !form.resetFields) return
      if (!props) {
        form.resetFields()
      } else {
        form.fields.forEach(item => {
          if (
            (Array.isArray(props) && -1 < props.indexOf(item.prop)) ||
            props == item.prop
          ) {
            item.resetField()
          }
        })
      }
    },
    mxNumber(val, options = {}) {
      if (isNaN(val)) return val
      if (options.decimal) {
        return Number(val).toFixed(options.decimal)
      }
    },
    mxDef(val) {
      return val !== undefined && val !== null
    },
    mxLog(v) {
      console.log(v)
    },
    mxJSONStringify(v) {
      try {
        return JSON.stringify(v)
      } catch (error) {
        return error + ''
      }
    },
    mxTranslateEnums(value, enums = []) {
      if (Array.isArray(value)) {
        return value.map(
          val =>
            enums.find(item => item.k == val) &&
            enums.find(item => item.k == val).v
        )
      } else {
        return (
          enums.find(item => item.k == value) &&
          enums.find(item => item.k == value).v
        )
      }
    },
    mxRevalidate(form, keys) {
      if (form && keys) {
        form.validateField(keys)
      }
    }
  }
}
Vue.mixin(mixin)

export default mixin
