<template>
  <div
    style="height: 100vh; position: relative;"
    :class="`lang-${$i18n.locale}`"
  >
    <!-- TODO use lang setting in html tag,
              but there is bug in el-tooltip of table-body.js of ElementUI,
              which cause deleting lang attr accidentally -->
    <div id="ad_block_detect" class="google-ad f-hiden-tag" />
    <el-container v-if="$store.state.account.isAuth" class="h-full">
      <h1 class="f-hiden-tag">{{ title }}</h1>
      <el-header
        class="w-full bg-white flex items-center px-8 justify-between text-[#5A607F] shadow-[0_1px_4px_0_rgba(19,21,35,0.08)] z-10"
        height="40px"
        style="line-height: 40px;"
      >
        <div class="w-[223px] h-[24px] ml-[16px]">
          <img src="~/assets/img/mintegral-logo.png" width="122px" alt="" srcset="">
        </div>
        <!-- <brand-logo class="elm-brand-logo" theme="white" />
     
        <the-drawer class="hidden-md-and-up" /> -->
        <the-user-bar />
      </el-header>
      <el-container class="flex-1 overflow-hidden">
        <el-aside class="bg-[#F5F6FA] shadow-[1px_0_0_0_#E6E9F4] h-full scrollbar-none hidden-sm-and-down" width="255px">
          <the-menu class="elm-menu" mode="vertical" />
        </el-aside>

        <el-main class="px-[28px] py-[17px]">
          <div class="m-warning">
            <div class="">
              <ul>
                <li v-if="isShowAdBlockTips">
                  <i class="el-icon-warning" />
                  {{ this.mxVm$t('common.adBlockTips') }}
                  <el-button
                    type="text"
                    icon="el-icon-close"
                    @click="isShowAdBlockTips = false"
                  />
                </li>
                <li
                  v-if="
                    mxEnum.Yes ==
                      $store.state.account.balanceInfo.show_less_balance &&
                      false !== isShowBalanceTips
                  "
                >
                  <i class="el-icon-warning" />
                  {{ this.mxVm$t('account.insufficientBalance') }}
                  <el-button
                    type="text"
                    icon="el-icon-close"
                    @click="isShowBalanceTips = false"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div v-if="!isShowNoPermission" class="">
            <nuxt />
          </div>
          <no-permission-dialog v-else />
          <contact-us-bar
            v-if="
              $store.state.account.basicInfo.pm_name ||
                $store.state.account.basicInfo.bd_name
            "
          />
        </el-main>
        <!-- <el-footer class="g-container" height="">
            Copyright {{ currentYear }} Mintegral All rights reserved.
          </el-footer> -->
      </el-container>
    </el-container>
    <basic-info-dialog :visible.sync="dialogConfig.basicInfoDialog.visible" />
    <layout-loading></layout-loading>
  </div>
</template>

<script>
import LayoutLoading from '~/components/layouts/LayoutLoading.vue'
import BrandLogo from '~/components/widgets/BrandLogo.vue'
import ContactUsBar from '~/components/widgets/ContactUsBar.vue'
import TheMenu from '~/components/layouts/TheMenuV2.vue'
import TheUserBar from '~/components/layouts/TheUserBar.vue'
import TheDrawer from '~/components/layouts/TheDrawer.vue'
import NoPermissionDialog from '~/components/offers/NoPermissionDialog.vue'
import BasicInfoDialog from '~/components/account/BasicInfoDialog.vue'

export default {
  components: {
    LayoutLoading,
    BrandLogo,
    ContactUsBar,
    TheMenu,
    TheUserBar,
    TheDrawer,
    NoPermissionDialog,
    BasicInfoDialog
  },
  middleware: ['auth', 'webContent'],
  head() {
    return {
      title: this.mxVm$t({
        id: 'common.pageTitle',
        values: { title: this.$store.state.pageTitle }
      })
    }
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      isShowAdBlockTips: false,
      isShowBalanceTips: undefined,
      dialogConfig: {
        basicInfoDialog: { visible: false }
      }
    }
  },
  computed: {
    isShowBalance() {
      const apiFlag =
        undefined !== this.$store.state.account.balanceInfo.balance
      if (!apiFlag) return false

      const { name } = this.$route
      const list = ['index', 'add-funds', 'billing-management']
      if (name) {
        for (let i = 0; i < list.length; ++i) {
          if (-1 < name.indexOf(list[i])) return false
        }
      }
      return true
    },
    navList() {
      const navPaths = []
      const routePath = (this.$route.matched[0] || {}).path
      const { locale } = this.$i18n || {}

      const getNavPath = function({ key, i18n, path, children, more }) {
        if (key) {
          navPaths.push({ key, i18n, path })

          if (path) {
            if (path === '/') {
              path = ''
            }

            // match
            if (path === routePath || `/${locale}${path || ''}` === routePath) {
              throw new Error('break') // break recursion
            }
          }
        }

        // find in children
        const subClass = children || more || []
        subClass.forEach(child => {
          getNavPath(child)
        })

        // unmatch and remove
        navPaths.pop()
      }

      try {
        getNavPath({ children: this.$store.state.account.menu })
      } finally {
        /* eslint no-unsafe-finally: "off" */
        return navPaths
      }
    },
    isShowNoPermission() {
      console.log('!!!', this.navList)
      // return !this.navList.length
      return false
    },
    title() {
      const { navList = [] } = this
      const item = navList[navList.length - 1] || {}
      return this.mxVm$t(item.i18n)
    }
  },
  watch: {
    '$route.name'(newVal, oldVal) {
      this.$store.dispatch('account/fetchBalanceInfo')
    }
  },
  mounted() {
    this.$store.commit('setPageTitle', this.title)
    this.$store.dispatch('account/fetchBalanceInfo')
    this.detectAdBlock()
    this.checkAcquireUsers()
  },
  updated() {
    this.$store.commit('setPageTitle', this.title)
  },
  methods: {
    detectAdBlock() {
      const elm = document.getElementById('ad_block_detect')
      if (!elm || !elm.clientWidth || !elm.clientHeight) {
        this.isShowAdBlockTips = true
      }
    },
    checkAcquireUsers() {
      const { is_acquire_users } = this.$store.state.account.basicInfo
      const {
        mxEnum: { No }
      } = this.$root
      if (is_acquire_users == No) {
        this.dialogConfig.basicInfoDialog.visible = true
      }
    }
  }
}
</script>

<style lang="scss">
.f-no-breadcrumb {
  .m-page-info {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.m-warning /deep/ {
  color: $--color-warning;
  background: rgba($--color-warning, 0.1);
  font-size: 14px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: 8px 0;
    }
  }

  .el-button {
    color: $--color-warning;
    padding: 0;
  }
}

.m-page-info /deep/ {
  position: relative;
  margin-bottom: 15px;

  .el-breadcrumb__inner.is-link,
  .el-breadcrumb__inner a {
    font-weight: 400;
  }
}
.m-page-info__buttons {
  position: relative;
  display: block;
  height: 54px;
  line-height: 54px;
}
</style>
