<template>
  <el-menu
    class="ss-menu"
    :default-active="activeMenuIndex"
    :mode="mode"
    unique-opened
    @select="onSelect"
  >
    <template v-for="item in $store.state.account.menu">
      <el-submenu
        v-if="item.children && item.children.length > 1"
        :key="item.key"
        :index="item.key"
        :show-timeout="0"
        :hide-timeout="0"
        :popper-append-to-body="false"
      >
        <template v-slot:title>
          <i v-if="item.icon" :class="item.icon" />
          <span style="margin-left: 5px">{{ mxVm$t(item.i18n) }}</span>
        </template>
        <el-menu-item
          v-for="subItem in item.children"
          :key="subItem.key"
          :index="subItem.path"
        >
          {{ mxVm$t(subItem.i18n) }}
        </el-menu-item>
      </el-submenu>
      <el-menu-item
        v-else-if="(item.children.length == 1 ? item.children[0] : item).path"
        :key="item.key"
        :index="(item.children.length == 1 ? item.children[0] : item).path"
      >
        <i v-if="item.icon" :class="item.icon" />
        <span style="margin-left: 5px">{{ mxVm$t(item.i18n) }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
const sassVars = {
  headerBgColor: '#000000',
  headerTextColor: '#fff',
  headerMenuActiveTextColor: '#fff'
}

export default {
  props: {
    mode: {
      type: String,
      default: 'vertical'
    }
  },
  data() {
    return {
      theme: {
        backgroundColor: sassVars.headerBgColor,
        textColor: sassVars.headerTextColor,
        activeTextColor: sassVars.headerMenuActiveTextColor
      },
      activeMenuIndex: '/'
    }
  },
  computed: {
    currentPath() {
      return this.$store.state.currentPath
    }
  },
  watch: {
    currentPath(newValue) {
      // console.log(newValue)
      const path = newValue.replace(`/${this.$i18n.locale}`, '')
      // console.log(path)
      if (!path) {
        this.activeMenuIndex = '/'
      } else {
        // console.log('----------------------------')
        // console.log(path)
        this.activeMenuIndex = path
      }
    }
  },
  mounted() {
    const path = this.$route.path.replace(`/${this.$i18n.locale}`, '') || '/'
    // console.log(path)
    this.activeMenuIndex = path
  },
  methods: {
    async turnToPlayturbo() {
      this.$store.commit('account/setIsLoading', true)
      const { id } = this.$store.state.account.basicInfo
      const { code, data } = await this.$axios.$get('accountPlayturbo', {
        params: { adv_user_id: id }
      })
      this.$store.commit('account/setIsLoading', false)
      if (code == 200 && data) {
        const { redirect_url } = data
        window.open(redirect_url, '_blank')
      }
    },
    onSelect(index, path) {
      // console.log(index, path)
      this.$emit('select')
      this.activeMenuIndex = index
      const reg = /^\/./
      if (index === 'playturbo') {
        this.turnToPlayturbo()
        return
      }
      // 正常路由
      if (reg.test(index) || index === '/') {
        this.$router.push(this.mxVmLocalePath(index))
      } else {
        window.open(this.mxVm$t(index), '_blank')
      }
    }
  }
}
</script>

<style lang="scss">
.ss-menu {
  &.el-menu {
    border-right: 0 none;
  }
  .el-menu-item.is-active {
    color: #fff;
    background-color: $--color-primary;
  }
}
</style>
