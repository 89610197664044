<template>
  <el-dialog
    :visible.sync="isVisible"
    :title="mxVm$t('account.completeInfo')"
    custom-class="dialog--xxl"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="onClosed"
  >
    <pageEdit mode="complete" @after-submit="onClosed" />
  </el-dialog>
</template>

<script>
import pageEdit from '~/pages/account/index.vue'

export default {
  components: {
    pageEdit
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    isVisible: {
      get() {
        return this.visible
      },
      set(visible) {
        this.$emit('update:visible', visible)
      }
    }
  },
  methods: {
    onClosed() {
      this.isVisible = false
    }
  }
}
</script>
