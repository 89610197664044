import { Notification } from 'element-ui'

function formatUser(user) {
  return user ? JSON.parse(user) : { basicInfo: {} }
}

function showUserChangeTip(app, { name }) {
  if (!name) return
  const notifyInstance = Notification({
    title: app.i18n.t('common.notice'),
    message: app.i18n.t('common.userChangeTips', {
      name
    }),
    type: 'info',
    customClass: 'user-change-tip',
    duration: 0,
    showClose: true
  })

  return notifyInstance
}

/**
 * when login another user, refresh current window.
 * if router in offers data page, back to list page
 * @param {Object} app
 */
function listenUserChange(app) {
  const auth_storage_key = `auth_storage_key`
  const { path, query } = app.context.route
  // show notice when route query user_change = 1
  if (query.user_change) {
    const { name } = formatUser(
      localStorage.getItem(auth_storage_key)
    ).basicInfo
    showUserChangeTip(app, { name })
    const removeQuery = { ...query }
    delete removeQuery.user_change
    app.router.replace({ path, query: removeQuery })
  }
  window.addEventListener('storage', event => {
    const { key, oldValue, newValue } = event
    if (key !== auth_storage_key) return
    const lastUser = formatUser(oldValue)
    const currentUser = formatUser(newValue)

    // login another user
    if (
      currentUser.basicInfo.name &&
      currentUser.basicInfo.name !== lastUser.basicInfo.name
    ) {
      const { path } = app.context.route
      let redirect = path
      if (path.includes('/offers/')) {
        const offersPath = path.includes('cn') ? '/cn/offers' : '/offers'
        redirect = `//${location.host}${offersPath}?user_change=1`
      } else if (path.includes('/login')) {
        redirect = `//${location.host}${path.replace(
          '/login',
          ''
        )}?user_change=1`
      } else {
        redirect = `//${location.host}${path}?user_change=1`
      }
      location.href = redirect
    }
  })
}
export default ({ app }) => {
  listenUserChange(app)
}
