import Vue from 'vue'
import Element from 'element-ui/lib/element-ui.common'
// import Element from 'D:/code/ADN/element/lib/element-ui.common.js'
import device from '~/utils/device.js'
import SmartTree from '~/components/forms/SmartTree'
import TargetAppDetail from '~/components/forms/TargetAppDetail'
// import ElMvTable from '../components/table'

if (device.isMobile) {
  // Fixes an issue with filters not working on mobile
  Element.Select.computed.readonly = function() {
    // trade-off for IE input readonly problem: https://github.com/ElemeFE/element/issues/10403
    const { isIE } = device.browser
    return !(this.filterable || this.multiple || !isIE) && !this.visible
  }
}

export default ({ app }) => {
  Vue.use(Element, {
    size: 'small',
    i18n: (key, value) => app.i18n.t(key, value)
  })
  Vue.component('smart-tree', SmartTree)
  Vue.component('target-app-detail', TargetAppDetail)
  // Vue.use(ElMvTable, {
  //   i18n: (key, value) => app.i18n.t(key, value)
  // })
}
