export default ({ app }, inject) => {
  const versionKey = 'version_storage_key'
  const localVersion = localStorage.getItem(versionKey)
  const currentVersion = process.env.version
  localStorage.setItem(versionKey, currentVersion)
  const updateVersion = localVersion !== currentVersion
  // print info in console
  if (updateVersion) {
    console.log(
      `%cupdate version! current version:${currentVersion}`,
      'color: #009877; font-size: 14px'
    )
  }
  inject('updateVersion', callback => {
    if (updateVersion) callback(currentVersion, localVersion)
  })
}
