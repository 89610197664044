export default ({ app }) => {
  const { apiDebugToken } = process.env
  if (apiDebugToken) {
    app.router.beforeResolve((to, from, next) => {
      const tokenFrom = from.query[apiDebugToken]
      const tokenTo = to.query[apiDebugToken]
      if (!tokenTo && tokenFrom) {
        const query = { ...to.query, [apiDebugToken]: tokenFrom }
        next({ path: to.path, query })
        return
      }
      next()
    })
  }

  app.router.beforeEach((to, from, next) => {
    // console.log(to)
    app.store.commit('setCurrentPath', to.path)
    next()
  })
}
