import Vue from 'vue'
import { attractTrafficMonitor } from '~/plugins/gio/gio-monitor'

const eventMap = {}

Vue.directive('attract-click', {
  bind(el, binding, vnode) {
    const userid = vnode.context.$store?.state?.account.basicInfo.id
    if (!eventMap[binding.value]) {
      const handler = function() {
        attractTrafficMonitor.onClick(binding.value, userid)
      }
      eventMap[binding.value] = handler
    }
    const handler = eventMap[binding.value]
    el.addEventListener('click', handler)
  },
  unbind(el, binding) {
    const handler = eventMap[binding.value]
    if (handler) el.removeEventListener('click', handler)
  }
})
