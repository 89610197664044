const defaultBatch = 'optionsBatch'
const separator = '?query='

// filter default batch prefix
function filterDefaultBatchPrefix(key) {
  if (!key) return
  const defaultPrefix = defaultBatch + separator
  if (0 == key.indexOf(defaultPrefix)) {
    key = key.replace(defaultPrefix, '')
  }
  return key
}

export const state = () => ({
  data: {}
})

export const getters = {
  getOption: state => key => {
    key = filterDefaultBatchPrefix(key)
    return (
      (key && state.data[key]) || {
        isLoading: false,
        list: []
      }
    )
  }
}

export const mutations = {
  setIsLoading(state, { isLoading, prefixKey, data }) {
    const tmp = {}
    data.forEach(item => {
      const key = `${prefixKey || ''}${item}`
      const list = (state.data[key] || {}).list || []
      tmp[key] = {
        list,
        isLoading
      }
    })
    state.data = {
      ...state.data,
      ...tmp
    }
  },
  setData(state, { prefixKey, data }) {
    const tmp = {}
    for (const item in data) {
      const key = `${prefixKey || ''}${item}`
      const list = data[item] || []
      tmp[key] = {
        isLoading: false,
        list
      }
    }
    state.data = {
      ...state.data,
      ...tmp
    }
  }
}

export const actions = {
  async fetchOptions({ commit, state }, optionsKey) {
    if (!optionsKey) return

    if (Array.isArray(optionsKey)) {
      // batch request
      const config = {}
      optionsKey.forEach(item => {
        item = filterDefaultBatchPrefix(item)
        // return if already exist
        if (state.data[item]) return
        let apiKey = defaultBatch
        if (-1 < item.indexOf(separator)) {
          // other batch
          apiKey = item.split(separator)[0]
          // filter prefix to get query item
          item = item.replace(apiKey + separator, '')
        }
        if (!config[apiKey]) config[apiKey] = []
        if (-1 == config[apiKey].indexOf(item)) {
          config[apiKey].push(item)
        }
      })
      for (const apiKey in config) {
        const query = config[apiKey]
        if (!query.length) return

        const prefixKey =
          apiKey != defaultBatch ? apiKey + separator : undefined
        commit('setIsLoading', { isLoading: true, prefixKey, data: query })
        const { code, data } = await this.$axios.$get(apiKey, {
          params: { query: query + '' }
        })
        commit('setIsLoading', { isLoading: false, prefixKey, data: query })

        if (200 == code) {
          const result = {}
          for (const key in data) {
            // filter query result
            if (-1 < query.indexOf(key)) {
              result[key] = data[key]
            }
          }
          commit('setData', { prefixKey, data: result })
        }
      }
    } else {
      // single request
      const key = optionsKey
      if (state.data[key]) return state.data[key].list

      commit('setIsLoading', { isLoading: true, data: [key] })
      const { code, data } = await this.$axios.$get(key)
      commit('setIsLoading', { isLoading: false, data: [key] })

      if (200 == code) {
        commit('setData', { data: { [key]: data } })

        return data
      }
    }
  }
}
