<template>
  <div>
    <div class="m-page-info">
      <h2 class="leading-9 text-[24px] m-0 font-medium">
        {{ $store.state.pageTitle }}
      </h2>
      <el-breadcrumb separator="/" class="mt-1 text-[12px] leading-5">
        <el-breadcrumb-item v-for="(item, index) in navList" :key="item.key">
          <nuxt-link
            v-if="index < navList.length - 1 && item.path"
            :to="mxVmLocalePath(item.path)"
          >
            {{ mxVm$t(item.i18n) }}
          </nuxt-link>
          <span v-else>{{ mxVm$t(item.i18n) }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="m-page-info__buttons">
        <slot name="button"></slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  computed: {
    isShowBalance() {
      const apiFlag =
        undefined !== this.$store.state.account.balanceInfo.balance
      if (!apiFlag) return false

      const { name } = this.$route
      const list = ['index', 'add-funds', 'billing-management']
      if (name) {
        for (let i = 0; i < list.length; ++i) {
          if (-1 < name.indexOf(list[i])) return false
        }
      }
      return true
    },
    navList() {
      const navPaths = []
      const routePath = (this.$route.matched[0] || {}).path
      const { locale } = this.$i18n || {}

      const getNavPath = function({ key, i18n, path, children, more }) {
        if (key) {
          navPaths.push({ key, i18n, path })

          if (path) {
            if (path === '/') {
              path = ''
            }

            // match
            if (path === routePath || `/${locale}${path || ''}` === routePath) {
              throw new Error('break') // break recursion
            }
          }
        }

        // find in children
        const subClass = children || more || []
        subClass.forEach(child => {
          getNavPath(child)
        })

        // unmatch and remove
        navPaths.pop()
      }

      try {
        getNavPath({ children: this.$store.state.account.menu })
      } finally {
        /* eslint no-unsafe-finally: "off" */
        return navPaths
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.m-page-info /deep/ {
  position: relative;
  margin-bottom: 15px;

  .el-breadcrumb__inner.is-link,
  .el-breadcrumb__inner a {
    font-weight: 400;
  }
}
.m-page-info__buttons {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  height: 54px;
  line-height: 54px;
}
</style>
