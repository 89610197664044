<template>
  <el-form-item :label="label" v-bind="$attrs">
    <template v-if="tips" v-slot:label>
      {{ label }}
      <icon-tips :content="tips"></icon-tips>
    </template>
    <slot></slot>
  </el-form-item>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    tips: {
      type: String,
      default: ''
    }
  }
}
</script>
