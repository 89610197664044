const FETCH_ADV_SETS = 'FETCH_ADV_SETS'
const ADD_ADV_SETS = 'ADD_ADV_SETS'
const TOGGLE_LOADING = 'TOGGLE_LOADING'

export const state = () => ({
  advSets: [],
  isLoading: false
})

export const mutations = {
  [ADD_ADV_SETS](state, data) {
    state.advSets.push(data)
  },
  [FETCH_ADV_SETS](state, data) {
    state.advSets = data
  },
  [TOGGLE_LOADING](state, data) {
    state.isLoading = data
  }
}

export const getters = {
  getAdvSets: state =>
    state.advSets.map(item => ({
      value: item.set_id,
      label: item.set_name
    }))
}

export const actions = {
  async dispatchFetchAdvSets({ commit }, params) {
    const { code, data } = await this.$axios.$get('advSetIndex', {
      params: { adv_user_id: params.adv_user_id }
    })
    const sets = code === 200 ? data || [] : []
    commit(FETCH_ADV_SETS, sets)
    return { code, data }
  },
  async dispatchAddAdvSets({ commit, dispatch }, params = {}) {
    commit(TOGGLE_LOADING, true)
    const { code } = await this.$axios.$post('advSetCreate', {
      adv_user_id: params.adv_user_id,
      set_name: params.set_name
    })
    if (code === 200) {
      await dispatch('dispatchFetchAdvSets', {
        adv_user_id: params.adv_user_id
      })
      commit(TOGGLE_LOADING, false)
      return true
    } else {
      commit(TOGGLE_LOADING, false)
      return false
    }
  }
}
