<template>
  <el-dialog
    :visible.sync="isVisible"
    :append-to-body="true"
    :title="$t('data.uploadResult')"
    custom-class="dialog--sm"
  >
    <el-row :gutter="15">
      <el-col :span="-1">
        <span class="f-bold">{{ $t('data.uploadTotal') }}: </span>
        {{ results.total }}
      </el-col>
      <el-col :span="-1">
        <span class="f-bold">{{ $t('data.uploadSuccess') }}: </span>
        {{ results.success }}
      </el-col>
      <el-col :span="-1">
        <span class="f-bold">{{ $t('data.uploadFailed') }}: </span>
        {{ results.errors.length }}
      </el-col>
    </el-row>
    <el-table
      v-if="results.errors.length"
      :data="results.errors"
      max-height="500"
    >
      <el-table-column min-width="25%" label="File" prop="name" />
      <el-table-column min-width="75%" label="Error" prop="error">
        <template v-slot="{ row }">
          <template v-if="'object' == typeof row.error">
            <template v-for="errorKey in Object.keys(row.error)">
              <p :key="errorKey">
                {{ row.error[errorKey] }}
              </p>
            </template>
          </template>
          <template v-else>
            {{ row.error }}
          </template>
        </template>
      </el-table-column>
    </el-table>
    <template v-slot:footer>
      <el-button type="primary" @click="isVisible = false">
        {{ $t('common.ok') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dataSource: {
      type: Object,
      default: undefined
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVisible: {
      get() {
        return this.visible
      },
      set(visible) {
        this.$emit('update:visible', visible)
      }
    },
    results() {
      const { total = 0, errors = [] } = this.dataSource || {}
      return { total, success: total - errors.length, errors }
    }
  }
}
</script>
