<template>
  <!-- @TODO: 抽离组件 -->
  <div>
    <template v-for="(item, $index) in dataFields[prop]">
      <slot
        :itemFields="item"
        :itemFieldsProp="`${prop}.${$index}`"
        :index="$index"
        :onDelete="onDelete"
      ></slot>
    </template>
    <el-button
      v-show="showAdd"
      type="primary"
      size="mini"
      plain
      icon="el-icon-plus"
      @click="dataFields[prop].push(getItemFields())"
    >
      {{ mxVm$t('action.add') }}
    </el-button>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    prop: {
      type: String,
      default: ''
    },
    configItem: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: 'add'
    },
    dataFields: {
      type: Object,
      default: () => ({})
    },
    dataConfig: {
      type: Object,
      default: () => ({})
    },
    dataErrors: {
      type: Object,
      default: () => ({})
    },
    max: {
      type: Number,
      default: 10
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemFields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    showAdd() {
      if (this.disabled) return false
      return this.dataFields?.[this.prop]?.length < this.max
    },
    rules() {
      const res = [
        {
          trigger: 'blur',
          type: 'email',
          message: this.mxVm$t('account.ccemailValid')
        }
      ]
      if ((this.dataFields?.[this.prop]?.length ?? 0) > 1) {
        res.push({
          required: true,
          message: this.mxVm$t('rule.isRequired')
        })
      }
      return res
    }
  },
  methods: {
    getItemFields() {
      return { ...this.itemFields }
    },
    onDelete(index) {
      this.dataFields[this.prop].splice(index, 1)
      this.$emit('delete', this.dataFields[this.prop])
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  display: inline-block;
  width: 100%;
  // margin-bottom: 20px;
}
</style>
