<template>
  <el-container
    v-loading="$store.state.account.isLoading || isLoading"
    :class="`lang-${$i18n.locale} vertical-fullscreen`"
    style="height: 100vh; overflow-y: auto"
  >
    <h1 class="f-hiden-tag">{{ this.$store.state.pageTitle }}</h1>
    <el-header
      class="w-full bg-white flex items-center px-8 justify-between text-[#5A607F] shadow-[0_1px_4px_0_rgba(19,21,35,0.08)] z-10"
      height="40px"
      style="line-height: 40px;"
    >
      <brand-logo class="elm-brand-logo" theme="white" />
      <the-user-bar :is-stateless="true" />
    </el-header>
    <el-main style="padding: 0;">
      <div>
        <nuxt @change-loading="status => (isLoading = status)" />
      </div>
      <el-footer class="g-container" height="">
        <copyright :currentYear="currentYear" />
      </el-footer>
    </el-main>
  </el-container>
</template>

<script>
import BrandLogo from '~/components/widgets/BrandLogo.vue'
import TheUserBar from '~/components/layouts/TheUserBar.vue'
import Copyright from '~/components/layouts/Copyright.vue'
export default {
  components: {
    BrandLogo,
    TheUserBar,
    Copyright
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      isLoading: false
    }
  },
  head() {
    return {
      title: this.mxVm$t({
        id: 'common.pageTitle',
        values: { title: this.$store.state.pageTitle }
      })
    }
  }
}
</script>
