// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/dialog/close@2x.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-501c884f]:export{primaryColor:#00a68f;infoColor:#4f6475;headerBgColor:#000;headerTextColor:#131523;headerMenuActiveTextColor:#fff}.footer[data-v-501c884f]{display:flex;padding:0 24px 24px;justify-content:space-between;align-content:center}.title[data-v-501c884f]{color:#333;margin-bottom:16px;line-height:22px;font-weight:700}.content[data-v-501c884f]{margin:24px 4px 0;padding:0 20px;box-sizing:border-box;color:#333;overflow-y:auto;font-size:14px}.content[data-v-501c884f]::-webkit-scrollbar{width:5px;height:5px}.content .mask[data-v-501c884f]{background:#fff;position:absolute;top:0;left:0;right:10px;height:20px}.content .title[data-v-501c884f]{font-size:16px}.close-btn[data-v-501c884f]{position:absolute;right:20px;top:20px;opacity:.6;height:12px;width:12px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;color:#fff;cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
