<template>
  <el-row :gutter="10" style="margin-bottom: 8px;">
    <el-col :span="7" style="padding-left: 0;">
      <el-form-item
        :prop="`${itemFieldsProp}.email`"
        :rules="formRules.email"
        :error="dataErrors[`${itemFieldsProp}.email`] || ''"
      >
        <el-input
          v-model="itemFields.email"
          :placeholder="mxVm$t('account.ccemailAddress')"
          :disabled="disabled || disabledForFields.email === true"
          clearable
        />
      </el-form-item>
    </el-col>
    <el-col :xl="17" style="display: flex; padding-left: 0;">
      <el-form-item
        :prop="`${itemFieldsProp}.subscription_type`"
        :rules="formRules.subscription_type"
        :error="dataErrors[`${itemFieldsProp}.subscription_type`] || ''"
      >
        <el-checkbox-group
          v-model="itemFields.subscription_type"
          :disabled="disabled || disabledForFields.subscription_type === true"
          @change="$emit('subscriptionTypeChange')"
        >
          <el-checkbox-button
            v-for="(option, index) in subscriptionTypes"
            :key="option.k"
            :label="option.k"
          >
            {{ option.v }}
            <el-popover trigger="hover" placement="top">
              {{ mxVm$t(`account.subscribedEmail.tips.${option.k}`) }}
              <i
                slot="reference"
                class="el-icon-warning-outline"
                style="cursor:pointer;"
              />
            </el-popover>
          </el-checkbox-button>
        </el-checkbox-group>
      </el-form-item>
      <template v-if="showDeleteBtn">
        <el-button
          type="text"
          icon="el-icon-close"
          class="text--red"
          style="height: 32px; line-height: 32px; margin: 0 10px;"
          @click="$emit('delete')"
        >
        </el-button>
      </template>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'EmailList',
  props: {
    itemFieldsProp: {
      type: String,
      default: ''
    },
    itemFields: {
      type: Object,
      default: () => ({})
    },
    showDeleteBtn: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isList: {
      type: Boolean,
      default: true
    },
    prop: {
      type: String,
      default: ''
    },
    dataFields: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    dataErrors: {
      type: Object,
      default: () => ({})
    },
    disabledForFields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    formRules() {
      const emailRule = [
        { trigger: 'blur', type: 'email', message: 'not a valid email.' }
      ]
      emailRule.push({ required: true, message: 'Required' })
      emailRule.push(...(this.rules.email || []))

      // const subscriptionTypeRule = [{ required: true, message: 'Required' }]
      const subscriptionTypeRule = []
      subscriptionTypeRule.push(...(this.rules.subscription_type || []))

      return { email: emailRule, subscription_type: subscriptionTypeRule }
    },
    subscriptionTypes() {
      return (
        this.$store.state.dataOptions.data[
          'adv-user-email-subscription-type'
        ] || []
      )
    }
  }
}
</script>
<style lang="scss">
.el-checkbox-button.is-disabled.is-checked .el-checkbox-button__inner {
  background: #f5f7fa;
  color: #131523;
}
</style>
