export default class CreativeService {
  constructor(context) {
    this.$axios = context.$axios
    this.cache = context.cache
  }

  /**
   * 获取endcard相关的gif
   * @param {*} creative_url url
   * @returns gifs
   */
  async gifFromEndcards(creative_url) {
    try {
      const { data, code, msg } = await this.$axios.$get('gifList', {
        params: { urls: JSON.stringify(creative_url) }
      })
      if (code === 200) {
        return [null, data]
      } else {
        return [msg, null]
      }
    } catch (err) {
      return [err, null]
    }
  }

  async getPlayableToken(adv_user_id) {
    try {
      const { data, code, msg } = await this.$axios.$get('playturboToken', {
        params: { adv_user_id }
      })
      if (code === 200) {
        return [null, data]
      } else {
        return [msg, null]
      }
    } catch (err) {
      return [err, null]
    }
  }

  /**
   * 上传gif
   * @param {*} gif_url gif链接
   */
  async uploadGif(gif_url) {
    try {
      const { data, code, msg } = await this.$axios.$post('gifUpload', {
        ec_to_gif: JSON.stringify(gif_url)
      })
      if (code === 200) {
        return [null, data]
      } else {
        return [msg, null]
      }
    } catch (err) {
      return [err, null]
    }
  }

  /**
   * 绑定gif和playable关系
   * @param {*} creatives gif链接
   */
  async bindGifWithPlayable(creatives) {
    try {
      const { data, code, msg } = await this.$axios.$put('gifList', {
        creatives: JSON.stringify(creatives)
      })
      if (code === 200) {
        return [null, data]
      } else {
        return [msg, null]
      }
    } catch (err) {
      return [err, null]
    }
  }
}
