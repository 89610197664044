<template>
  <div :class="`lang-${$i18n.locale} mtg-login`">
    <nuxt />
  </div>
</template>
<script>
import TheDropdownLang from '~/components/layouts/TheDropdownLang.vue'
export default {
  components: {
    TheDropdownLang
  },
}
</script>

<style lang="scss" scoped>
.mtg-login {
  position: relative;
  height: 100vh;
  overflow-y: auto;
  font-size: 14px;
  color: #fff;
}
</style>
