import { get } from 'lodash-es'
import { loginMonitor } from '~/plugins/gio/gio-monitor'
import SESDK from '~/plugins/web-sesdk'
import * as Sentry from '@sentry/vue'
import Enum from '~/config/enum.js'
import { attractTrafficMonitor } from '~/plugins/gio/gio-monitor'
const authStorageKey = `auth_storage_key`

export const state = () => ({
  isLoading: false,
  isAuth: false,
  basicInfo: {},
  balanceInfo: {},
  action: {},
  menu: [],
  _m: []
})

export const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = !!isLoading
  },
  setAuth(state, data) {
    state.isAuth = !!data
    if (data) {
      localStorage.setItem(authStorageKey, JSON.stringify(data))
    } else {
      localStorage.removeItem(authStorageKey)
    }
    if (!data) data = {}
    loginMonitor.setUserId((data.basicInfo || {}).id)
    attractTrafficMonitor.setUserId((data.basicInfo || {}).id)
    state.basicInfo = data.basicInfo || {}
    const currencySymbol = (
      Enum.Currency.options.find(item => item.k == state.basicInfo.currency) ||
      {}
    ).symbol
    state.basicInfo.currencySymbol = currencySymbol
    state.action = data.action || {}
    state.menu = data.menu || []
    state.balanceInfo = data.balanceInfo || {}
    state._m = data._m || []
  },
  setBasicInfo(state, basicInfo) {
    if ('object' != typeof basicInfo) return
    state.basicInfo = Object.assign({}, state.basicInfo, basicInfo)
  },
  setBalanceInfo(state, balanceInfo) {
    if ('object' != typeof balanceInfo) return
    state.balanceInfo = balanceInfo || {}
  }
}

export const actions = {
  async auth({ commit, state }, dataFields) {
    // if update version, remove storage data
    this.$updateVersion((currentVersion, preVersion) => {
      localStorage.removeItem(authStorageKey)
    })
    // if payload is "force", always fetch
    const isForce = 'force' == dataFields
    // if the user is authenticated
    // 每次打开页面进行auth
    // const setAuth = localStorage.getItem(authStorageKey)
    // if (!isForce && setAuth) {
    //   commit('setAuth', JSON.parse(setAuth))
    //   return
    // }
    const isCheck = 'check' == dataFields
    if (!isForce && state.isAuth) {
      Sentry.setUser({
        email: (state.basicInfo && state.basicInfo.email) || 'unknown',
        name: (state.basicInfo && state.basicInfo.name) || 'unknown',
        id: (state.basicInfo && state.basicInfo.id) || 'unknown'
      })
      //@TODO: 配合热力引擎SDK测试，后续需要删除
      if (process.env.BUILD_ENV == 'production') {
        SESDK.login(state.basicInfo && state.basicInfo.id)
        const testLog = SESDK.fastFetchRemoteConfig('acctest')
        console.log('[SESDK acctest]:', testLog)
      }
      return
    }
    commit('setIsLoading', true)
    const { $get, $post } = this.$axios
    const { code, data } = await ('object' == typeof dataFields
      ? $post('login', dataFields)
      : $get('auth', { __skipAutoNotify: isCheck }))
    commit('setIsLoading', false)
    if (code == 200) {
      const auth = Object.assign({}, data || {})

      // @TODO: 临时逻辑，分流 星耀 用户
      if ('object' !== typeof dataFields) { // login
        if (auth.in_new_version) {
          if (window.location.hostname !== process.env.CREATIVE_GREY_DOMAIN) {
            const url = new URL(window.location.href)
            url.host = process.env.CREATIVE_GREY_DOMAIN
            window.location.href = url.href
          }
        } else {
          if (window.location.hostname !== process.env.CREATIVE_DOMAIN) {
            const url = new URL(window.location.href)
            url.host = process.env.CREATIVE_DOMAIN
            window.location.href = url.href
          }
        }
      }

      // always add 'help' to _m

      // @TODO: 临时逻辑，分流 星耀 用户
      if ('object' !== typeof dataFields) {
        // login
        if (auth.in_new_version) {
          if (window.location.hostname !== process.env.CREATIVE_GREY_DOMAIN) {
            const url = new URL(window.location.href)
            url.host = process.env.CREATIVE_GREY_DOMAIN
            window.location.href = url.href
          }
        } else {
          if (window.location.hostname !== process.env.CREATIVE_DOMAIN) {
            const url = new URL(window.location.href)
            url.host = process.env.CREATIVE_DOMAIN
            window.location.href = url.href
          }
        }
      }

      auth._m.push(
        'help',
        'userManual',
        'createCampaign',
        'creatives',
        'integration',
        'analyzeOptimize',
        'guide',
        'policy',
        'faqs',
        'xmp'
      )
      commit('setAuth', {
        basicInfo: auth.basic_info,
        action: auth._a,
        menu: filterMenu(auth._m),
        _m: auth._m
      })
      const basic_info = auth.basic_info || {}
      Sentry.setUser({
        email: (basic_info && basic_info.email) || 'unknown',
        name: (basic_info && basic_info.name) || 'unknown',
        id: (basic_info && basic_info.id) || 'unknown'
      })
      //@TODO: 配合热力引擎SDK测试，后续需要删除
      if (process.env.BUILD_ENV == 'production') {
        SESDK.login(basic_info && basic_info.id)
        const testLog = SESDK.fastFetchRemoteConfig('acctest')
        console.log('[SESDK acctest]:', testLog)
      }
    }
  },
  async logout({ commit, state }) {
    commit('setIsLoading', true)
    const { code } = await this.$axios.$get('logout')
    commit('setIsLoading', false)
    if (code == 200) {
      commit('setAuth', null)
    }
  },
  async fetchBaseInfo({ commit, state }) {
    commit('setIsLoading', true)
    const { code, data } = await this.$axios.$get('accountInfo')
    commit('setIsLoading', false)
    if (code == 200) {
      commit('setBasicInfo', data)
    }
  },
  async fetchBalanceInfo({ commit, state }) {
    // commit('setIsLoading', true)
    const { code, data } = await this.$axios.$get('balanceInfo')
    if (code == 200 && data) {
      commit('setBalanceInfo', data)
    }
    // commit('setIsLoading', false)
  }
}

export const getters = {
  isSubUser: state => {
    return state.basicInfo.parent_user_id != 0
  }
}

const MENU = [
  {
    key: 'overview',
    i18n: 'menu.overview',
    icon: 'ss-v2-iconfont ss-v2-icon-common-applications',
    children: [
      {
        key: 'summary_report',
        i18n: 'menu.summaryReport',
        path: '/'
      }
    ]
  },
  {
    key: 'user_acquisition',
    i18n: 'menu.userAcquisition',
    icon: 'ss-v2-iconfont ss-v2-icon-common-users',
    children: [
      {
        key: 'campaign',
        i18n: 'menu.campaignList',
        path: '/campaigns',
        more: [
          {
            key: 'create_campaign',
            i18n: {
              id: 'action.create',
              values: { item__id: 'campaign.text' }
            },
            path: '/campaigns/create'
          }
        ]
      },
      {
        key: 'offer',
        i18n: 'menu.offerList',
        path: '/offers',
        more: [
          {
            key: 'create_offer',
            i18n: {
              id: 'action.create',
              values: { item__id: 'offer.text' }
            },
            path: '/offers/create'
          },
          {
            key: 'edit_offer',
            i18n: {
              id: 'action.edit',
              values: { item__id: 'offer.text' }
            },
            path: '/offers/edit'
          },
          {
            key: 'copy_offer',
            i18n: {
              id: 'action.copy',
              values: { item__id: 'offer.text' }
            },
            path: '/offers/copy'
          },
          {
            key: 'offer_detail',
            i18n: {
              id: 'action.detail',
              values: { item__id: 'offer.text' }
            },
            path: '/offers/detail'
          },
          {
            key: 'offer_set_tracking',
            i18n: 'offer.setTracking',
            path: '/offers/set-tracking'
          }
        ]
      },
      {
        key: 'creative_lib',
        i18n: 'menu.creativeLib',
        path: '/creatives'
      },
      {
        key: 'target_app',
        i18n: 'menu.targetSetting',
        path: '/account/target-app'
      },
      {
        key: 'manage_event',
        i18n: 'menu.manageEvent',
        path: '/manageEvent'
      },
      {
        key: 'manage_audience',
        i18n: 'menu.manageAudience',
        path: '/manageAudience'
      },
      {
        key: 'mediation_postback_setting',
        i18n: 'menu.mediationPostbackSetting',
        path: '/settings/mediaPostback',
        more: [
          {
            key: 'create_mediation_postback_setting',
            i18n: {
              id: 'action.create',
              values: { item__id: 'mediationPostbackSetting.text' }
            },
            path: '/settings/mediaPostback/create'
          },
          {
            key: 'edit_mediation_postback_setting',
            i18n: {
              id: 'action.edit',
              values: { item__id: 'mediationPostbackSetting.text' }
            },
            path: '/settings/mediaPostback/edit'
          }
        ]
      }
    ]
  },
  {
    key: 'report',
    i18n: 'menu.report',
    icon: 'ss-v2-iconfont ss-v2-icon-common-statistics',
    children: [
      {
        key: 'performance_report',
        i18n: 'menu.performanceMonitor',
        path: '/reports'
      },
      {
        key: 'event_report',
        i18n: 'menu.eventMonitor',
        path: '/reports/event'
      },
      {
        key: 'cohort_report',
        i18n: 'menu.cohortMonitor',
        path: '/reports/cohort'
      },
      {
        key: 'pixel_report',
        i18n: 'menu.eventMonitor',
        path: '/reports/pixel'
      },
      {
        key: 'creative_report',
        i18n: 'menu.creativeMonitor',
        path: '/reports/creative'
      },
      // {
      //   key: 'dco_report',
      //   i18n: 'menu.dcoMonitor',
      //   path: '/reports/dco'
      // },
      {
        key: 'skan_report',
        i18n: 'menu.skanMonitor',
        path: '/reports/skan'
      }
    ]
  },
  {
    key: 'account',
    i18n: 'menu.account',
    icon: 'ss-v2-iconfont ss-v2-icon-common-settings',
    children: [
      {
        key: 'account_basic_info',
        i18n: 'menu.basicInfo',
        path: '/account'
      },
      {
        key: 'user_management',
        i18n: 'menu.userManagement',
        path: '/account/user-management'
      },
      {
        key: 'update_password',
        i18n: 'menu.changePassword',
        path: '/account/change-password'
      },
      {
        key: 'attribution_setting',
        i18n: 'menu.attributionSetting',
        path: '/account/attribution-setting'
      },
      {
        key: 'add_funds',
        i18n: 'menu.addFunds',
        path: '/account/add-funds'
      },
      {
        key: 'billing_management',
        i18n: 'menu.billingManagement',
        path: '/account/billing-management'
      },
      {
        key: 'history',
        i18n: 'menu.operationLog',
        path: '/account/operation-log'
      }
    ]
  },
  {
    key: 'help',
    i18n: 'helpCenter.text',
    icon: 'ss-v2-iconfont ss-v2-icon-messages-question',
    children: [
      {
        key: 'userManual',
        i18n: 'helpCenter.userManual',
        isExternal: true,
        path: 'url.userManual'
      },
      {
        key: 'createCampaign',
        i18n: 'helpCenter.createCampaign',
        isExternal: true,
        path: 'url.createCampaign'
      },
      {
        key: 'creatives',
        i18n: 'helpCenter.creatives',
        isExternal: true,
        path: 'url.creatives'
      },
      {
        key: 'integration',
        i18n: 'helpCenter.integration',
        isExternal: true,
        path: 'url.integration'
      },
      {
        key: 'analyzeOptimize',
        i18n: 'helpCenter.analyzeOptimize',
        isExternal: true,
        path: 'url.analyzeOptimize'
      },
      {
        key: 'guide',
        i18n: 'helpCenter.guide',
        isExternal: true,
        path: 'url.guide'
      },
      {
        key: 'policy',
        i18n: 'helpCenter.policy',
        isExternal: true,
        path: 'url.policy'
      },
      {
        key: 'faqs',
        i18n: 'helpCenter.faqs',
        isExternal: true,
        path: 'url.faqs'
      }
    ]
  }
]

function filterMenu(keys) {
  const result = []
  const checkItems = function(origins, records) {
    origins.forEach(item => {
      if (!item.children && -1 == keys.indexOf(item.key)) return
      const tmp = Object.assign({}, item)
      if (item.children) {
        tmp.children = []
      }
      records.push(tmp)
      if (tmp.children) {
        const { children } = records[records.length - 1]
        checkItems(item.children, children)
      }
    })
  }
  checkItems(MENU, result)
  return result
}
