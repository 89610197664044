<template>
  <el-dialog
    v-if="showPlayturboDialog"
    :visible.sync="visible"
    custom-class="nofitication-dialog"
    lock-scroll
    width="900px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onClose"
    @closed="onClosed"
  >
    <div>
      <div class="close-btn" @click="visible = !visible"></div>
      <div
        class="bg"
        :class="$i18n.locale"
        @click="turnToPlayturbo"
        v-attract-click="'pt-dialog-bg'"
      ></div>
      <div style="position: relative;">
        <div class="content">
          <!-- <div class="mask"></div> -->
          <p style="white-space: pre-line; line-height: 20px">
            <span
              >{{ mxVm$t('dialog.pt.text1')
              }}<a href="javascript:void(0)" style="cursor: default;">{{
                mxVm$t('dialog.pt.link')
              }}</a
              ><span
                v-html="
                  mxVm$t({
                    id: 'dialog.pt.text2',
                    values: { term: mxVm$t('dialog.pt.term') }
                  })
                "
              ></span
            ></span>
          </p>
        </div>
      </div>
    </div>
    <div slot="footer" class="footer">
      <span>
        <el-checkbox v-model="noMore" style="line-height: 40px"> </el-checkbox>
        <span style="cursor:pointer;font-size: 14px;" @click="noMore = !noMore">
          {{ mxVm$t('dialog.pt.nomorereminders') }}
        </span>
      </span>
      <el-button
        v-attract-click="'pt-dialog'"
        type="primary"
        @click="turnToPlayturbo"
      >
        {{ mxVm$t('dialog.pt.btn') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { attractTrafficMonitor } from '~/plugins/gio/gio-monitor'
import { PT_ACTIVITY_ID, PT_ACTIVITY_PERMISSION } from '@/config/constant.js'
const KEY_PREFIX = 'PT_DIALOG'
export default {
  data() {
    return {
      showPlayturboDialog: true,
      visible: false,
      noMore: false,
      localStorageKey: ''
    }
  },
  created() {},
  watch: {
    '$store.state.account.basicInfo.id': {
      handler: function(v) {
        if (this.mxDef(v)) {
          this.localStorageKey = `${KEY_PREFIX}_${PT_ACTIVITY_ID}_${v}`
          this.tryDialog()
        }
      },
      immediate: true
    },
    visible: {
      handler: function(v) {
        if (v) attractTrafficMonitor.pv('pt-dialog')
      },
      immediate: true
    }
  },
  methods: {
    onClose() {
      if (this.noMore) {
        window.localStorage.setItem(this.localStorageKey, true)
      }
    },
    onClosed() {
      this.reset()
    },
    reset() {
      this.noMore = false
    },
    tryDialog() {
      const shown = window.localStorage.getItem(this.localStorageKey)
      if (this.$store.state.account.action[PT_ACTIVITY_PERMISSION] && !shown) {
        this.visible = true
      }
    },
    handleNoMore() {
      window.localStorage.setItem(this.localStorageKey, true)
    },
    async turnToPlayturbo() {
      if (this.noMore) this.handleNoMore()
      this.$store.commit('account/setIsLoading', true)
      const { code, data } = await this.$axios.$get('playturboSsid')
      this.$store.commit('account/setIsLoading', false)
      if (code == 200 && data) {
        this.visible = false
        const activityUrl = `${process.env.playableActivityUrl}?ss_id=${data.ss_id}&lang=${this.$i18n.locale}&place=1`
        this.$nextTick(() => {
          window.open(activityUrl, '_blank')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  padding: 0 24px 24px;
  justify-content: space-between;
  align-content: center;
}
.title {
  color: #333;
  margin-bottom: 16px;
  line-height: 22px;
  font-weight: 700;
}
.bg {
  cursor: pointer;
  padding-bottom: 38.8888%;
  // height: 25.21%;
  background-size: cover;
  img {
    display: block;
  }
  &.en {
    background-image: url(//cdn-adn-https.rayjump.com/cdn-adn/v2/portal/24/04/15/15/36/661cd901412e3.png);
    background-image: image-set(
      url(//cdn-adn-https.rayjump.com/cdn-adn/v2/portal/24/04/15/15/36/661cd901412e3.png) 1x,
      url(//cdn-adn-https.rayjump.com/cdn-adn/v2/portal/24/04/15/15/36/661cd901412e3.png) 2x
    );
  }
  &.ja {
    background-image: url(//cdn-adn-https.rayjump.com/cdn-adn/v2/portal/24/04/15/15/36/661cd8f1e8677.png);
    background-image: image-set(
      url(//cdn-adn-https.rayjump.com/cdn-adn/v2/portal/24/04/15/15/36/661cd8f1e8677.png) 1x,
      url(//cdn-adn-https.rayjump.com/cdn-adn/v2/portal/24/04/15/15/36/661cd8f1e8677.png) 2x
    );
  }
  &.cn {
    background-image: url(//cdn-adn-https.rayjump.com/cdn-adn/v2/portal/24/04/15/15/36/661cd90a4026d.png);
    background-image: image-set(
      url(//cdn-adn-https.rayjump.com/cdn-adn/v2/portal/24/04/15/15/36/661cd90a4026d.png) 1x,
      url(//cdn-adn-https.rayjump.com/cdn-adn/v2/portal/24/04/15/15/36/661cd90a4026d.png) 2x
    );
  }
}
.content {
  margin: 24px 4px;
  padding: 0 20px;
  box-sizing: border-box;
  height: 209px;
  overflow-y: auto;
  font-size: 14px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .mask {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 10px;
    height: 20px;
  }
  .title {
    font-size: 16px;
  }
}
.close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 0.6;
  // font-size: 16px;
  height: 12px;
  width: 12px;
  background-image: url(~/assets/img/dialog/close@2x.png);
  // background-image: image-set(
  //   url(~/assets/img/dialog/close@1x.png) 1x,
  //   url(~/assets/img/dialog/close@2x.png) 2x
  // );
  background-size: cover;
  color: #fff;
  cursor: pointer;
}
</style>
<style lang="scss">
.nofitication-dialog {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  color: rgb(161, 167, 196);
  max-width: unset;
  line-height: 1.4;
  & .el-dialog__header {
    display: none;
  }
  & .el-dialog__footer,
  & .el-dialog__body {
    padding: 0;
  }
  & .el-dialog__body {
    color: rgb(161, 167, 196);
  }
}
</style>
