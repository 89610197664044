<template>
  <el-dropdown>
    <div
      class="w-[24px] h-[24px] relative attract"
      :class="{
        unread: !read
      }"
      @click="onClick"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        style="vertical-align: unset; display: block;"
      >
        <rect width="24" height="24" fill="white" />
        <path
          d="M3 3H11V11H3V3ZM3 13H11V21H3V13ZM13 3H21V11H13V3ZM13 13H21V21H13V13ZM15 5V9H19V5H15ZM15 15V19H19V15H15ZM5 5V9H9V5H5ZM5 15V19H9V15H5Z"
          fill="#7E84A3"
        />
      </svg>
    </div>
    <el-dropdown-menu slot="dropdown" class="attract-dropdown">
      <el-dropdown-item v-for="item in apps" :key="item.key">
        <div
          v-attract-click="`userbar_${item.key}`"
          class="p-2 text-xs rounded hover:bg-[#F5F6FA] w-[240px] cursor-pointer flex"
          @click="jumpTo(item)"
        >
          <div>
            <svg class="w-[40px] h-[40px]">
              <use :xlink:href="item.icon" />
            </svg>
          </div>
          <div class="ml-[12px]">
            <div class="text-[#333] leading-[20px] font-semibold">
              {{ item.key }}
            </div>
            <div class="text-[#999] leading-[20px]">
              {{ mxVm$t(item.desc) }}
            </div>
          </div>
        </div>
      </el-dropdown-item>
    </el-dropdown-menu></el-dropdown
  >
</template>

<script>
import { PT_ACTIVITY_PERMISSION } from '@/config/constant.js'
import '~/assets/img/link/attract_icon.svg'
import '~/assets/img/link/xmp_dropdown.svg'
import '~/assets/img/link/pt_dropdown.svg'
const READ_ATTRACT = 'READ_ATTRACT'
export default {
  data() {
    return {
      read: true,
      visible: true,
      apps: [
        {
          key: 'XMP',
          desc: 'attractTraffic.xmp.desc',
          link: 'attractTraffic.xmp.link',
          icon: '#xmp_dropdown'
        },
        {
          key: 'Playturbo',
          desc: 'attractTraffic.pt.desc',
          link: 'attractTraffic.pt.link',
          icon: '#pt_dropdown'
        }
      ]
    }
  },
  created() {
    const res = localStorage.getItem(READ_ATTRACT)
    if (res) {
      this.read = true
    } else {
      this.read = false
    }
  },
  methods: {
    onClick() {
      this.updateReadIfNeeded()
    },
    updateReadIfNeeded() {
      if (!this.read) {
        localStorage.setItem(READ_ATTRACT, 1)
        this.read = true
      }
    },
    jumpTo(item) {
      if (item.key === 'Playturbo') {
        this.turnToPlayturbo()
      } else {
        window.open(this.mxVm$t(item.link), '_blank')
      }
    },
    async turnToPlayturbo() {
      if (this.$store.state.account.action[PT_ACTIVITY_PERMISSION]) {
        this.$store.commit('account/setIsLoading', true)
        const { code, data } = await this.$axios.$get('playturboSsid')
        this.$store.commit('account/setIsLoading', false)
        if (code == 200 && data) {
          const activityUrl = `${process.env.playableActivityUrl}?ss_id=${data.ss_id}&lang=${this.$i18n.locale}&place=2`
          window.open(activityUrl, '_blank')
        }
      } else {
        this.$store.commit('account/setIsLoading', true)
        const { id } = this.$store.state.account.basicInfo
        const { code, data } = await this.$axios.$get('accountPlayturbo', {
          params: { adv_user_id: id }
        })
        this.$store.commit('account/setIsLoading', false)
        if (code == 200 && data) {
          const { redirect_url } = data
          window.open(redirect_url, '_blank')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.attract {
  &:hover {
    path {
      fill: #00a68f;
    }
  }
  &.unread {
    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #ea1b26;
    }
  }
}
.attract-dropdown {
  .el-dropdown-menu__item:not(.is-disabled):hover,
  .el-dropdown-menu__item:focus {
    background-color: unset;
    color: unset;
  }
  li.el-dropdown-menu__item {
    padding: 0;
  }
  &.el-dropdown-menu,
  .el-menu--popup {
    padding: 12px;
    border-radius: 8px;
  }
}
</style>
