export const state = () => ({
  // 是否锁住前进后退按钮
  isBtnLock: false
})

export const mutations = {
  setBtnLock(state, data) {
    state.isBtnLock = data
  }
}

export const actions = {
  dispatchSetBtnLock({ commit }, bool) {
    commit('setBtnLock', bool)
  }
}
