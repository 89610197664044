// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/link/cr_xmp.svg?ignore");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/link/cr_xmp_highlight.svg?ignore");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/link/cr_pt.svg?ignore");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/img/link/cr_pt_highlight.svg?ignore");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-784aa902]:export{primaryColor:#00a68f;infoColor:#4f6475;headerBgColor:#000;headerTextColor:#131523;headerMenuActiveTextColor:#fff}.friend-link[data-v-784aa902]{height:14px;cursor:pointer;transition:background .3s}.friend-link.xmp[data-v-784aa902]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;width:25px}.friend-link.xmp[data-v-784aa902]:hover{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat}.friend-link.pt[data-v-784aa902]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;width:68px}.friend-link.pt[data-v-784aa902]:hover{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
