const Identify = {} // unique fetch
const FetchCache = {} // success fetch data cache
export function prefetch({ _vc, id, api, params }) {
  if (!Identify[id]) {
    Identify[id] = _vc.$axios
      .$get(api, {
        params: params,
        __skipAutoNotify: true
      })
      .then(({ code, data, msg }) => {
        FetchCache[id] = { code, data, msg }
      })
      .catch(error => {
        console.error(error)
        delete Identify[id]
      })
  }

  return Identify[id]
}

export function getPrefetch({ _vc, id, api, params }) {
  return new Promise((resolve, reject) => {
    if (FetchCache[id]) {
      resolve(FetchCache[id])
    } else if (Identify[id]) {
      Identify[id].then(resolve).catch(reject)
    } else {
      prefetch({ _vc, id, api, params })
        .then(resolve)
        .catch(reject)
    }
  })
}
