import { cloneDeep, flatMap } from 'lodash-es'

export function filterTree(oldNodes, predicate) {
  const nodes = cloneDeep(oldNodes)
  // 如果已经没有节点了，结束递归
  if (!(nodes && nodes.length)) {
    return []
  }

  const newNodes = []
  for (const node of nodes) {
    if (predicate(node)) {
      // 如果节点符合条件，直接加入新的节点集
      newNodes.push(node)
      node.c = filterTree(node.c, predicate)
    } else {
      // 如果当前节点不符合条件，递归过滤子节点，
      // 把符合条件的子节点提升上来，并入新节点集
      newNodes.push(...filterTree(node.c, predicate))
    }
  }
  return newNodes
}

export function getTreeNodes(tree, predicate) {
  const queue = [...tree]
  const nodes = []

  // get child's key
  while (queue.length) {
    const curNode = queue.shift()
    nodes.push(curNode)

    if (curNode.c && curNode.c.length) {
      queue.push(...curNode.c)
    }
  }

  return nodes
}

export function getTreeLeaf(tree) {
  const nodes = getTreeNodes(tree)
  return nodes.filter(node => !node.c || node.c.length === 0)
}

export function getLeafValues(node) {
  if (!node.c || node.c.length === 0) {
    return [node]
  } else {
    return flatMap(node.c, getLeafValues)
  }
}
