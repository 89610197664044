/* eslint-disable prettier/prettier */
import elementUILocale from 'element-ui/lib/locale/lang/en'

export default {
  ...elementUILocale,
  versionDetect: {
    newVersion: 'A new version has been detected. Please refresh the page.',
    refreshApp: 'Refresh',
    prompt: 'Prompt'
  },
  time: {
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
    AllDays: 'All Days',
    timeSumDesc: 'Period selected ({timeSum} hours total per week)'
  },
  error: {
    unknown: 'Unknown Error',
    clientSide: 'Client Side Error',
    serverSide: 'Server Side Error',
    errorCode: 'Status Code {code}',
    noPermission: 'No permission',
    expiredTitle: 'Expired',
    expiredLogin: 'Please login again',
    noLogin: 'Please login first',
    errorPage: 'Error Page',
    system: 'System error, please contact the administrator.'
  },
  menu: {
    overview: 'Overview',
    summaryReport: 'Summary Report',
    userAcquisition: 'User Acquisition',
    manageEvent: 'Manage Event',
    manageAudience: 'Manage Audience',
    campaignList: 'Campaign List',
    offerList: 'Offer List',
    creativeLib: 'Manage Creative',
    report: 'Reports',
    performanceMonitor: 'Performance Monitor',
    dcoMonitor: 'DCO Monitor',
    eventMonitor: 'Event Monitor',
    cohortMonitor: 'Cohort Report',
    creativeMonitor: 'Creative Monitor',
    account: 'Account',
    basicInfo: 'Basic Information',
    userManagement: 'User Management',
    changePassword: 'Change Password',
    attributionSetting: 'Attribution Setting',
    addFunds: 'Add Funds',
    billingManagement: 'Billing Management',
    operationLog: 'Operation Log',
    targetSetting: 'Target Setting',
    reportingAndApi: 'Reporting&API',
    playturbo: 'Playturbo',
    skanMonitor: 'SKAN Monitor',
    mediationPostbackSetting: 'Mediation Postback Setting'
  },
  action: {
    text: 'Action',
    searchText: 'Search',
    search: 'Search {item}',
    export: 'Export {item}',
    create: 'Create {item}',
    add: 'Add {item}',
    copy: 'Quick Add {item}',
    copy_1: 'Copy {item}',
    edit: 'Edit {item}',
    view: 'View {item}',
    detail: '{item} Detail',
    upload: 'Upload {item}',
    dragUpload: 'Drop file here or click to upload',
    download: 'Download {item}',
    next: 'Next',
    back: 'Back',
    submit: 'Submit',
    cancel: 'Cancel',
    updateAll: 'Update All',
    include: 'Include',
    exclude: 'Exclude',
    select: 'Select',
    click: 'Click',
    impression: 'Impression',
    save: 'Save',
    apply: 'Apply',
    confirm: 'Confirm',
    delete: 'Delete {item}',
    deleteText: 'Delete',
    selectToDelete: 'Select to Delete',
    remove: 'Remove',
    removeItem: 'Remove {item}',
    upload: 'Upload  {item}',
    narrow: 'Narrow',
    futureNarrow: 'Narrow Further',
    maintainByAM: 'Maintain by AM',
    install: 'Install',
    sureToContinue: 'Sure to continue?',
    close: 'Close',
    goBack: ' Go Back',
    preview: 'Preview',
    skip: 'SKIP',
    cancelSearch: 'Cancel Search',
    nextStep: 'Next Step',
    submitAgain: 'Submit Again',
    clear: 'Clear',
    expand: 'Expand'
  },
  common: {
    else: 'Other',
    featureUpgrade: 'The feature is updating, please try again later',
    version: 'Version',
    pageTitle: '{title} - Mintegral Advertiser Self-service Platform',
    title: 'Advertiser Self-service Platform',
    logout: 'Logout',
    name: '{item} Name',
    status: '{item} Status',
    id: '{item} ID',
    type: '{item} Type',
    to: '-',
    success: 'Success',
    successful: 'Successful',
    failed: 'Failed',
    pureSuccess: 'Success',
    pureFail: 'Fail',
    error: 'Error',
    reason: 'Reason',
    row: 'Row',
    autoRedirect: 'Automatically redirect...',
    notes: 'Notes',
    url: '{item} URL',
    variables: '{item} Variables',
    optional: 'Optional',
    ok: 'OK',
    sureToContinue: 'Sure to continue?',
    sureToDelete: 'Sure to delete?',
    sureToRun: 'Sure to run?',
    sureToStop: 'Sure to stop?',
    message: 'Message',
    global: 'Global',
    yes: 'Yes',
    no: 'No',
    backToHome: 'Go back homepage',
    adBlockTips:
      'We noticed that you seems to have ad-block plugins enabled in browser, please disable it for this site to avoid issues of using our service.',
    copy: 'Copy',
    showAll: 'Show All',
    contactUs: 'Contact Us',
    summary: 'Summary',
    newItem: 'New {item}',
    moreTemplate: '{num} more',
    requiredItem: 'Required {item}',
    optionalItem: 'Optional {item}',
    duplicateUpload: 'Duplicate upload',
    selectAll: 'Select All',
    clearAll: 'Clear All',
    exclude: 'Exclude',
    freezeTips: 'The data is too large and page may freeze for a moment',
    redirecting: 'Redirecting...',
    uploadNetworkError: 'The current network is abnormal, please try again.',
    charFullStop: '.',
    notice: 'Notice',
    userChangeTips: `You're currently signed in as {name}, current page has been refreshed automatically.`,
    step: 'Step {num}',
    enableBtn: 'Enable',
    pauseBtn: 'Pause',
    enable: 'enable',
    pause: 'pause',
    selectCurrentPage: 'Select Current Page ({count})',
    selectAll: 'Select all ({count})',
    processing: 'Processing……',
    all: 'All',
    custom: 'Custom',
    selected: 'Selected',
    selectable: 'Selectable',
    advancedSetting: 'Advanced Setting',
    getTemplateWrap1: 'Please Click ',
    getTemplateWrap2: ' to get template',
    here: 'here',
    devices: {
      oppo: 'Oppo',
      vivo: 'Vivo',
      xiaomi: 'Xiaomi',
      redmi: 'Redmi',
      huawei: 'Huawei',
      honor: 'Honor',
      amazon: 'Amazon'
    },
    maximumOf: 'Maximum number of {item} is {num}',
    applyFor: 'Apply {item} For {target}'
  },
  recaptcha: {
    tecentText: "I'm not a robot"
  },
  data: {
    text: 'Data',
    spendCvrCpiTips: 'Spend, CVR and CPI numbers cover the last 7 days',
    eventSpendTips: 'event%=Sum(event)/click',
    spend: 'Spend',
    cvr: 'CVR',
    cpi: 'CPI',
    cpiTips: 'CPI = Spend / Conversions',
    original_money: 'Spend',
    click: 'Clicks',
    install: 'Conversions',
    impression: 'Impressions',
    adv_original_money: 'Spend',
    adv_click: 'Click',
    adv_install: 'Conversions',
    adv_impression: 'Impressions',
    package_name: 'Package Name',
    ecpi: 'CPI',
    ecpm: 'eCPM',
    ecpc: 'CPC',
    ctr: 'CTR',
    avg_cvr: 'CVR',
    app: 'App',
    country_code: 'Location',
    ad_type: 'Ad Type',
    balance: 'Balance',
    remainingCredit: 'Remaining Credit',
    partnerCredit: 'Credit',
    timestamp: 'Hour',
    date: 'Day',
    week: 'Week',
    month: 'Month',
    today: 'Today',
    yesterday: 'Yesterday',
    lastDays: 'Last {num} days',
    currentMonth: 'Current month',
    lastMonth: 'Last month',
    uploadResult: 'Upload Results',
    uploadTotal: 'Total',
    uploadSuccess: 'Success',
    uploadFailed: 'Failed',
    uploadFile: 'File',
    uploadError: 'Error',
    all: 'All',
    specific: 'Specific {item}',
    gather: 'Total',
    action: 'Action',
    language: 'Language',
    translation: 'Translation',
    value: 'Value',
    platform: 'Platform',
    campaign: 'Campaign',
    bidRate: 'Bid Rate',
    originalBidRate: 'Original Bid Rate',
    location: 'Location'
  },
  rule: {
    specialCharsNotAllowed: 'Only letter, number, hyphen and underscore',
    uploadMaxSize: 'File is beyond the maximum of {size}',
    uploadFormat: 'File does not match format requirement ({requirement})',
    uploadType: 'File does not match type requirement ({requirement})',
    uploadDimension:
      'File does not match dimension requirement ({requirement})',
    uploadDuration:
      'File does not match duration requirement (less than {requirement} seconds)',
    uploadExceed: 'Only allow {num} files',
    uploadExceedImage: 'Only allow {num} pictures',
    uploadExceedVideo: 'Only allow {num} videos',
    uploadExceedCreatives:
      'Only allow {pictureNum} pictures and {videoNum} videos',
    uploadFilename:
      'File name can only contain letter, number, hyphen and underscore, and should be within {length} characters',
    length: 'Length should be {min} to {max}',
    minlength: 'Length should be over {min}',
    letterNumberUnderlineOnly: 'Only letter, number and underscore',
    letterNumberUnderlineCommaOnly: 'Only letter, number, underscore and comma',
    usernameRule:
      'The first character must be an alphabet;only letter, number and underscore within 3 to 32 characters',
    isRequired: 'Required',
    fieldRequired: 'This field is required',
    invalidStoreId: 'Invalid Store ID',
    invalidValue: 'Invalid Value',
    url: 'Please input a VALID url',
    intNumber: 'Only numbers greater than 0',
    number: 'Only numbers greater than 0, with {num} decimal places',
    integer: 'Only allow to set integer between {min} to {max}',
    version: 'Please set as "x.x" or "x.x.x"',
    email: 'This is not a valid email address. Please Try again.',
    password:
      '8 to 20 characters long, combination of digit, capital and lowercase letter (eg. Psw1234567).',
    captcha: 'Please click to verify',
    agreement: 'Please read and check the terms',
    queryTimeError: 'Invalid time',
    commonName:
      'only letter, number, hyphen and underscore,within {length} characters',
    targetAppLimit: 'App number in "Target App" is beyond the maximum of 2000.',
    commonLength: 'Within {length} characters'
  },
  campaign: {
    text: 'Campaign',
    totalOffers: 'Total Offers',
    totalOffersTips: 'The number of offers related to this campaign',
    activeOffers: 'Active Offers',
    activeOffersTips: 'The number of offers currently in running status',
    targetApp: 'Target App',
    promoteType: 'Promote Type',
    previewLink: 'Preview Link',
    previewLinkTipsObj: {
      title: 'Supported App Store: ',
      vivoDesc:
        'If you don‘t have a preview link，you can use "vivomarket://details?id=${YourPackageName}"',
      oppoDesc:
        'If you don‘t have a preview link，you can use "oppomarket://details?id=${YourPackageName}"'
    },
    previewLinkPlaceholder:
      'Please enter the download URL of your App in iTunes App Store, Google Play, etc',
    previewLinkTips:
      'App not in the store yet? App download link is also accepted',
    previewLinkUploadBtnText: 'Upload your .apk file here',
    previewLinkTips2: 'for promotion',
    previewLinkUploadError: 'Please upload .apk file.',
    previewLinkPlatformError:
      'Please enter the right preview link and app platform can not be changed',
    landingPage: 'Landing Page',
    landingPagePlaceholder: `Please enter the landing page url you'd like to promote`,
    miniprogramUrl: 'Wechat Mini Program Path',
    miniprogramUrlPlaceholder:
      'Wechat Mini Program url. Enable to put " index.htm" as default.',
    miniprogramName: 'Wechat Mini Program Name',
    miniprogramNamePlaceholder:
      'Please input the name of wechat mini program here',
    miniprogramOriId: 'Wechat Mini Program Ori ID',
    miniprogramOriIdPlaceholder:
      'Please input the original id of wechat mini program here. For example, gh_xxxxxxxxx',
    coppaTips: 'This campaign is directed to children according to the',
    coppa: 'Children’s Online Privacy Protection Act (COPPA) 16 CFR 312',
    coppaTipsSuffix: '', // leave blank
    updateAllTips:
      'If you checked this field, all the "Target App" settings in offers which related to this campaign would be updated to the new value, otherwise the setting will take effect on new offers only.',
    platform: '{item} Platform',
    storeId: 'Store ID',
    appStore: 'App Store',
    appStoreTipsRow1:
      'Please select the app store where your app is available.',
    appStoreTipsRow2: 'For non-app store applications, please select Other.',
    areaType: 'Data Cluster',
    areaTypeOptions: {
      oversea: 'Overseas',
      cn: 'CN'
    },
    app: 'App',
    website: 'Website',
    miniprogram: 'Wechat Mini Program',
    ctv: 'CTV',
    ctvOption: 'CTV',
    appOption: 'App',
    websiteOption: `Not app, it's a website`,
    miniprogramOption: 'Wechat Mini Program',
    miniprogramTrackinglinkRequired:
      'Wechat Mini Program url. Enable to put " index.htm" as default.',
    icon: '{item} Icon',
    size: '{item} Size',
    package: '{item} Package',
    category: '{item} Category',
    categoryPlatformTips: 'Please choose a platform first',
    version: '{item} Version',
    description: '{item} Description',
    packageRuleTips:
      'Invalid store id, please check.(e.g.,idxxxxxxxxxx or com.xxx.xxx)',
    packageRuleTipsCampaign:
      'Package Name must be the same as the package name in Preview Link',
    packageRuleReg: 'Package name must be among a~z A~Z 0~9 . _',
    appVersion: 'App Version',
    updateTime: 'Update Time',
    developerName: 'Developer Name',
    privacyUrl: 'Privacy Url',
    sensitivePermission: 'Sensitive Permission',
    status: 'Delivery',
    dailyBudget: 'Daily Budget',
    dailyBudgetTips:
      '1. Once campaign run over cap, campaign and related offers will be paused automatically. \n 2. Daily cap will take effect by GMT+8 time zone only.',
    openDailyBudget: 'Open Budget (No Budget Cap)',
    sureToChangeStatus: 'Are you sure wish to {status} the campaign?',
    changeStatusSucDesc:
      'Successfully modified {successCount} campaign(total: {total}).',
    changeStatusFailDesc:
      'Unsuccessfully modified {failCount} campaign(total: {total}). The failed details is as followed.'
  },
  offer: {
    text: 'Offer',
    relatedCampaign: 'Related Campaign',
    relatedCampaignTips:
      'The ads objective you want to promote, Offer is created for running the campaign',
    status: 'Delivery',
    bidType: 'Bid Type',
    bidTypeErrForCtv:
      'CTV Campaign does not support creating Target CPE or Target ROAS Offer. Please make a different selection!',
    billingType: 'Billing Type',
    addDeepTarget: 'Add Optimization Goal', // author: zy
    addDeepTargetDesc: `1. The current offer needs to enable the Optimization Goal, Mintegral will select traffic that meets both the shallow and deep targets to be placed after disposing of the Optimization Goal.<br/>2. The setting cannot be modified once selected and submitted<br/>3. After enabling the optimization goal, please make sure to post back the deep optimization events, otherwise the Event Optimization will be impacted.<br/>4. After activation, the cold start will collect the Optimization Goals' data (cold start will take around three days, and it's required to accumulate at least 20 optimization events)`, // author: zy
    deepTarget: 'Optimization Goal', // author: zy
    deepTargetDesc: {
      mainDesc: `1. Please make sure the event name posted back from MMP is mapped with Mintegral's Event Optimization name (e.g., it should be equipped with Mintegral open event if the Optimization Goal is Day 1 retention), {clickDesc1} to check.<br/>2. No extra setting is required when using MMP's default event postback. `,
      endDesc: `to check the MMP's postback event and optimization event comparative table)`, // author:
      clickDesc1: `click here`,
      clickDesc2: `Click here`
    },
    deepTargetTypes: {
      // 深度目标类型 author: zy
      day1_retention: {
        label: 'Day1 Retention',
        tips:
          'Set Day 1 Retention as the Optimization Goal, acquiring users with a higher retention rate'
      },
      first_purchase: {
        label: 'First Purchase',
        tips:
          'Set In-App Purchase( First purchase) as the Optimization Goal, acquiring users with a greater chance to pay'
      },
      register: {
        label: 'Register',
        tips:
          'Set Registration as the Optimization Goal, acquiring users with a higher registration intention'
      },
      key_action: {
        label: 'Key Action',
        tips:
          'Set a Key Action Event as the Optimization Goal, acquiring users with a greater chance to install and meet the Key Action Optimization Goal.'
      }
    },
    covertRate: {
      // 深度目标转化率说明文案 author: zy
      formLabel: 'Target Ratio For Optimization Goal',
      tips: `1. This portal is to set the Optimization Goals' rate, for example, if the target Day 1 Retention rate is 20%, the goals rate is 20%<br/>2. Please set a reasonable threshold, as the result will impact the volume of traffic.<br/>3. At the beginning, it is essential to collect and accumulate reasonable data (the threshold should not be too high)<br/>4. Frequently changing the Optimization Goals' rate is not allowed. Please set it carefully as it takes 48 hours to update)`,
      validMsg: 'Please input an number from 0 to 100，with 3 decimal places',
      addDesc: `Frequently changing the Optimization Goals' rate is not allowed. Please set it carefully as it takes 48 hours to update`,
      editDesc: `(last submission updated : utc {time} )`
    },
    thirdEventDialogTitle: `MMP's Default Event`,
    thirdEventTbCols: {
      // 三方事件列表文案
      third_party: 'MMP',
      event_name: 'Default',
      deep_target: 'Optimization Goal'
    },
    targetGoalType: 'Target Goal Type',
    targetGoalWindow: 'Target Goal Window',
    targetGoalWindowTipsForRoas:
      'D3 and D7 ROAS offers temporarily do not support customers creating offers by themselves. If you have any questions, please contact the Mintegral team.',
    targetGoalWindowTips:
      'Day 0: optimize CPE within 24 hours <br />Day 7: optimize CPE within 192 hours',
    targetEventForRoas: {
      iaa: 'Ad Revenue',
      iap: 'Purchase'
    },
    campaignTipsForRoas: {
      iap: 'This campaign currently supports ROAS optimization for Ad Revenue.',
      iaa: {
        '102': `The current campaign supports ROAS optimization for Purchase Revenue.
           And the Ad Revenue events for this campaign are not in real-time, preventing ROAS optimization. Please update Ad Revenue to real-time and then retry.`,
        '101': `1.The Ad Revenue data received is currently insufficient to meet the creation requirements. Please continue accumulating more Ad Revenue data and try again.
           2.IAP Revenue data meets the creation requirements.`
      },
      noEvent: {
        iaa102:
          'the Ad Revenue events for this campaign are not in real-time and no IAP Data postback received, unable to create ROAS campaign for now.',
        iaa101:
          'The Ad Revenue data received is currently insufficient to meet the creation requirements and no IAP Data postback received, unable to create ROAS campaign for now.'
      }
    },
    targetEventTipsForRoas: {
      iap: `This campaign hasn't received purchase event data for ROAS optimization with IAP Revenue.`,
      iaa: {
        '102':
          'Ad Revenue events for this campaign are not in real-time, preventing ROAS optimization. Please update Ad Revenue to real-time postback then retry',
        '101':
          'The Ad Revenue  data received are currently insufficient to meet the creation requirements. Please continue accumulating more data and then retry.'
      },
      noEvent:
        'The Ad Revenue and IAP Revenue data received are currently insufficient to meet the creation requirements. Please continue accumulating more data and then retry. '
    },
    dataSource: 'Data Source',
    setTargetGoal: 'Set Target Goal',
    setTargetGoalTips:
      'Please avoid frequent/significant change. It is recommended to adjust the target ROAS with a limit of 10% and with an interval of 24 hours.',
    setTargetGoalByCountry: 'Set Target Goal By Country',
    addRoasGoalByCountry: 'Add Roas Goal by Country / Region',
    setCpeGoalByCountry: 'Set CPE Goal By Country',
    targetCpeGoal: 'Target CPE Goal',
    targetCpeGoalTips: {
      forWindowD0:
        'Day 0 Target CPE Goal = Total spend on user acquisition across all networks / Day 0 event unique users (excluding organic users)',
      forWindowD7:
        'Day 7 Target CPE Goal = Total spend on user acquisition across all networks / Day 7 event unique users (excluding organic users)'
    },
    addCpeGoalByCountry: 'Add CPE Goal by Country',
    numberValidateError:
      '{title} should be above {min} and below {max}, with {decimal} places',
    targetEvent: 'Event Name',
    targetEventTip1_1:
      'a. For now, we only support <strong style="color: #333">Purchase</strong> Events. If the event name can not be found, please confirm that the event postback configuration on the MMP side is correct, and the event is mapped to "Purchase" in',
    targetEventTip1_2:
      '. (Details: <a href="https://alidocs.dingtalk.com/i/p/ZR2PmK51081zvpO72AmoVYPYWRNyVXdb?dontjump=true" target="_blank">MMP Attribution Guidance</a>)<br/>b. To shorten the learning phase and improve the optimization effect, it is necessary to enable all network install & event postback and configure the event mapping at least <span style="color: red">14</span> days in advance.',
    targetEventTip2:
      'a. To shorten the learning phase and improve the optimization effect, it is necessary to enable all network install & event postback and configure the event mapping at least <span style="color: red">14</span> days in advance. (Details: <a href="https://alidocs.dingtalk.com/i/p/ZR2PmK51081zvpO72AmoVYPYWRNyVXdb?dontjump=true" target="_blank">MMP Attribution Guidance</a>)',
    country: 'Location',
    createdTime: 'Created',
    promoteTime: 'Promote',
    setTracking: 'Set Tracking Link',
    editCreative: 'Edit Creative',
    'basic-info': 'Input Basic Info',
    'basic-info-detail': 'Basic Info',
    givenBidType: 'Bid Goal',
    givenBidTypeTips:
      'For more bid goals, please fill the <a target="_blank" href="//docs.google.com/forms/d/1NY46QHIz7fzq_VDMr_HoUbKQolaJJ-UHXR21eSuhJmc/edit">form</a> to get in touch with us.',
    targetRoasGuidanceText: 'Guidance to Target-Roas Optimizer',
    targetRoasGuidanceLink:
      'https://alidocs.dingtalk.com/i/p/ZR2PmK51081zvpO72AmoVJl7EWl2EXdb',
    targetCpeGuidanceText: 'Guidance to Target-CPE Optimizer',
    targetCpeGuidanceLink:
      'https://alidocs.dingtalk.com/i/p/ZR2PmK51081zvpO72AmoV9QdrYd0jXdb?rnd=0.9703118694003117&dontjump=true',
    'bid-rate-budget': 'Set Bid Rate & Budget',
    'bid-rate-budget-detail': 'Bid Rate & Budget',
    'target-info': 'Fulfill Targeting Info',
    'target-info-detail': 'Targeting Info',
    'creative-info': 'Upload Creatives',
    'creative-info-detail': 'Creative Groups',
    endTimeSuggestion:
      'We suggest you to promote at least {num} hours to get more traffic, sure to continue?',
    promotePeriods: 'Delivery Time',
    deliveryTimeZone: 'Delivery Timezone',
    noEndTime: 'No end date',
    longTerm: 'Long-term',
    longTermTips:
      'If you prefer to manually stop the offer，please select long-term delivery.',
    customDeliverSchedule: 'Configure start and end time',
    promotePeriodsTips:
      'If you prefer to manually stop the offer, please set the value of "end time" to null',
    deliveryTime: 'Delivery Time',
    deliveryTimeTips:
      'Promotion time must be at least 6 hours, please set the delivery time and delivery schedual as required',
    deliverySchedule: 'Delivery Schedule',
    timezone: 'Timezone',
    startTime: 'Start Time',
    startImmediately: 'Now',
    startTimeError: '"Start Time" must be earlier than "End Time"',
    endTime: 'End Time',
    endTimeError: '"End Time" must be later than "Start Time"',
    promoteError: 'Promotion time must be at least 6 hours',
    startDate: 'Start Date',
    endDate: 'End Date',
    userActivation: 'User Activation',
    userActivationTip:
      'Reactivate dormant or inactive users, for retargeting offer.',
    cpiTips: 'Cost per Install . When a user installs and runs your app',
    cpeTips: `Cost per Event. When a user takes an action that you've specified`,
    cpcTips: 'Cost per Click. When a user click the advertisement',
    cpmTips: 'Cost per 1000 advertisement Impressions',
    ocpiTips:
      'oCPI is a nonfixed CPI bidding method, i.e. the algorithm will adjust the bid based on the value of users automatically. Superior to the CPI model, the dynamic bidding model allows you to achieve higher bidding competitveness and unlock new audiences while fulfilling your ROAS target.',
    settlementEvent: 'Settlement Event',
    settlementMultipleEvent: 'Settlement By Multiple Event',
    settlementSetting: 'Settlement Setting',
    eventCategory: 'Event Category',
    bidRate: 'Default Bid Rate',
    bidRatePlaceholder: 'Cost per every conversion',
    bidRateError:
      'Bid Rate should be above {min} and below {max}, with {decimals} decimal places',
    bidRateByCountry: 'Bid Rate by Location',
    addBidRateByCountry: 'Add Bid Rate by Location',
    futureBidRate: 'Future Bid Rate',
    bidRateGroup: 'Bid Rate Group',
    roasCompaignNotAllowTips: {
      '101':
        'Inadquate data. 10 ad-revenue events for 3 consecutive days are required.',
      '102':
        'Real-time ad-revenue is required. Please check if the data postback is real-time or you can contact your account manager for more help.'
    },
    roasInputAll: 'Input all {goalWindow} roas goal and cold start price',
    roasCountry: 'Country',
    roasGoal: 'Roas Goal',
    suggestRoasGoal: 'suggest roas goal',
    coldStartPrice: 'Cold Start Price',
    coldStartPriceTips:
      'The coldstart phase lasts for 3 days at least. At this phase, the billing type remains as CPI model. It is recommended to set the bid at 1.2 times the CPI model bid.',
    roasGoalError:
      'D0 Roas Goal should be above {min} and below {max}, with {decimals} decimal places',
    budgetType: 'Budget Type',
    budgetTypeTips: `Your offer will be paused once the budget is reached. However, installs may in from users after the pause causing spend to exceed the budget. If this is happens, consider lowering your budget to give a 'buffer'`,
    allocateBudgetByDay: 'Allocate the budget by day',
    allocateBudgetByDayTips:
      'Your offer will be paused once the daily budget is reached and will be restarted automatically at 00:00 of the next day',
    allocateBudgetByPeriod: 'Allocate the budget by a period of time',
    allocateBudgetByPeriodTips:
      'Your offer will be paused once the budget is reached. You need to raise the budget to restart the offer',
    dailySpendingBudget: 'Daily Spending Budget',
    averageDailySpendingBudget: 'Average Daily Spending Budget',
    averageSpendingBudgetTips: {
      forAllArea:
        'The budget modification will take effect the next day. If the periodic spending amount exceeds the cyclical available budget, modifying the budget will result in offer suspension. Please set it appropriately and avoid frequent modifications.',
      forSpecialArea:
        'The regional modification will take effect immediately and the cyclical budget calculation will be restarted. The amount spent in this cycle will no longer be counted. Please set it appropriately and avoid frequent modifications.',
      moreInfoTips: 'Click <a href="https://alidocs.dingtalk.com/i/p/ZR2PmK51081zvpO72AmoV0WdqBqoDXdb?dontjump=true" target="_blank">here</a> to learn more information.'
    },
    dailySpendingBudgetError:
      'Budget should not be set below {min} or beyond {max}',
    dailySpendingBudgetsuggestion:
      'Budget Suggestion: to accumulate an adequate number of unique event users during the learning phase, we recommend setting a daily budget of at least 10 times the target CPE goal (Daily Budget >= 10 * Target CPE goal)',
    dailySpendingCapError: 'Daily conversion is an integer greater than {min}',
    totalSpendingBudget: 'Total Spending Budget',
    totalSpendingBudgetError:
      'Budget should be set below minimum of {min} and beyond the maximum of {max}',
    spendingBudgetValueError:
      'Budget should be at least {num} times greater than Bid Rate',
    openBudget: 'Open budget (no budget cap)',
    futureBudget: 'Future Budget',
    statusOverCap:
      'Please raise the total spending budget to restart the offer and it may take a few minutes to run again',
    statusOverDailyCap:
      'Please raise the daily spending budget to restart the offer and it may take a few minutes to run again',
    statusStoppedNoEnoughMoney:
      'The balance on your account is insufficient, please recharge it first.',
    statusStoppedNoEnoughCredit:
      'Please contact your AM to check account setup.',
    addFutureBidSetting: 'Prospective Bid Setting',
    addFuturebudgetSetting: 'Advanced Budget Setting',
    addFutureGeo: 'Add Future GEO',
    currentBidRate: 'Current Bid Rate',
    newBidRate: 'New Bid Rate',
    totalBudgetCountedFrom: 'Total Budget Counted From',
    effectiveTime: 'Effective Time',
    effectiveTimeError: `Time can't be later than current time corresponding to timezone`,
    basicTarget: 'Basic Targeting',
    advancedTarget: 'Advanced Targeting',
    targetDevice: 'Device Type',
    targetVersion: 'System Version',
    targetMinVersionPlaceholder: 'Min Version (E.g. x.x.x)',
    targetMaxVersionPlaceholder: 'Max Version (E.g. x.x.x)',
    targetGeo: 'Target Locations',
    targetGlobalGeo: 'Global',
    targetSpecificGeo: 'Custom Country / City',
    cityCodeTreePlaceholder: 'Type to add locations',
    addLocationInBulk: 'Add Location in Bulk',
    futureGeo: 'Future Geo',
    targetGeoChangeTips:
      'Due to the value changes of Target Locations, Bid Rate by Location ({code}) will be deleted. ',
    targetGeoBudgetChangetTips:
      'Due to the value changes of Target Locations, Budget by Location ({code}) will be deleted. ',
    targetGeoFutureBudgetChangetTips:
      'Due to the value changes of Target Locations, Budget in future task by Location ({code}) will be deleted. ',
    targetGeoCreativeChangeTips: `Due to the changes of target locations, creative won't be applied in region which has been deleted.(Deleted region:{code})`,
    targetGeoPlayableChangeTips: `Due to the changes of target locations, playable creative won't be applied in region which has been deleted.(Deleted region:{code})`,
    targetGeoDcoChangeTips: `Due to the changes of target locations, dco creative won't be applied in region which has been deleted.(Deleted region:{code})`,
    targetGeoCreativeEnlargeTips: `Due to the changes of target locations, creative (creative name:{code})won't be applied in region which has been deleted, please specify region for these creatives again，otherwise, they are be used in all the offer target area.`,
    targetGeoPlayableEnlargeTips: `Due to the changes of target locations, playable creative (creative name:{code})won't be applied in region which has been deleted, please specify region for these creatives again，otherwise, they are be used in all the offer target area.`,
    targetGeoDcoEnlargeTips: `Due to the changes of target locations, dco creative (creative name:{code})won't be applied in region which has been deleted, please specify region for these creatives again，otherwise, they are be used in all the offer target area.`,
    targetGeoBasicCreativeChangeTips: `Due to the changes of target locations,Text assets won't be applied in region which has been deleted.(Deleted region:{code}) `,
    targetSource: 'Target Source',
    targetSourceChangeTips:
      'Due to the value changes of Traffic Source, Ad Type Rewarded Appwall you set will be updated to Appwall. ',
    rewardedSource: 'Rewarded',
    nonRewardedSource: 'Non-Rewarded',
    targetNetwork: 'Network',
    targetCustomNetwork: 'Custom Network',
    targetCarrier: 'Target Carrier',
    targetAllCarrier: 'All Carriers',
    targetSpecificCarrier: 'Specific Carriers',
    targetCustomDevice: 'Model Type',
    targetCustomDeviceError:
      'Please select at least one model under the {brands} brand for direct jump to {brands} app store.',
    deviceTreePlaceholder: 'Type to add model type',
    deviceIdNeeds: 'Device ID Needs',
    deviceIdNeedsAll: 'All',
    deviceIdNeedsID: 'Only Device ID',
    deviceIdNeedsIPUA: 'Only IP+UA',
    deviceIdType: 'Device ID Type',
    targetAllCustomDevice: 'All Devices',
    targetSpecificCustomDevice: 'Custom Devices',
    targetSpecificCustomDeviceInclude: 'Include', // zy
    targetSpecificCustomDeviceExclude: 'Exclude', // zy
    category: 'Category',
    targetCategory: 'Target Category',
    targetAllCategory: 'All Categories',
    targetSpecificCategory: 'Custom Categories',
    categoryTreePlaceholder: 'Type to add category',
    targetCustomAudience: 'Custom Audiences',
    targetAllCustomAudience: 'All Audiences',
    targetSpecificCustomAudience: 'Custom Audiences',
    targetGender: 'Gender',
    targetGenderMale: 'Male',
    targetGenderFemale: 'Female',
    targetAge: 'Age',
    targetCustomAge: 'Custom Age',
    targetInterest: 'Interest',
    targetAllInterest: 'All Interest',
    targetSpecificInterest: 'Custom Interest',
    targetIncludeMatchInterest:
      'Include people who match at least one of the following:',
    targetMustMatchInterest:
      'And must also match at least one of the following:',
    targetApp: 'Target App',
    targetLogic: 'Target Logic',
    AppDetails: 'App Details',
    AppId: 'App ID',
    StoreId: 'Store ID',
    AppIdPlaceholder:
      'You can put each App ID(mtgxxxxx) on a new line or separate them using commas.\nExamples:\nmtg17000111\nmtg1700112,mtg1700113',
    StoreIdPlaceholder:
      'You can put each Store ID(idxxxxx or com.xxxx.xxx) on a new line or separate them using commas.\nExamples:\nid414478124\ncom.tencent.mm,id414478124',
    targetAllApp: 'All App',
    targetSpecificApp: 'Custom App',
    targetCustomAdSchedule: 'Custom Ad Schedule',
    targetAllAdSchedule: 'All',
    targetSpecificAdSchedule: 'Custom Ad Schedule',
    targetDeviceLanguage: 'Device Language',
    targetAllCustomDeviceLanguage: 'All Device Language',
    targetSpecificCustomDeviceLanguage: 'Custom Device Language',
    languageTreePlaceholder: 'Type to add language',
    retargetDevice: 'Retargeting Device',
    retargetDeviceTips:
      'You can upload a txt file with a list of advertising IDs you wish to target',
    retargetWay: 'Retargeting Way',
    retargetUploadOption: 'Upload deviceid file',
    retargetUploadOptionTips:
      'Upload a txt file with a list of advertising IDs you wish to target. Please noted deviceid should be placed line by line and the maximum of file size is 200M.',
    retargetWebsiteOption: 'Retargeting my website visitors',
    retargetWebsiteOptionTips:
      'Select people who visited your website or took specific actions',
    retargetVistorType: 'Visitor Type',
    retargetVistorTypeInclude: 'Iclude my website visitors',
    retargetVistorTypeExclude: 'Exclude my website visitors',
    retargetVistorTime: 'Visitor Time',
    retargetVistorTimeTextPrefix: `Visitors' behavior happened in the past`,
    retargetVistorTimeTextSufix: 'days',
    retargetVistorAction: 'Visitor Action',
    retargetAllVistorAction: 'All Action',
    retargetSpecificVistorAction: 'Specific Action',
    retargetFromOffer: 'From Offer',
    cancelFutureSetting: 'Cancel future setting',
    selectDateAndTime: 'Select date and time',
    effectiveTimeEarlierError: 'Effective time must be after 30 minutes',
    effectiveDayEarlierError: 'Effective time must be after 1 day',
    targetCity: 'Specific Country/City',
    setTrackingTips:
      'Offer has been created successfully, please set up a tracking link to run the offer.',
    setTrackingOptionNo: 'No, maybe later',
    setTrackingOptionYes: 'Yes, set now',
    creativeTitle: 'Creatives',
    creativeUploadNew: 'Upload New Creatives',
    creativeAddExisting: 'Add Existing Creatives',
    creativeUpload: 'Upload Creatives',
    creativeRelatedCampaignTips: 'Select campaign with available creatives',
    creativeDeleteRelated:
      'Creatives ({creatives}) already referenced by other offers ({offers}), if this creative is deleted, the creative in other offers will also be deleted.',
    creativeCampaignTips: 'Please choose another campaign',
    creativeAdType: 'Ad Type',
    creativeAdOutputType: 'Ad Output Type',
    creativeType: 'Creative Type',
    endcardID: 'Endcard ID',
    creativeAdTypeFormat: 'Format',
    creativeAdTypeSize: 'Size',
    creativeAdTypeTips:
      'You can choose specific ads type for promotion to get the best performance of your ads',
    creativeAdTypeConfigTips:
      'The creatives added above will determine what ads are eligible below.',
    creativeAdTypeClickTips: 'Click here',
    creativeAdTypeGuideTips: ' to learn more about each individual ad.',
    creativeDetailTitle: 'Creative Details',
    creativeFile: 'Creative File',
    creativePixel: 'Pixel',
    creativeDimension: 'Dimension',
    creativeSupportedAdType: 'Supported Ad Type',
    creativeStatusTips:
      'This is used to wether we should utilize the creative for promotion, If you want to stop using the creative, you can switch the status of the creative or just delete it',
    creativeMissingError: 'Missing required creatives for checked ad types',
    missingRequiredCreative: 'Missing Required Creative',
    creativeAdTypeMissingError: 'Missing checked ad types for active creatives',
    creativeCountryMissingError:
      'Missing required creatives for ad types in target locations',
    creativeCountryMissingErrorForRoas:
      'Missing required creatives（{dimenssion}） for ad types in target locations',
    creativeMissingErrorForRoas:
      'Please upload at least one image creative of 512x512 pixels, one image creative of 1200x627 pixels and a video creative.',
    creativeApplyForCountries: 'Apply Locations',
    creativeAllCountries: 'For All Locations',
    creativeSpecificCountries: 'For Specific Locations',
    // area budget
    forAllArea: 'For All Area',
    forSpecialArea: 'For Special Area',
    spendingBudget: 'Spending Budget',
    averageSpendingBudget: 'Average Spending Budget',
    conversion: 'Conversion',
    budgetFor: 'Budget For',
    applyIn: 'Apply In',
    applyInTip: 'Area you added in the same blank will share the budget/cap',
    shared: 'Shared',
    seperated: 'Separated',
    budgetGroupType: 'Budget Group Type',
    budgetGroup: 'Budget Group',
    daily: 'Daily',
    budgetGroupTypeTips1:
      '1.This tag is used to identify the regions in this budget group as shared budget/cap, or each has the same budget/cap.',
    budgetGroupTypeTips2:
      '2.Select "Shared" option means regions in the same budget group share a budget/cap.',
    budgetGroupTypeTips3:
      '3.Select "Separated” option to set the same budget/cap for all regions in the same budget group',
    setDailyCapBy: 'Set Daily Cap By',
    setDailyCapByTips: 'Daily cap will take effect by GMT{timezone} time zone only',
    dailyConversion: 'Daily Conversion',
    openCap: 'Open Cap',
    addBudgetByCountry: 'Add Budget By Location',
    addOthers: 'Add Others',
    addOthersTip: 'Insert area except area that added above',
    setupEffectiveTime: 'Effective time for budget setup',
    budgetEffectiveTime: 'Effective time for offer budget',
    editOfferBudget: 'Edit Offer Budget',
    now: 'Now',
    checkFutureTask: 'Check Future Task',
    deleteFutureTask: 'Delete Future Task',
    saveFutureTask: 'Save Future Task',
    addBudgetConfig: 'No budget can be applied in',
    pleaseCheck: 'Please check',
    addFutureBudgetConfig: 'In future task,No budget can be applied in',
    go: 'Go',
    insertFutureTips:
      'Success！you can click the link (check future task) in edit budget pop-up window, to check the setup which will take effect at specific time',
    budgetDialogConfirmTip:
      'Please check future budget task and set budget for added area:',
    creativeSelectTips: 'Select Creatives to Apply',
    creativeNameUniqueTips: 'Creative name ({item}) must be unique',
    creativeNameExistedTips:
      'Faild,creative name({name}) must be unique, please change creative name and try again.',
    highBidValueWarning: 'Notice: your bids is over {num}. Please check.',
    lowBidValueWarning: 'Bid for {locations} is too low to get the impression',
    highChangeBidWarning:
      'Notice: Your bids are about to change significantly (original bids is {num}), Please check.',
    selectToRun: 'Select To Run',
    selectToStop: 'Select To Stop',
    targetAdType: 'Target AD Type',
    creativeMoreOfferTips:
      'Offer can be promoted in ad type "we suppose you like these apps" to get more users.',
    gpAndCnError: `Sorry, it doesn't allow to promote apps in mainland China which landing page for the ads is Google Play, please remove“China”from your target area and submit again.`,
    bidRateChangeTitle: 'Bid Rate Confirm',
    bidRateChangeTips: 'Sure to bid as below?',
    uniqueSettlementTip: 'Settlement name must be unique',
    settlementNameTip:
      'Please confirm that the settlement event has been configured',
    settlementPriceTip:
      'The offer bid rate needs to be consistent with the Step 1 settlement event bid rate',
    contentPolicyLink: 'Mintegral Ad Content Policies and Guidelines',
    warning10x:
      'Your new bids does rise drastically, please change and try again.',
    sureToChangeStatus: 'Are you sure wish to {status} the offer?',
    changeStatusTips: 'Please enable campaign first.',
    changeStatusTitle: '{status} Offer',
    changeStatusSucDesc:
      'Successfully modified {successCount} offer(total: {total}).',
    changeStatusFailDesc:
      'Unsuccessfully modified {failCount} offer(total: {total}). The failed details is as followed.',
    targetDevPrice: 'Device Price',
    customTargetDevPrice: 'Custom Device Price',
    targetDevPriceShortcut: 'Shortcuts',
    adTypeTips:
      'The Creative Set added above will determine what ads are eligible below. ',
    adTypeConfirmDialog: {
      title: 'Adtype has been changed，please confirm',
      originAdType: 'Original adtype',
      newAdType: 'New adtype',
      originText: ' {isMul} {adTypes}',
      newText: ' {isMul} {adTypes}',
      confirmAndSubmit: 'Confirm and submit'
    },
    adTypeAndGeoCheck: {
      title: 'Adtype Confirm',
      desc:
        'Offer in certain region(s) are unable to be delivered properly due to missing creatives in certain ad type(s). To ensure effective delivery, we recommend adding relevant creative set(s).',
      confirmAndSubmit: 'Confirm and submit',
      confirmTips: 'Confirm submission?',
      errorTips:
        'Please cancel targeting in regions ({regions}) where there are missing creatives before submission.',
      regionWithoutCreative: 'Region(s) without Creative',
      adtypeWithoutCreative: 'Adtype(s) without Creative'
    },
    targetGoalConfirmDialog: {
      title: 'Target Goal Confirm',
      noticeText1:
        'Do you confirm your intention to adjust the settings? This particular adjustment exceeds 50%/2 times, which may result in a significant impact on revenue or exceed the budget. Please click the confirmation button once more to verify the configuration.',
      noticeText2:
        'Do you confirm your settings? This particular setting goal<=5%, which may result in a significant impact on revenue or exceed the budget. Please click the confirmation button once more to verify the configuration.',
      noticeText3:
        'Do you confirm your intention to adjust the settings? This particular adjustment exceeds 50%/2 times & setting goal<=5%, which may result in a significant impact on revenue or exceed the budget. Please click the confirmation button once more to verify the configuration.',
      dimension: 'Dimension',
      oriValue: 'Original Value',
      newValue: 'New Value',
      confirmAndSubmit: 'Confirm and submit',
      lessThan5: 'The ROAS target goal is set below 5%',
      lessThanHalfTimes:
        'the target goal adjustment ratio is less than 0.5 times',
      moreThanDoubleTimes:
        'the target goal adjustment ratio is greater than 2 times.'
    },
    priceProposal: {
      tips: `To enhance the competitiveness of the offer and accelerate data accumulation, the following bid suggestions are provided for your reference.`,
      list: '	{symbol}{min} ~ {symbol}{max} for {geo}',
      accept: 'Accept'
    },
    budgetProposal: {
      tips:
        'To quickly accumulate sufficient learning samples and shorten the learning phase, we sincerely recommend to increase the budget to',
      tips2: `To quickly accumulate sufficient learning samples and shorten the learning phase, we sincerely recommend to increase the budget to {symbol}{num}`,
      list: '{symbol}{num} for {geo}',
      accept: 'Accept'
    },
    audienceDetail: {
      tips: 'Partial user configurations have expired, please be aware.',
      tips2: 'The relevant audience segments are as follows:',
      colTarget: 'Targeting logic',
      colName: 'Audience Package',
      ok: 'ok'
    }
  },
  creative: {
    text: 'Creative',
    creativeName: 'Creative Name',
    groupText: 'Creative Group',
    imageOrVideo: 'Image Or Video',
    playableAds: 'Playable Ads',
    uploadNewCreative: 'Upload New Creatives',
    uploadPlayable: 'Upload Playable Creatives',
    reuploadPlayable: 'Re-Upload Playable Material',
    dcoAds: 'Dco Ads',
    uploadRules: 'Please upload creative',
    selectRules: 'Please select creative',
    adTypeRequired: 'Ad type is required',
    videoTemplateRequired: 'Video template is required',
    endcardTemplateRequired: 'Endcard template is required',
    graphicType: 'Graphic',
    videoType: 'Video',
    removeSameCreativeTip:
      'Please remove creatives with the same name ({name}) ',
    duplicateCreativeTip: `it's forbidden to add creative with same name to existed creatives in offer`,
    creativeNameUniqueTips: `Error! Creative name ({name}) must be unique, please check again.`,
    uploadTime: 'Upload Time',
    applyInOffer: 'Apply in Offer',
    applyInPackage: 'Apply in StoreID',
    applyToCreativeSets: 'Apply to Creative Sets',
    removeOrDeleteCreative: 'Remove or Delete Creative',
    applyInBulk: 'Apply Creatives for Offers in Bulk',
    applyInBulkV2: 'Apply Creatives to Creative Sets in Bulk',
    creativeUploadedTitle: "Creative You've Uploaded",
    creativeSelectedTitle: "Creative You've Selected",
    applyForOffer: 'Apply Creatives for Existing Offers',
    applyForOfferTips:
      'Input or paste offers here, seperated by new line. For example:\noffer name1\noffer name2\noffer name3',
    applyForPackagesTips:
      'Input or paste storeid here, seperated by new line. For example:\n storeid1\n storeid2\n storeid3',
    applyForPackageTips:
      'Please input a storeid here. For example: id123456789, com.abc.123',
    packageValidateText: 'Invalid Package Name or Store ID',
    applyCreativeFor: 'Apply Creatives for',
    applyForOfferNum: 'Maximum number of offers is 50',
    applyForCountry: 'Apply Creatives for Area',
    allAreaInOffer: 'All Area in Offers',
    specificAreaInOffer: 'Specific Area in Offers',
    confirmToAddNewAdType: 'Confirm to Add New AD Type for Offers',
    confirmToAddNewAdTypeTips:
      'Due to the updates of creative, a few new ad type will be added in offers below, if you remove all the offers in table, creatives will be added to the offers which without ad type changes only, please confirm and submit',
    invalidCreativeForAdTypeTips:
      'Due to the lack of required creatives for ad type which should be added in offers below, you could back to last page to add required creatives in offers or continue to submit and ignore issue creative in specific offers',
    adTypeRequirementTips: 'Check creative requirements for different ad type',
    active: 'Active',
    lackOfCreative: 'Lack of Creative',
    lackOfAdType: 'Lack of Checked Supported AD Type',
    unmatchedCountry: 'Lack of Creative in Location',
    offerCountry: 'Offer Target Location',
    playturbo: 'Go to playturbo to create creatives',
    moveToAnotherCreativeSet: 'Move to Another Creative Set',
    moveToAnotherCreative: 'Move Creative',
    moveToAnotherCreativeSetBtn: 'Move Creative (Offer)',
    moveToAnotherCreativeAdvSet: 'Move to Another Adv Creative Set',
    moveToAnotherCreativeAdvSetBtn: 'Move Creative (User)',
    moveCreativeToAnotherCreativeSet: 'Move creatives to another creative set',
    editCreativeSet: 'Edit Creative Set',
    creativeSet: 'Creative Set',
    creativeSetName: 'Creative Set Name',
    adWords: 'Ad Words',
    addToOffer: 'Add to Offer',
    addAppDescriptionAsAdWords: 'add app description as ad words',
    belongToCreativeSet: 'Belong to Creative Set',
    belongToCreativeAdvSet: 'Belong to Adv Creative Set',
    newCreativeSetName: 'New Creative Set Name',
    addNewCreativeSet: 'add New Creative Set',
    creativeSetNameMaxLengthError:
      'Maxim length of Creative set name is ({len})',
    adwordsLengthError:
      'Ad words must be between ({min}) and ({max}) characters in length',
    set: 'Adv Creative Set',
    newSet: 'New Creative Adv Set',
    addToAccount: 'Add to Account',
    doubleCheckCreativeTips: `It's forbidden to add same creatives in an offer, sure to remove invalid creative from offer and continue to submit?`,
    deleteCreativeIds: 'Delete Creative ID',
    deleteCreativeIdsNote: `1. After deleting the creatives, it may cause the associated offer to fail to promote normally in some regions or ad types due to the lack of creatives.<br />
      2. Only a maximum of 500 creative IDs can be deleted at one time.`,
    deleteCreativeIdsTips: `1. Creative ID refers to the unique ID generated after the creative is associated with the offer.<br />
      2. Delete Creative ID means removing the creative from the associated offer.<br />
      3. If you truly want to delete the creative, you need to operate through the [Delete Creative] button.`,
    deleteCreativeIdsPlaceholder: `
Please input creative IDs here, separate by new line or commas (, ) . For example，
1805178251
1805178252
1805178253,1805178254,1805178255
    `,
    deleteCreativeIdsConfirm: 'Sure to continue？',
    deleteCreativeIdsError: 'Invalid creative ids',
    deleteCreativeIdsErrorMax:
      'Only a maximum of 500 creative IDs can be deleted at one time',
    uploadRequirment:
      'https://alidocs.dingtalk.com/i/p/ZR2PmK51081zvpO72AmoVLYwVj2DQXdb?dontjump=true',
    materialType: {
      image: 'Image',
      video: 'Video',
      playable: 'Playable'
    }
  },
  playableCreatives: {
    text: 'Playable Creatives',
    bgm: 'Bgm',
    uploadBy: 'Upload By',
    playableType: 'Playable Type',
    playableGuide: 'Guidance For Playable Ads',
    relateBgm: 'Relate Bgm',
    uuid: 'UUID',
    offerId: 'Offer ID',
    backgroundMusic: 'Background Music ( Accept File Type: mp3 )',
    addExistMp3: 'Add Exist MP3 File',
    applyInBulk: 'Apply Playable Creatives for Offers in Bulk',
    relateToPl: 'Relate To Playable Ads',
    relateToPlayable: 'Relate To Playable',
    relateToApp: 'Relate To App (storeid)',
    deleteTips: `If you delete this creative, the creative would be removed from all the offers.`,
    url: 'URL',
    uploadByUrl: 'URL',
    uploadByZip: 'Upload zip from a local path',
    uniqueTip: 'creative name must be unique',
    existedTip: 'forbidden to add same name to existed creatives in offer',
    nameUrlUnique: 'creative name + creative url must be unique',
    platformErrorTip: `Due to the conflict of platform, creative can't be applied in offers below:`,
    platformIllegalTip: `it's forbidden to add creative with conflict of platform`,
    generateGif: 'Generate Gif',
    toOffer:
      'Agree to apply the GIF images which were created by dynamic endcard in offers to cover more traffics',
    toLibrary:
      'I would like to build new GIF creatives based on the source endcard to increase the diversity of my ad creatives.',
    efToGifMore: 'Learn more.',
    offerToOffer:
      'I would like to build creatives as GIFs based on the source endcard and use the GIFs in this offer to cover more traffic.',
    zipTips: 'Please select language first'
  },
  dcoCreatives: {
    dcoId: 'Dco ID',
    applyInBulk: 'Apply Dco Creatives for Offers in Bulk',
    dcoText: 'DCO (Dynamic Creative Optimization): ',
    uploadTips:
      'Usually used to describe dynamic creatives. In order to achieve the goal of optimizing creatives and increasing conversions, Mintegral will combine different creative elements for ad display based on machine learning. If you have related needs, please contact your corresponding Account Manager.'
  },
  basicCreatives: {
    textAssets: 'Text Assets',
    inputText: 'Input Text',
    textAssetsTip:
      'Entered text will be translated to different language based on offer target region.',
    addAndUpdateButton: 'Add and update text asset',
    tableEmptyText:
      'Once offer turn to active,text assets would be grabbed from app store automatically, and show the details here.',
    autoGrab: 'Auto Grab',
    autoGrabTips:
      'Once offer turn to active, the value of auto grapped would display here ASAP.',
    inputTranslation: 'Input translation text',
    translate: 'Translate',
    copyTranslationTo: 'Copy Translation To',
    selectBasicCreativeRequired: 'Select basic creatives to copy',
    updateToOffer: 'Update To Offer',
    addNew: 'Add New',
    uploadRequirment:
      'https://cdn-adn-https.rayjump.com/cdn-adn/v2/portal/21/06/23/17/56/60d305676af22.pdf'
  },
  abTest: {
    text: 'A/B Test',
    start: 'Start A/B Test',
    startTip:
      'Turn on the button to add creatives for abtest, once the button turn to off, all the creative test would be paused and removed from testing area.',
    trafficTip: '% chance that the A/B test will find a winning result.',
    checkResult: 'Check Result',
    traffic: 'A/B Test Ratio',
    detail: 'A/B Test Detail',
    chooseCreative: 'Choose Creative For A/B Test',
    chooseTextAssets: 'Choose Text Assets For A/B Test',
    expandAll: 'Expand All',
    narrowAll: 'Narrow All',
    addCreatives: 'Add Creatives',
    addCreativesStep: '2. Add Creatives',
    removeCreative: 'Remove From Test Group',
    createGroup: '1. Create Group',
    deleteGroup: 'Delete Group',
    groupName: 'Group Name',
    groupID: 'Group ID',
    groupStatus: 'Group Status',
    testArea: 'A/B Test Area',
    relateCreatives: 'Related Creatives',
    relateOffer: 'Related Offer',
    creativeID: 'Creative ID',
    creativeName: 'Creative Name',
    groupLengthTip:
      'You could only add a minimum of 2 creatives and a maximum of 5 creatives in {groupNames} group',
    oneGroupLengthTip:
      'You could only add a minimum of 2 creatives and a maximum of 5 creatives in a group',
    addGroupTitle: 'Add Creatives For A/B Test',
    chooseCreativesTitle: 'Add Creatives To A/B Test',
    groupIDLabel: 'Group ID:',
    groupNameLabel: 'Group Name:',
    creativeTypeLabel: 'Creative Type:',
    pixelLabel: 'Pixel / Dimension:',
    areaLabel: 'Test Area:',
    step1Title: 'Step1: Create a test group',
    step2Title: 'Step2: Choose creatives and add to A/B test',
    emptyDataTip: 'There is no active creative can be added to test group.',
    chooseArea: 'Choose Area For Test',
    applyIn: 'Apply In Country / Region',
    relatedGroup: 'Related A/B Test Group',
    addTextAssetGroupTitle: 'Add Creatives For A/B Test',
    chooseTextAssetTitle: 'Add Creatives To A/B Test',
    chooseTextAssetTip: `Only Text asset you've added in offer can be used for A/B test`,
    addNewLine: 'Add New Line',
    textAssetGroupUnique: 'Every group item must be unique, please check.',
    changeTipTitle: 'A/B Test Change Tips',
    removeCreativesTitle: 'Change: Delete Creatives In Group',
    removeCreativesTipByGeo: `Due to the changes of area, creative below would be removed from abtest group, sure to continue?`,
    removeCreativesTipBySource: `Due to the removal of creatives, creative below would be remove from abtest group at the same time, sure to continue?`,
    removeGroupTitle: 'Change: Delete Group',
    removeGroupTipByGeo: `Due to the changes of area, creative below would be removed from abtest group, besides, some A/B test group will be removed at the same time because the number of test creatives is less than the minimum of 2, sure to continue?`,
    removeGroupTipBySource: `Due to the removal of creatives, creative below would be removed from abtest group also, besides, some A/B test group will be removed at the same time because the number of test creatives is less than the minimum of 2, sure to continue?`,
    confirmAndSubmit: 'Confirm And Submit',
    reportResultTitle: 'A/B Test Result',
    abTestCreativesRequired: 'Please choose creatives for A/B test',
    dynamicEndcard: 'Dynamic Endcard',
    confirmAndCheck: 'Confirm and Save',
    deleteUnsupportedParameterSave: 'Delete Unsupported parameter & Save',
    notSupportByMintegral: ' is not supported by Mintegral'
  },
  tracking: {
    trackingMethod: 'Tracking Method',
    adjustEvent: 'Adjust Event',
    eventName: 'Event Name',
    clickThroughAttribution: 'Click-through Attribution',
    viewThroughAttribution: 'View-through Attribution',
    noteTips1:
      'When you edit and save a new tracking link, it will be temporarily saved to the new test click url. After successfully testing the new link, it will automatically update to the actual click tracking link used.',
    noteTips2Snippet1: 'Any problem? Please contact "',
    noteTips2Snippet2: '" for help.',
    noteTips3:
      'To send user data to a third-party tracking service, you will need to provide us with a base URL for the tracking service and include the supported parameters for the data you want to receive.',
    noteTips4Snippet1:
      'For details about the macro in tracking url and integration, please check ',
    noteTips4Snippet2: 'this document',
    isAsync: 'Is Async',
    isAsyncTips:
      'Check this if the Click URL is only for click data feedback. Meanwhile, the Mintegral platform will send click data on the server-side, and the users will be redirected to application download page or landing page, that is the preview link of campaign.',
    isAsyncLabelTips:
      'Check this if the Click URL is only for click data feedback. Meanwhile, the Mintegral platform will send click data on the server-side, and the users will be redirected to application download page or landing page, that is the preview link of campaign.',
    supportServerSideClick: 'Support server-side click',
    supportServerSideClickTips:
      'This indicates whether this offer is allowed to receive click data from Mintegral server.',
    packageTips:
      'Please contact your AM to apply for advanced permission to use the macro of package name',
    testTracking: 'Tracking URL Testing',
    testOfferTips:
      'Track testing can help you to verify that the click url has implemented correctly.',
    testOfferStep1: 'Step 1. Set up the conversion tracking URL',
    testOfferStep2: 'Step 2. Use the test link to install your app',
    testOfferStep3: 'Step 3. Perform Actions In Your App',
    testOfferStep4: 'Step 4. Run Verification Tests',
    testOfferStep1Text1Snippet1:
      'When transformation target is completed, you need to call postback url. Please',
    testOfferStep1Text1Snippet2: 'click here',
    testOfferStep1Text1Snippet3: 'to find how to set up postback URL.',
    testOfferStep3Text1:
      'Open your app, it should create a session which you will then verify in the next step.',
    testOfferStep3Text2:
      'Perform the actions in your app that you defined for each conversion (e.g. registration)',
    testScanQrCodeTips: 'Please visit link or scan QR code to test',
    testResult: 'Test Result:',
    testResultForTestClickUrl: 'Test Result of Test Click URL:',
    getTestLink: 'Get Test Link',
    getTestResult: 'Get Test Result',
    yourTestLink: 'Your Test Link',
    deviceIdType: 'Device ID Type',
    deviceId: 'Device ID',
    errorIDFATips: 'Invalid IDFA',
    errorGAIDTips: 'Invalid GAID',
    errorOAIDTips: 'Invalid OAID',
    errorIMEITips: 'Invalid IMEI',
    testClickUrl: 'Test Click URL',
    testClickUrlTips: 'It will be updated to Click URL after the test passes.',
    clickAndImpressionUrlRequire:
      'Click url and Impression url can not be both empty',
    serverClickUrl: 'Server Click URL',
    pass: 'Success',
    notPass: 'Failed',
    testing: 'Testing',
    pending: 'Pending',
    saveTestSuccessTips: 'The tracking link has been tested before',
    saveTestFailedTips:
      'Please follow the steps below to test the tracking link',
    checkSettingInTesting: 'Check Setting In Testing',
    paramName: 'Parameter Name',
    macro: 'Macro / Value',
    closeVisualTool: 'Close Visual Tool',
    editParamInVisualTool: 'Edit Parameters in Visual Tool',
    editParamOfUrl: 'Edit Parameters of Tracking URL',
    urlBasicPath: 'URL Basic Path: ',
    unknownParameter: 'Unknown Parameter',
    applyParams: 'Apply Parameters to {url}',
    needTestClickUrl: 'Need To Test Click URL',
    isNeedTestClickUrl:
      'Yes, using this url while the test result turn to pass',
    noNeedTestClickUrl: 'No, saving the url directly',
    requiredUuidAndClickid:
      '{uuid} and {click_id} must be present in the link.',
    linkChangeTips:
      'Tracking Method corresponding to this link is {method}. Sure to update the value of "Tracking Method"?',
    campaignLinkChangedWarning:
      'Campaign link changed, some offers which related to the campaign would be paused and needs to be retested, sure to continue?'
  },
  report: {
    checkReport: 'Check Report',
    advancedControls: 'Advanced Controls',
    breakdowns: 'Breakdowns',
    metrics: 'Metrics',
    metricsTips: {
      sectionTips1: 'Tips: for Ad&IAP metrics, ',
      sectionTips2: 'event mapping',
      sectionTips3: ' is needed.'
    },
    dateStartError: 'Start date can not be earlier than {num} days',
    dateGapError:
      'Gap between start date and end date is limited within 0 months | Gap between start date and end date is limited within 1 month | Gap between start date and end date is limited within {num} months',
    dateReport: 'Summary Report',
    campaignReport: 'Campaign Performance',
    offerReport: 'Offer Performance',
    exporting: 'Exporting, please wait a moment',
    csvBidManager: 'CSV Bid Manager {note}',
    bidForApp: 'Bid for App',
    bidForRegion: 'Bid for Region',
    promoteRegion: 'Promote Country / Region',
    settleBySkanPostback: 'Settle by  SKAdNetwork Postback',
    conversionTips:
      "The callback information is limited due to Apple's new policy. The following dimensions of conversions sent by skadnetwork are available: offer-geo, offer, campaign and advertiser.",
    showDateBy: 'Show Date by',
    byReceivedTime: 'by received time',
    byInstallTime: 'by install time',
    customize: 'Customize',
    dataByDay: 'Show data by calendar day',
    dataByDayTips1:
      '1. checked, allow to view retention, roi and purchase(D0) data by calendar day（only utc 0 and utc+8 are allowed）',
    dataByDayTips2:
      '2.unchecked, retention, roi and purchase(D0) is calculated based on events happened after a specific time interval after user activation',
    dataByDayTips3:
      '3. When the query time zone is utc 0 or utc +8, and selected retention, roi or purchase(D0) metrics, and the breakdown is not "hour", this item can be choosed',
    blockTrafficByCSV: 'Block traffic by CSV',
    blockTrafficByCSVAppId: 'Block App Id by CSV',
    blockTrafficByCSVStoreId: 'Block Store Id by CSV',
    targetLogicTips: `"exclude" is used for blocking apps
    "include" is used for removing apps from blocking list`,
    storeIdTips: `1. Target Store ID, eg. com.abc.bqw, id1234567890
    2.Please use commas to separate multiple apps`,
    appIdTips: `1. Target App ID, eg. mtg1234567890,mtg1234567891,...
    2. Please use commas to separate multiple apps`,
    appTemplate: `\uFEFFoffer_name,target_logic,app_id
    Used to enter the offer name of the offer that needs to block traffic.,You can select include or exclude.,Mtgid of traffic to be shielded.`,
    storeTemplate: `\uFEFFoffer_name,target_logic,store_id
    Used to enter the offer name of the offer that needs to block traffic.,You can select include or exclude.,Storeid of traffic to be shielded.`
  },
  account: {
    user: 'User',
    userName: 'Account Name',
    companyName: 'Registered Business Name (EN)',
    registeredAddress: 'Registered Business Address',
    registeredCountry: 'Registered Business Location',
    headquater: 'Headquarter Location',
    headquaterAddress: 'Headquarter Address',
    contactNumber: 'Skype/Contact Number',
    companyProperty: 'Company Property',
    mainAddress: 'Main Address',
    invoiceInformation: 'Invoice Information',
    invoiceCompany: 'Company or Personal Name',
    invoiceAddress: 'Address Details',
    companyAddress: 'Address Details',
    companyWebsite: 'Company Website',
    productLink: 'Product Link',
    settlementCurrency: 'Settlement Currency',
    settlementCurrencyTips: `Once confirmed, it can't be changed and all the offers in this account should use currency you specify for bidding and budget setting.`,
    qualificationDocuments: 'Upload Qualification Documents',
    qualificationDocumentsTips:
      'If you are an enterprise user, please upload your Business license, otherwise, if your are a personal user, please upload your personal documents (passport or identity card)',
    password: '{item} Password',
    confirmPassword: 'The confirm password and new password do not match',
    currentPwd: 'Current Password',
    newPwd: 'New Password',
    confirmPwd: 'Confirm Password',
    downloadApiDocument: 'Download Reporting API Document',
    email: 'Email',
    emailAddress: 'Email Address',
    ccemail: 'CC Email',
    ccemailAddress: 'CC Email Address',
    ccemailValid: 'not a valid email',
    ccemailTips:
      'Allow to add multiple E-mail addresses to receive news form Mintegral.',
    apiKey: 'Api Key',
    accessDetail: 'Access Detail',
    agreeTermConditionPerfix: 'I agree to ',
    agreeAnd: 'and',
    agreeTerm: 'Advertiser Terms of Service',
    agreePrivacyPolicy: 'Privacy and Data Protection Addendum',
    agreeTermConditionSuffix: '',
    admin: '1.admin',
    subuser: '1.Subuser: without access to "account" menu',
    checkTraffic: '2.View traffic details, including package name and App name',
    confidentialityTerm: 'CONFIDENTIALITY AGREEMENT',
    switchTips: `If you turn on the access button, it means you've agree to `,
    balance: 'Advertising Balance: ',
    insufficientBalance:
      'The balance on your account is less than $100. Please recharge it in time.',
    currentBalance: 'Current Balance',
    balanceTips:
      'You will get a notification when your balance reaches $100, please recharge your account as soon as possible to keep on launching offers.',
    billingHistory: 'Billing History',
    transaction: 'Transaction',
    amountBilled: 'Amount Billed',
    dateBilled: 'Date Billed',
    paymentMethod: 'Payment Method',
    paymentStatus: 'Payment Status',
    paymentPendingTips:
      'We’ll confirm your payment within 72 hours, please be patient, thank you.',
    paymentProcessingTips: 'Transaction processing',
    pay: 'Pay Now',
    billingCurrency: 'Billing Currency',
    depositAmount: 'Deposit Amount',
    minimumAmount: 'Minimum: {num}',
    amountTips: 'Only number and the minimum is {num}',
    predictableAmount: 'Predictable Amount',
    depositAmountTips:
      'Any government taxes, including but not limited to WHT, GAT, VAT or other tax charges arising from this cooperation shall be borne by you.',
    depositAmountTipsPrefix:
      'There may be some fees happened in the recharge process (For instance, withholding tax',
    depositAmountTipsSuffix:
      '), the actual amount is subject to our final confirmation.',
    confirmAmount: 'Confirm and Choose Payment Method',
    choosePaymentMethod: 'Choose Payment Method',
    chooseBank: 'Choose Bank',
    alipay: 'Alipay',
    wechat: 'WeChat',
    scanQrCodeTipsPrefix: 'Please use {item} to scan ',
    scanQrCodeTips: 'the following QR code to complete the payment',
    payOnNewPageTipsPrefix: 'Please complete the payment ',
    payOnNewPageTips: 'in the automatically opened new page',
    scanTimeRemaining: 'Time Remaining: ',
    cancelPayment: 'Cancel Payment',
    completePayment: 'Complete Payment',
    scanTimeEndTips: 'Order has timed out, please try again!',
    payFailedTips: 'Order payment failed, please try again!',
    billingName: 'Billing Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    billingAddress: 'Billing Address',
    city: 'City',
    state: 'State',
    country: 'Location',
    taxRate: 'Tax Rate',
    taxRateTips:
      'Some locations will collect withholding tax, table below is only for your reference:',
    confirmOrder: 'Confirm Order',
    orderSuccessTipsPrefix: 'We’ll confirm your payment within 72 hours, ',
    orderSuccessTipsSuffix: 'please be patient, thank you.',
    errorCardTips: 'Unsupported card type.',
    targetAppUpdateAllTips:
      'If you checked this field, all the "Target App" settings of offers which related to this account would be updated to the new value, otherwise the setting will take effect on new offers only.',
    targetAppForPlatform: 'For {platform} Offer',
    dearClient: 'Dear Client,',
    welcomeToWechat:
      'Welcome to the online deposit service system of Mintegral Advertiser Self-service platform.',
    completeWeChatPay:
      'To check the progress, please visit the billing management page (',
    checkTheBill:
      '). The deposit will usually be credited to your account within 3 working days. ',
    haveNotPaid: 'Haven’t paid yet? Please click',
    payHere: ' here ',
    payAgain: 'to pay again.',
    sendEmail:
      'If there are any problems during the deposit process, please contact your account manager or send an email to ',
    thanksForCooperation: 'Thanks for your cooperation.',
    applyingPay: 'Charge service is loading, please stay in current page.',
    mintegralTeam: 'Mintegral Team',
    completeInfo: 'Please confirm and fulfil your account information first.',
    invoiceStatus: 'Invoice Status',
    applyInvoice: 'Apply Invoice',
    downloadInvoice: 'Download Invoice',
    invoiceDialog: 'Invoice Information',
    invoiceDialogTips: 'Please confirm your invoice information',
    invoiceDialogAddress: 'Address',
    invoiceDialogAddressPlaceholder: 'Company or personal address',
    invoiceDialogCompany: 'Invoice Title',
    invoiceDialogCompanyPlaceholder: 'Company or personal name',
    condition: 'Condition',
    viewTraffic: 'View Traffic Details',
    viewTrafficTips:
      'View traffic details, including package name and App name',
    viewTrafficConfirm:
      "If you turn on the button, it means you've agree to {link}",
    viewTrafficConfirmItem: 'CONFIDENTIALITY AGREEMENT',
    viewTrafficTipsLink:
      'https://adv.mintegral.com/files/confidentiality-agreement.pdf',
    paymentTips: `Tips: If you cannot add funds through the following methods, please <a target="_blank" href="//cdn-adn-https.rayjump.com/cdn-adn/doc/mintegral-adv/prepayment-guidelines.pdf">click here</a> to get prepayment guidelines.`,
    subscribedEmail: {
      text: 'Subscribed Emails',
      tips: {
        realtime_delivery_email:
          'Budget、credit、balance alarm emails during the delivery period',
        system_notification_email:
          'System notification emails such as creative rejections, tracking link test results, etc.',
        delivery_daily_report: 'Daily report for delivery performance',
        billing_email: 'Bill-related emails'
      },
      validMsg: 'At least one email address needs to subscribe "billing email"'
    }
  },
  login: {
    text: 'Login',
    aboutUs: 'About Us',
    usernamePlaceholder: 'Username or Email',
    passwordPlaceholder: 'Password',
    codePlaceholder: 'Verification Code',
    forgetPassword: 'Forgot your username or password?',
    forgetPassword2: 'Forgot password?',
    resetPassword: 'Reset Password',
    noAccountTips: `Don't have an account?`,
    signupTips: 'Sign up here',
    signup: 'Sign Up',
    browserTips:
      'Please use Google Chrome for the better experience of our site',
    loginHelp: 'Login Help',
    loginHelpTips:
      'If you have forgotten your username or password, please enter the email address you registered with and we will send your user name and the new password.',
    loginEmailPlaceholder: 'E-mail Address',
    signContactTips1: 'If there are any problems, please send an email to ',
    signContactTips2: ', thanks for your cooperation.',
    knowMintegral: 'How did you hear about Mintegral',
    fallback1: 'Captcha loading failed? Click',
    fallback2: ' here ',
    fallback3: 'to try again.',
    completeAccount: 'Complete Account',
    completeInfoTips:
      'Please complete your account information as required on the form. If you are a subuser, please contact your main user and add your account in the “user management“',
    loginAgainTips: 'Success, please login again'
  },
  guide: {
    welcome: 'Welcome!',
    welcomeText:
      'Our platform aims provide you with the full control of your unique acquistion strategy. We leverage a combination of our proprietaryad-serving technology, accurate tageting abundant data and creative ad format to help you deliver valuable results to the right users globally.',
    start: 'Get Started Now',
    stepOne: 'Step 1',
    stepTwo: 'Step 2',
    createAds: 'Create ads to reach your audience',
    createAdsDetail:
      'Create ads to use our powerful targeting tools to accurately target the people you want to reach informed by their demographics, interests and behaviors. Besides, you can upload the creatives as an image or a video to show in your output ads. We are flexible enough to support many different dimensions and pixels to meet your requirements for ads quality.',
    manageCampaign: 'Manage campaign with well-rounded measurements',
    manageCampaignDetail:
      'View and measure metrics in real-time. Our ROI-based analytics environment shows for actions your consumers take and optimizes against them to find more.'
  },
  addLocationDialog: {
    title: 'Add Location in Bulk',
    editDuplicateTip: `There're a few cities with same name, please confirm which city you'd
    like to add`,
    city: 'City',
    cities: 'Cities',
    region: 'State/Region',
    country: 'Country/Region',
    countries: 'Countries/Regions',
    addToOffer: 'Add to offer',
    typeLocationTip: `Type or paste your locations above.You can put each location on a new
    line or separate them using commas.`,
    locationType: 'location type:',
    invalidLocations: 'Invalid Locations:',
    addLocations: 'Add {locationCount} locations',
    cancel: 'Cancel',
    success: 'success',
    back: 'Back',
    locationErrorTips: `Location doesn't exist.`,
    locationInBulkErrorTips:
      'Only allow to set bids for offer target geo which without specific bids.'
  },
  budgetDialog: {
    editTitle: 'Edit Offer Budget',
    confirmTitle: 'Confirm Future Budget Setup'
  },
  videoTemplate: {
    typeEndcard: 'Endcard Template: ',
    typeVideo: 'Video Template:',
    stardardTemplate: 'Standard',
    storekitTemplate: 'Storekit',
    fullscreenTemplate: 'Fullscreen',
    fullscreenTips: 'You can click anywhere in demo to jump to download page.'
  },
  fileUpload: {
    readFileError: 'Read file error',
    typeLimitError: '{type} Limit error: {limit}',
    fileNameError: 'Filename error: {name}',
    sizeError: 'Size error: {size}',
    dimensionError: 'Dimension error: {error}',
    fileTypeError: 'invalid file format, {type} is suggested.',
    uploadProgress: 'Upload progress:'
  },
  optimizeTraffic: {
    title: 'Optimize Traffic',
    offerName: 'Offer Name :',
    uuid: 'UUID :',
    mtgid: 'Mtg ID :',
    action: 'Action',
    tips: `It's forbidden to block the only traffic in offer App white list`
  },
  url: {
    userManual: 'https://adv.mintegral.com/doc/en/userManual/overview.html',
    createCampaign: 'https://adv.mintegral.com/doc/en/createCampaign/generalUATips.html',
    creatives: 'https://adv.mintegral.com/doc/en/creatives/guide.html',
    integration: 'https://adv.mintegral.com/doc/en/integration/tracking/integrationType.html',
    analyzeOptimize: 'https://adv.mintegral.com/doc/en/analyzeOptimize/subChannelPriceGuide.html',
    guide: 'https://adv.mintegral.com/doc/en/guide/introduction/quickStart.html',
    policy: 'https://adv.mintegral.com/doc/en/policy.html',
    faqs: 'https://www.mintegral.com/en/help-center',
    contentPolicy:
      'https://cdn-adn-https.rayjump.com/cdn-adn/v2/portal/21/11/08/19/45/61890decb5e5d.pdf',
    advertiserTermsOfService: 'https://cdn-adn.rayjump.com/cdn-adn/doc/mintegral-adv/advertiser-terms-of-service-en.pdf'
  },
  userAcquisition: {
    xmp: 'XMP'
  },
  helpCenter: {
    text: 'Help Center',
    userManual: 'User Manual',
    createCampaign: 'Create Campaign',
    creatives: 'Creatives',
    integration: 'Tracking',
    analyzeOptimize: 'Analyze & Optimize',
    guide: 'API',
    policy: 'Policies & Specification',
    faqs: 'FAQ'
  },
  landingPage: {
    title: 'Landing Page',
    generate: 'Generate {item}',
    developerName: 'Developer Name',
    privacyUrl: 'Privacy Url',
    sensitivePermission: 'Sensitive Permission',
    ctaButton: 'CTA button',
    apkUrl: '.apk download url',
    appVersion: 'App Version',
    updateTime: 'Update Time',
    templateId: 'Template id',
    mainImage: 'Main Image',
    validate: {
      invalidUrl: 'Invalid url',
      apkUrl: 'It’s forbidden to add .apk download url as landing page url',
      success: 'success',
      status: 'status',
      downloadUrl:
        'Invalid download url, please add a url which is able to catch .apk file directly.'
    }
  },
  csv: {
    tipstable: {
      column: 'Column',
      description: 'Description',
      offerName: 'Offer Name',
      offerNameText: `Target Offer, eg. offerABC123`,
      geo: 'Geo',
      geoText: 'Target Country/Region, eg. US/CN',
      app: 'APP',
      appText: 'Target App ID, eg. mtg1234567890',
      bidWay: 'Bid way',
      bidWayText:
        'Bidding Method, When input "default" for Bid Rate, final bid will always be Default Bid \n 1. fixed: Final Bid=Bid Rate Input \n 2. ratio: Final Bid=Bid Rate Input*Default Bid',
      bidRate: 'Bid Rate',
      bidRateAppText:
        '1. Bidding Value, eg. 2.25/50%/default\n2. Cancel setting：write "default" (no quotation marks)',
      bidRateRegionText:
        '1. Bidding Value, eg. 2.25/default\n2. Cancel setting：write "default" (no quotation marks)',
      default: 'Default',
      defaultText: 'Default Bid. Do Not Edit',
      currency: 'Currency',
      currencyText:
        'Currency，must be consistent with account currency. eg. USD',
      differentPriceGeo: 'The bid is about to change significantly',
      lowPriceeo: 'The bid is too low to get the impression',
      highPriceGeo:
        'The bid is a little bit high, whitch may cause the risk of over cap'
    }
  },
  event: {
    text: 'Event',
    manageEvent: 'Manage Event',
    eventName: 'Event Name',
    packageName: 'Package Name',
    mintegralEvent: 'Mintegral Event',
    eventDef: 'Event Definition',
    emptyDoc:
      'Please check <a href="{docUrl}"  target="__blank">{doc}</a> and send events to Mintegral first.',
    doc: 'postback doc',
    docUrl:
      'https://adv.mintegral.com/doc/en/integration/quickStart/postback.html',
    eventMetrics: 'Event Metrics',
    mintegralEventWarning:
      'The mintegral event is important and it is not recommended to change once set it.',
    mtgEventChangeWarning:
      'Change of this mintegral event may severely influence the performance of your campaign, please be careful.',
    mtgEventDeleteWarning:
      'Delete of this mintegral event may severely influence the performance of your campaign, please be careful.',
    tips: {
      '1': `Day 1 Retention rate = Day 1 retention events/conversion（based on
      de-duplicated device id)`,
      '2': `Purchase rate = Purchase events/ conversions（based on de-duplicated device id)`,
      '3': `Registration rate = Registration events/ conversions（based on de-duplicated device id)`,
      '4': `Key Action conversion rate = Key Action events/ conversions（based on de-duplicated device id)`
    },
    eventExistError: 'Event name is existed!'
  },
  ska: {
    support: 'Support SKAdNetwork',
    supportTips: `If the value is set to "Yes" and saved, the value can't be changed.`,
    enableReporting: 'Enable SKAdNetwork Reporting',
    enableReportingTips:
      'This indicates whether this offer will be used for SKAdNetwork CampaignId mapping. If you set this value to Yes and submit, the setting cannot be changed.',
    settle: 'Settle by SKAdNetwork Postback',
    settleTips: `1.This indicates whether this offer will be settled by SKAdNetwork postback. Once the offer is saved, the setting cannot be changed.\n
    2. Once you choosed to settled by SKAdNetwork postback, the offer will be targeted to eligible SKAdNetwork uses`,
    target: 'Target SKAdNetwork Traffic',
    targetTips:
      'This indicates whether this offer will be targeted to only eligible SKAdNetwork users.'
  },
  deleteDialog: {
    note:
      'Note：Removal of creatives may cause lack of necessary creatives in related offers, resulting in pause of some geos and ad types.',
    deleteType: 'Delete type:',
    fromAccount: 'Delete creative from account',
    fromOffer: 'Remove creative from specific offer',
    fromPackage: 'Remove creative from package name（Store ID）',
    offerTips: 'Remove creative from offer below:',
    packageTips: 'Remove creative from specific Package Name(Store ID):'
  },
  checkGifDialog: {
    baseOn: 'Based on dynamic endcard',
    checkGifFile: 'Check Gif File',
    selectGif: 'Select gif file you prefer to add to account',
    landscapeMode: 'Landscape Mode',
    portraitMode: 'Portrait Mode',
    basedOnEC: 'Based on EC',
    saveBoth: 'Save both gif and endcard',
    quitGif: 'Quit gif, keep endcard only'
  },
  ectoGif: {
    doc:
      'https://cdn-adn-https.rayjump.com/cdn-adn/v2/portal/21/06/16/16/23/60c9b518b7b7c.pdf'
  },
  subUser: {
    viewCampaign: {
      text: 'View Campaigns',
      restricted: 'Restricted',
      unrestricted: 'Unrestricted'
    },
    role: {
      text: 'Role',
      admin: 'Administrator',
      subUser: 'Sub User'
    },
    permitted: {
      text: 'Add & Edit Campaigns',
      permitted: 'Permitted',
      notPermitted: 'Not Permitted'
    },
    password: 'Password',
    credit: {
      text: 'Credit Details',
      assignableAmount: 'Assignable Amount',
      unlimited: 'Unlimited'
    },
    tips: {
      viewCampaign:
        'Restricts users from viewing data and settings from all campaigns. Administrator can define the campaigns the user can access.',
      resetUserName: 'Success, the user name has been reset to {userName}'
    },
    basicInfo: { text: 'Basic Information' },
    permission: { text: 'Permission' },
    assignCredit: { text: 'Assign Credit' },
    validate: {
      userNameLen:
        'User name must be at least 3 characters long, less than 32 characters.',
      userName:
        'A valid user name can contain only letters, numbers, and underscores.',
      password:
        '8 to 20 characters long, combination of digit, capital and lowercase letter (eg. Psw1234567).',
      credit: 'Must be a positive number.',
      creditCap: "Credit can't be bigger than assignable amount.",
      campaignConflict:
        'Sorry, it’s forbidden to add multiple users with access to maintain the same campaign. Conflict data:',
      campaignConflict1: 'Campaign(',
      campaignConflict2: '),related user '
    }
  },
  audienceManage: {
    text: 'Audience',
    type: {
      // 1:自有,2:离线,3:运算,4:rta,5:特殊,6:已安装,7:上传,8:Api
      //     AudienceTypeUnknown = 0;
      // SELF = 1;
      // Offline = 2;
      // Calculate = 3;
      // Rta = 4;
      // Special = 5;
      // Installed = 6;
      // Upload = 7;
      // Api = 8;
      2: 'Offline',
      3: 'Calculate',
      7: 'Upload'
    },
    calculateAudience: 'Calculated Audience',
    uploadAudienceFile: 'Upload Audience File',
    uploadAudienceFileViaUrl: 'Upload Audience File Via Url',
    table: {
      name: 'Audience Name',
      deviceType: 'Device Type',
      audienceType: 'Audience Type',
      matchAudience: 'Match Audience',
      calculating: 'Calculating'
    },
    rule: {
      filepath: 'Invalid file path'
    },
    offerAudienceTabInclude: 'Include',
    offerAudienceTabExclude: 'Exclude',
    offerAudienceTabPlaceholder: 'Please input audience name',
    calculateDialog: {
      name: 'Audience Name',
      platform: 'Platform',
      audience: 'Audience',
      logicInTheSameGroup: 'Logic in the same group',
      logicBetweenGroup: 'Logic between group',
      intersection: 'Intersection ∩',
      union: 'Union ∪',
      subtraction: 'Subtraction -'
    },
    uploadDialog: {
      name: 'Audience Name',
      uploadFile: 'Upload File',
      deviceType: 'Device Type',
      sizeLimit:
        'Supports only .gz, .txt, .csv file types and they must be smaller than 500MB.',
      needPlatform: 'Please choose platform first.'
    },
    audienceTab: {
      includeDetails: 'Include Details',
      excludeDetails: 'exclude Details',
      packageName: 'Audience Package',
      expirationDate: 'Expiration date',
      selected: 'Selected Audience',
      status: 'Status',
      clear: 'Clear',
      refresh: 'Refresh'
    },
    batchInput: 'operate in bulk',
    batchInputPlaceholder:
      'Type or paste your audience below.You can put each location on a new line or separate them using commas.'
  },
  operationLog: {
    objective: 'Objective',
    datetime: 'Operation Time',
    operatorName: 'Operator',
    ip: 'IP',
    objectiveName: 'Objective Name',
    operation: 'Operation',
    feature: 'Feature',
    originalValue: 'Original Value',
    newValue: 'New Value',
    operationTimeTips:
      'Only allow to search the operation log of the last 180 days',
    viewDetail: 'View Detail',
    showAll: 'Show All',
    status: 'Status',
    bidRate: 'Bid Rate',
    dailyBudget: 'Daily Budget'
  },
  deepTargetSuggest: {
    nodata:
      'No target setup advise available,  we recommend setting an optimization goal first and then adjusting it based on the recommended target ratio after you have accumulated at least 60 events for 3 consecutive days.',
    suggest6:
      'The predicted performance increase for this target setup is lower than 5% thus we do not recommend moving forward with it. If it is necessary, please discuss with your Mintegral contact.',
    suggest4:
      'Significant decrease in estimated installs, please ensure your setup is correct.',
    suggest3:
      'It is predicted that this target setup will result in a {rate}% decrease in installs.',
    suggest1:
      'The recommended key action target {type} for this offer is {lower}-{upper}%.',
    ratio: 'ratio',
    price: 'price',
    suggest3static:
      "It is predicted that this target setup won't change in installs."
  },
  batchOperationBar: {
    selectedCountDesc: ' items selected'
  },
  dialog: {
    pt: {
      text1: `Dear advertisers,

      Playturbo is a platform for creating playable ads effortlessly without any coding skills. We are excited to introduce our new pricing model, which charges based on the actual ad consumption effectiveness of conversions. You will only need to pay a minimum of 3% of the ad consumption, aligning costs with actual results. This reduces the production costs of materials with poor conversion rates and increases return on investment.

      Furthermore, as a token of appreciation to our advertisers, `,
      text2: ` Click below now to learn more details, sign up, or contact your account manager to ensure you become one of the lucky first 50 users.
      {term}

      We look forward to providing you with a flexible and efficient solution for your ad campaigns.

      Best regards,
      Mindworks - Mintegral's Creative Lab`,
      term: `<span style="font-size: 12px;">*Terms and conditions apply, subject to Mindworks' interpretation.</span>`,
      link:
        'the first 50 clients to activate their accounts and complete their initial deposits will receive a special new user gift package 🎁, including two free material production opportunities provided by the Mindworks team.',
      nomorereminders: 'Not to be reminded again',
      btn: 'Learn More',
      img: 'BG_EN@2x.png'
    }
  },
  newYear: {
    title: 'Message',
    content: `
      Dear Partner,<br /><br />

      The Mintegral team will be out-of-office from Jan 19th to Jan 27th for the Lunar New Year Holiday, and business will resume on Jan 28th, 2023.<br /><br />

      During the holidays, offline business transfers may be delayed, while adding funds online via Paypal and credit card can be normally processed. Please replenish your account funds in advance if necessary. Other online functions will not be affected and can be used normally during this period.<br /><br />

      Thank you for your support, and we apologize for any inconvenience. If you have any questions, please get in touch with your Mintegral Account Manager or email <a href="mailto:self-service@mintegral.com">self-service@mintegral.com</a> <br /><br />

      The Mintegral Team
    `
  },
  mediationPostbackSetting: {
    text: 'Mediation Postback Setting',
    mediation: 'Mediation',
    postback: 'Postback',
    postbackUrl: 'Postback URL',
    postbackTime: 'Postback Time',
    generating: 'Generating',
    postbackStatusOptions: {
      received: 'Data Received',
      noData: 'No Data'
    },
    agreement: 'Data Postback Agreement',
    postbackguidance: 'Postback Guidance',
    mediationDataPostbackguidance: 'Mediation Data Postback Guidance',
    topOnGuide: 'Guide to TopOn for data postback-Mintegral',
    topOnGuideUrl:
      'https://cdn-adn-https.rayjump.com/cdn-adn/v2/portal/23/08/28/18/06/64ec71bf39c13.pdf'
  },
  awsApp: {
    text: `Dear advertiser,

    We're thrilled to announce Mintegral has launched Amazon Appstore UA Solutions on our platform. Now, you can access premium traffic from apps on Amazon’s Appstore through Mintegral’s Self-Service Platform.

    To help you better utilize this program, we have provided detailed instructions for setting up and configuring campaigns, which you can access through this link: https://assets-official.mintegral.com/v3/help-center/amazon+en.pdf. If you have any questions or need further assistance, please do not hesitate to contact your account manager or email us at self-service@mintegral.com.

    Thank you for your continued trust and support of Mintegral.
    `,
    ok: "Got it, don't show it again"
  },
  attractTraffic: {
    xmp: {
      desc: 'ONE-STOP CROSS-CHANNEL MEDIA BUYING PLATFORM',
      link: 'https://xmp.mobvista.com/en?utm_source=mintegral_adv'
    },
    pt: {
      desc: 'Creative Production Platform',
      link: 'https://www.mindworks-creative.com/register'
    },
    friendLink: 'Links'
  },
  creativeGroup: {
    text: 'Ad',
    groupText: 'Creative Set',
    existing: 'Existing Creative Set',
    name: 'Creative Set Name',
    selectRules: 'Please select Creative Set',
    creativetype: {
      text: 'Ad Output',
      groupText: 'Creative Set Type',
      image: 'Image',
      video: 'Video'
    },
    creativeTypePreview: 'Preview',
    related_offer: 'Related Offer',
    type: {
      text: 'Combination Method',
      programmatic: 'Programmatic Creative',
      customized: 'Customized Creative'
    },
    status: {
      effective: 'Effective',
      underReview: 'Under review',
      rejected: 'Rejected',
      pending: 'Pending',
      expired: 'Expired'
    },
    deletedialog: {
      warnings:
        'Deleting the following creative set(s) will result in associated offer(s) being unable to be delivered properly in certain ad type(s) in some region(s) due to missing creatives.',
      warningConfirm: 'Confirm deleting creative set(s)?',
      errors:
        'Please cancel targeting in regions as follows where there are missing creatives before deleting creative set(s).',
      cancelTargeting: 'Cancel Targeting Regions(s)'
    },
    material: {
      text: 'Creative',
      lack: 'Creative Missing',
      noSizeLimit: 'No size limit',
      applyTips:
        'Note: Creatives cannot be applied to creative sets that may exceed the number of creatives.',
      removeTips:
        'Removal of creatives may cause lack of necessary creatives in related offers, resulting in pause of some geos and ad types.',
      deleteTips:
        'Note: This operation will completely delete the creative from the account, which may cause the associated offer to not run properly in some regions or certain ad types due to missing creatives. Confirm to delete?',
      removeLabel: 'Creative Sets You Want to Remove Creatives from',
      applyLable: 'Apply Creatives to Existing Creative Set'
    },
    applyCreativesetTip:
      'Note: Creatives cannot be applied to creative sets that may exceed the number of creatives.',
    rules: {
      max50Materials: 'Up to 50 creatives',
      maxCountMaterialsForProgram: 'Up to {count} creatives',
      maxCountMaterials: 'Contains up to {imageCount} image materials and {videoCount} video materials.',
      mustIncludeMaterials: 'The ad set must include creatives',
      duplicateMaterials: 'Duplicate creatives',
      OnlyOneSameSize: 'Only one creative of the same size can be uploaded',
      OnlyOneEachType: 'Only one creative is allowed for each creative type',
      mustInclude: 'Upload at least {item}.',
      oneImage: 'one image creative',
      oneVideo: 'one video creative',
      onePlayable: 'one playable creative',
      oneIcon: 'one icon creative',
      leastOneImage: 'Must include 1 image',
      leastOneImageNVideo: 'Must include 1 video and 1 image',
      leastOneVideo: 'Must include 1 video',
      noSameName: 'Do not select ad sets with the same name',
      noSameNameForMaterial: 'Do not upload creative with the same name',
      noSameName2: 'Cannot have the same name as an existing ad set.',
      filename:
        'Ad Set name can only contain letters, numbers, hyphens, and underscores, and should be within 50 characters.',
      max10CreativeGroups: 'The new created creative sets cannot exceed 10',
      creativeNameRule: 'Ad output name should be within 100 characters.',
      requiredSizeErr:
        'Only one required size image({dimensions}) can be uploaded',
      optionalSizeErr:
        'Only one optional size image({dimensions}) can be uploaded',
      maxVideoCount: 'Only one video can be uploaded',
      maxPlayableCount: 'Only one playable can be uploaded',
      requiredSize: 'Required Size',
      optionalSize: 'Optional Size',
      countLimitForOffer: {
        msg1: 'Up to {countForProgram} programmatic creative sets.',
        msg2: 'Up to {countForProgram} programmatic creative sets and {countForCustom} customized creative sets.'
      }
    },
    operationGuide: 'Mintegral Creative Operation Guide',
    operationGuideUrl:
      'https://alidocs.dingtalk.com/i/p/ZR2PmK51081zvpO72AmoVLkNnDMplXdb?dontjump=true'
  }
}
