export default {
  All: 0,
  Yes: 1,
  No: 2,
  Action: {
    showBlockTrafficByCsv: 11002,
    showSupportSKADN: 11010,
    showTargetAppLogicSwitch: 16002,
    showPackage: 16001,
    editCampaign: 10001,
    addOfferRelatedCampaign: 10002,
    checkReport: 10003,
    appReport: 12006,
    targetApp: 11002,
    editOffer: 11005,
    copyOffer: 11006,
    setTracking: 11007,
    billingType: 11001,
    bidType: 11018,
    showTargetRoas: 11019,
    showTargetCpe: 11021,
    showDeepTargetSetting: 11014,
    supportDeepTargets: 11015,
    showAndroidMarketSetup: 11016,
    showTargetCategory: 11017,
    cba: 14001,
    vba: 14002,
    editOfferBudget: 11008,
    editAdTemplate: 11009,
    playturbo: 13005,
    showAdWords: 17001,
    ctvAuth: 11022,
    editOfferCreative: 11023,
    allowSetCyclicalBudget: 11024,
    allowToCustomCreative: 19001,
    creativeSetCountForProgramInOffer: 19002,
    creativeSetCountForCustomInOffer: 19004,
    materialCountInProgramCreativeSet: 19003
  },
  ReCaptcha: {
    key: '6LeHNjAUAAAAABRLE1PHResc9W4iZyEogJp9DTId',
    script:
      'https://www.recaptcha.net/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit',

    tencentScript: 'https://turing.captcha.qcloud.com/TCaptcha.js',
    tencentKey: '198228165'
  },
  Source: {
    ss: 1,
    adn: 2
  },
  PlatformV2: {
    android: 1,
    ios: 2,
    mix: 3,
    unlimited: 9, // ctv campaign platform
    list: [
      {
        k: 1,
        v: 'Android'
      },
      {
        k: 2,
        v: 'iOS'
      },
      {
        k: 3,
        v: 'Mix'
      }
    ]
  },
  Platform: {
    android: 1,
    ios: 2,
    list: [
      {
        k: 1,
        v: 'Android'
      },
      {
        k: 2,
        v: 'iOS'
      }
    ]
  },
  PlatformMap: {
    1: 'android',
    2: 'ios'
  },
  marketType: {
    appStore: 1,
    googlePlay: 2,
    apk: 3,
    others: 4,
    amazonAppstore: 5,
    miAppstore: 6,
    huaweiAppstore: 7,
    vivoAppstore: 8,
    oppoAppstore: 9,
    samsungAppstore: 10,
    ruAppstore: 11
  },
  OrderBy: {
    descending: 'DESC',
    ascending: 'ASC'
  },
  PromoteType: {
    app: 1,
    website: 2,
    miniprogram: 3,
    ctv: 4,
    list: [
      {
        k: 1,
        v_i18n: 'campaign.app'
      },
      {
        k: 2,
        v_i18n: 'campaign.website'
      },
      {
        k: 3,
        v_i18n: 'campaign.miniprogram'
      },
      {
        k: 4,
        v_i18n: 'campaign.ctv'
      }
    ]
  },
  BudgetType: {
    default: 1,
    allocateBudgetByDay: 1,
    allocateBudgetByPeriod: 2,
    list: [
      {
        k: 1,
        v_i18n: 'offer.allocateBudgetByDay'
      },
      {
        k: 2,
        v_i18n: 'offer.allocateBudgetByPeriod'
      }
    ]
  },
  BudgetForType: {
    default: 1,
    list: [
      {
        k: 1,
        v_i18n: 'offer.forAllArea'
      },
      {
        k: 2,
        v_i18n: 'offer.forSpecialArea'
      }
    ]
  },
  SetDailyCapType: {
    default: 1,
    conversion: 2,
    averageBudget: 3,
    list: [
      {
        k: 1,
        v_i18n: 'offer.spendingBudget'
      },
      {
        k: 3,
        v_i18n: 'offer.averageSpendingBudget'
      },
      {
        k: 2,
        v_i18n: 'offer.conversion'
      }
    ]
  },
  MaterialType: {
    image: 121,
    video: 122,
    playable: 131,
    list: [
      {
        k: 121,
        v_i18n: 'creative.materialType.image'
      },
      {
        k: 122,
        v_i18n: 'creative.materialType.video'
      },
      {
        k: 131,
        v_i18n: 'creative.materialType.playable'
      }
    ]
  },
  MaterialAdType: {
    banner: 1,
    interstitial: 2,
    native: 3,
    appWall: 4,
    rewardedAppWall: 5,
    interstitialVideo: 6,
    nativeVideo: 7,
    rewardedVideo: 8,
    instreamVideo: 9,
    moreOffer: 11,
    splashAD: 12
  },
  OfferStatus: {
    running: 1,
    stopped: 2,
    pending: 4,
    over_cap: 20,
    over_daily_cap: 11,
    campaign_stopped: 28,
    campaign_over_daily_cap: 29
  },
  CampaignStatus: {
    running: 1,
    stopped: 28,
    over_daily_cap: 29
  },
  DataSymbol: {
    currency: [
      'ecpm',
      'ecpc',
      'adv_original_money',
      'original_money',
      'cpi',
      'ecpi',
      'received_price'
    ],
    percent: [
      'ctr',
      'cvr',
      'ivr',
      'key_action_rate',
      'register_rate',
      'first_purchase_rate',
      'retention_d1_rate'
    ]
  },
  BillingStatus: {
    pending: 11,
    processing: 12
  },
  targetBidType: {
    cpi: 1,
    targetCpe: 13,
    targetRoas: 12,
    targetEgr: 10
  },
  billingType: {
    cpi: 1,
    cpe: 5,
    cpm: 3,
    cpc: 2,
    ocpi: 11
  },
  BidType: {
    cpi: 1,
    cpe: 5,
    cpm: 3,
    cpc: 2,
    oCPI: 11,
    oCPC: 12,
    oCPM: 13
  },
  deepTargetEvent: {
    Purchase: 'iap',
    AdRevenue: 'iaa',
    PurchaseAndAdRevenue: 'iaa_iap'
  },
  targetGoalWindow: {
    D0: 1,
    D7: 4,
    D3: 3
  },
  DeepTarget: {
    day1_retention: 1,
    first_purchase: 2,
    register: 3,
    key_action: 4
  },
  Currency: {
    usd: 1,
    cny: 2,
    options: [
      { k: 1, v: 'USD', symbol: '$' },
      { k: 2, v: 'CNY', symbol: '¥' }
    ]
  },
  PaymentMethod: {
    braintree: 1,
    // libpay: 2,
    wechatPay: {
      key: 'chinapnr_wechat_qr'
    },
    chinapnr: {
      type: 3,
      key: 'chinapnr',
      queryKey: 'payResult',
      storageKey: 'chinapnr_result',
      success: 10
    },
    payType: {
      qrcode: 'QR_CODE',
      redirect: 'REDIRECT'
    },
    success: 11, // order status
    failed: 13
  },
  ScreenOrientation: {
    landscape: 1,
    portrait: 2
  },
  BasicCreative: {
    options: [
      { k: 1, v: 'App Name' },
      { k: 2, v: 'CTA Button' },
      { k: 3, v: 'Description' },
      { k: 4, v: 'Short Title' }
    ]
  },
  ABTestMaterialType: {
    image: 121,
    video: 122,
    playable: 131,
    textAssets: 400
  },
  ABTestGroupType: {
    image: 1,
    video: 2,
    playable: 3,
    dynamicEndcard: 4,
    textAssets: 5
  },
  ABTestGroupAndMaterialMap: {
    1: 121,
    2: 122,
    3: 131,
    4: 131,
    5: 400
  },
  DynamicEndcard: [1, 5], // playable_type
  ImageDimension: [
    '1280x720',
    '512x512',
    '720x1280',
    '1200x627',
    '300x300',
    '320x480',
    '480x320',
    '640x320',
    '640x960',
    '768x1024',
    '800x480',
    '1024x768',
    '1200x628',
    '230x152',
    '320x50',
    '800x1200',
    '960x540',
    '1000x560',
    '600x500',
    '640x100',
    '640x120',
    '728x90'
  ],
  CreateFrom: {
    ss: 1,
    portal: 2,
    playturbo: 3
  },
  DeviceType: {
    IMEI: 1,
    IDFA: 2,
    GAID: 3,
    OAID: 4,
    IMEI_MD5: 6,
    IDFA_MD5: 7,
    GAID_MD5: 8,
    OAID_MD5: 9,
    options: [
      {
        k: 1,
        v: 'IMEI'
      },
      {
        k: 2,
        v: 'IDFA'
      },
      {
        k: 3,
        v: 'GAID'
      },
      {
        k: 4,
        v: 'OAID'
      },
      {
        k: 6,
        v: 'IMEI_MD5'
      },
      {
        k: 7,
        v: 'IDFA_MD5'
      },
      {
        k: 8,
        v: 'GAID_MD5'
      },
      {
        k: 9,
        v: 'OAID_MD5'
      }
    ]
  }
}
