<template>
  <el-card
    v-loading="$store.state.account.isLoading || isLoading"
    :body-style="{ padding: '80px 15px 100px' }"
  >
    <div class="g-container">
      <el-form
        ref="form"
        :model="dataFields"
        :rules="dataRules"
        :label-width="labelWidth"
        :hide-required-asterisk="'edit' == mode"
        label-position="right"
      >
        <el-row v-if="'create' == mode" :style="{ marginBottom: '50px' }">
          <div :style="{ width: labelWidth }" class="el-form-item__label">
            <h2 :style="{ fontSize: '30px' }">{{ mxVm$t('login.signup') }}</h2>
          </div>
          <p
            style="height: 40px;font-size:12px;white-space: nowrap;margin:0;line-height: 56px;"
          >
            {{ mxVm$t('login.signContactTips1') }}
            <span style="color:#009877;">self-service@mintegral.com</span>
            {{ mxVm$t('login.signContactTips2') }}
          </p>
        </el-row>
        <el-form-item
          :label="
            mxVm$t({ id: 'common.name', values: { item__id: 'account.user' } })
          "
          prop="name"
        >
          <el-input
            v-model="dataFields.name"
            data-key="input-name"
            :disabled="'edit' == mode || 'complete' == mode"
          />
        </el-form-item>
        <el-form-item
          v-if="dataFields.has_partner_credit"
          :label="mxVm$t('data.partnerCredit')"
          prop="partner_credit"
        >
          {{ currency }}{{ dataFields.partner_credit }}
        </el-form-item>
        <el-form-item :label="'Access Key'" prop="access_key">
          {{ dataFields.access_key }}
        </el-form-item>
        <el-form-item :label="'API Key'" prop="api_key">
          {{ dataFields.api_key }}
        </el-form-item>
        <el-form-item :label="mxVm$t('account.emailAddress')" prop="email">
          <!-- <el-input
            v-model="dataFields.email"
            data-key="input-email"
            :disabled="'edit' == mode || 'complete' == mode"
          /> -->
          <subscription-type-item
            v-bind="{
              itemFieldsProp: 'h__email',
              itemFields: dataFields.h__email,
              showDeleteBtn: false,
              disabledForFields: {
                email: 'edit' == mode || 'complete' == mode,
                subscription_type: false
              },
              isList: false,
              rules: { subscription_type: checkEmailSubscriptionRule() }
            }"
            @subscriptionTypeChange="onSubscriptionTypeChange"
            style="margin-left: 0;"
          />
        </el-form-item>
        <el-form-item v-if="'create' != mode">
          <template v-slot:label>
            <span>{{ mxVm$t('account.ccemailAddress') }}</span>
            <el-popover trigger="hover" placement="bottom">
              <div>
                {{ mxVm$t('account.ccemailTips') }}
              </div>
              <i
                slot="reference"
                class="el-icon-info"
                style="cursor:pointer;"
              />
            </el-popover>
          </template>
          <super-form-list
            prop="h__cc_emails"
            :data-fields="dataFields"
            :config-item="ccConfig"
            :item-fields="{
              email: '',
              subscription_type: [
                'system_notification_email',
                'delivery_daily_report'
              ]
            }"
          >
            <template v-slot="{ itemFieldsProp, index, itemFields, onDelete }">
              <subscription-type-item
                v-bind="{
                  itemFieldsProp,
                  itemFields,
                  showDeleteBtn: mode !== 'detail',
                  disabled: mode === 'detail',
                  rules: { subscription_type: checkEmailSubscriptionRule() }
                }"
                style="margin-left: 0"
                @delete="onDelete(index)"
                @subscriptionTypeChange="onSubscriptionTypeChange"
              />
            </template>
          </super-form-list>
        </el-form-item>
        <el-form-item
          :label="mxVm$t('account.contactNumber')"
          prop="contact_num"
        >
          <el-input
            v-model="dataFields.contact_num"
            data-key="input-contact-num"
          />
        </el-form-item>
        <el-form-item
          v-if="'create' == mode || 'complete' == mode"
          :label="mxVm$t('account.companyProperty')"
          prop="company_property"
        >
          <el-radio-group
            v-model="dataFields.company_property"
            v-loading="$store.state.dataOptions.isLoading"
            data-key="input-company-property"
          >
            <el-radio
              v-for="item in $store.state.dataOptions.data['company-property']"
              :key="item.k"
              :label="item.k"
            >
              {{ item.v }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="mxVm$t('account.companyName')"
          prop="company_name"
        >
          <el-input
            v-model="dataFields.company_name"
            :disabled="'edit' == mode"
            data-key="input-company-name"
          />
        </el-form-item>
        <el-form-item :label="mxVm$t('account.registeredAddress')" required>
          <div :style="{ width: '100%' }" class="f-fl">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="10">
                <el-form-item prop="registered_business_country">
                  <el-select
                    v-model="dataFields.registered_business_country"
                    data-key="input-country"
                    popper-class="input-country"
                    :loading="$store.state.dataOptions.isLoading"
                    :placeholder="`- ${mxVm$t('offer.country')} -`"
                    :disabled="'edit' == mode"
                    class="f-db"
                    filterable
                    @change="onRegisterdCountryChange"
                  >
                    <el-option
                      v-for="item in $store.state.dataOptions.data.country"
                      :key="item.k"
                      :value="item.k"
                      :label="item.v"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="14">
                <el-form-item prop="registered_business_address">
                  <el-input
                    v-model="dataFields.registered_business_address"
                    data-key="input-company-address"
                    :placeholder="mxVm$t('account.companyAddress')"
                    :disabled="'edit' == mode"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item :label="mxVm$t('account.headquater')" required>
          <div :style="{ width: '100%' }" class="f-fl">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="10">
                <el-form-item prop="headquarter_country">
                  <el-select
                    v-model="dataFields.headquarter_country"
                    data-key="input-country"
                    popper-class="input-country"
                    :loading="$store.state.dataOptions.isLoading"
                    :placeholder="`- ${mxVm$t('offer.country')} -`"
                    :disabled="'edit' == mode"
                    class="f-db"
                    filterable
                  >
                    <el-option
                      v-for="item in $store.state.dataOptions.data.country"
                      :key="item.k"
                      :value="item.k"
                      :label="item.v"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="14">
                <el-form-item prop="registered_business_address">
                  <el-input
                    v-model="dataFields.headquarter_address"
                    data-key="input-company-address"
                    :placeholder="mxVm$t('account.companyAddress')"
                    :disabled="'edit' == mode"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item :label="mxVm$t('offer.timezone')" prop="timezone">
          <el-select
            v-model="dataFields.timezone"
            data-key="input-timezone"
            popper-class="input-timezone"
            :loading="$store.state.dataOptions.isLoading"
            :placeholder="`- ${mxVm$t('offer.timezone')} -`"
            class="f-db"
          >
            <el-option
              v-for="item in $store.state.dataOptions.data.timezone"
              :key="item.k"
              :value="item.k"
              :label="item.v"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="mxVm$t('account.invoiceInformation')" required>
          <div :style="{ width: '100%' }" class="f-fl">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="10">
                <el-form-item prop="invoice_company">
                  <el-input
                    v-model="dataFields.invoice_company"
                    disabled
                    data-key="input-invoice-company"
                    :placeholder="mxVm$t('account.invoiceCompany')"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="14">
                <el-form-item prop="invoice_address">
                  <el-input
                    v-model="dataFields.invoice_address"
                    disabled
                    data-key="input-invoice-address"
                    :placeholder="mxVm$t('account.invoiceAddress')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item
          :label="mxVm$t('account.companyWebsite')"
          prop="company_website"
        >
          <el-input
            v-model="dataFields.company_website"
            data-key="input-company-website"
          />
        </el-form-item>
        <el-form-item
          :label="mxVm$t('account.productLink')"
          prop="product_link"
        >
          <el-input
            v-model="dataFields.product_link"
            data-key="input-product-link"
          />
        </el-form-item>
        <el-form-item
          v-show="
            mxEnum.No == $store.state.dataOptions.data['hide-local-currency']
          "
          prop="currency"
        >
          <template v-slot:label>
            {{ mxVm$t('account.settlementCurrency') }}
            <el-popover
              :content="mxVm$t('account.settlementCurrencyTips')"
              placement="top"
              trigger="hover"
              width="400"
            >
              <i slot="reference" class="el-icon-info" />
            </el-popover>
          </template>
          <el-radio-group
            v-model="dataFields.currency"
            :disabled="'create' != mode && 'complete' != mode"
            data-key="input-currency"
          >
            <el-radio
              v-for="item in mxEnum.Currency.options"
              :key="item.k"
              :label="item.k"
            >
              {{ item.v }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          prop="view_traffic_details"
          :label="'View Traffic Details'"
        >
          <template v-slot:label>
            {{ mxVm$t('account.viewTraffic') }}
            <el-popover
              :content="mxVm$t('account.viewTrafficTips')"
              placement="top"
              trigger="hover"
            >
              <template slot="reference">
                <!-- not support v-slot @v2.7.2 -->
                <i class="el-icon-info" />
              </template>
            </el-popover>
          </template>
          <el-switch
            v-model="dataFields.change_package_to_app_name"
            :active-value="1"
            :inactive-value="2"
          ></el-switch>
          <p>
            {{ mxVm$t('account.viewTrafficConfirm') }}
            <a :href="mxVm$t('account.viewTrafficTipsLink')" target="_blank">{{
              mxVm$t('account.viewTrafficConfirmItem')
            }}</a>
          </p>
        </el-form-item>
        <div v-if="'create' == mode || 'complete' == mode">
          <el-form-item prop="qualification_documents_url">
            <template v-slot:label>
              {{ mxVm$t('account.qualificationDocuments') }}
              <el-popover
                :content="mxVm$t('account.qualificationDocumentsTips')"
                placement="top"
                trigger="hover"
                width="400"
              >
                <i slot="reference" class="el-icon-info" />
              </el-popover>
            </template>
            <file-upload
              v-model="dataFields.qualification_documents_url"
              data-key="input-qualification-documents-url"
              :config="uploadConfig"
              name="file_sec"
            />
          </el-form-item>
          <el-form-item
            v-if="'complete' != mode"
            :label="mxVm$t('account.password')"
            prop="password"
          >
            <el-input
              v-model="dataFields.password"
              type="password"
              autocomplete="off"
              data-key="input-password"
            />
          </el-form-item>
          <el-form-item
            v-if="'complete' != mode"
            :label="mxVm$t('account.confirmPwd')"
            prop="password_confirmation"
          >
            <el-input
              v-model="dataFields.password_confirmation"
              type="password"
              autocomplete="off"
              data-key="input-password-confirmation"
            />
          </el-form-item>
          <el-form-item
            :label="mxVm$t('login.knowMintegral')"
            required
            prop="how_to_know"
          >
            <el-select
              v-model="dataFields.how_to_know"
              data-key="input-how-to-know"
              popper-class="input-how-to-know"
              :loading="$store.state.dataOptions.isLoading"
              :placeholder="`- ${mxVm$t('login.knowMintegral')} -`"
              class="f-db"
              filterable
            >
              <el-option
                v-for="item in $store.state.dataOptions.data['how-to-know'] ||
                  []"
                :key="item.k"
                :value="item.k"
                :label="item.v"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="
              mxEnum.No != $store.state.dataOptions.data['captcha-status'] &&
                'complete' != mode
            "
            prop="captcha"
          >
            <re-captcha ref="reCaptcha" v-model="dataFields.captcha" />
          </el-form-item>
          <el-form-item prop="is_agree">
            <div :style="{ lineHeight: '1.5' }">
              <el-checkbox
                v-model="dataFields.is_agree"
                :true-label="mxEnum.Yes"
                :false="mxEnum.No"
                data-key="input-is-agree"
              >
                {{ $t('account.agreeTermConditionPerfix') }}
                <a :href="$t('url.advertiserTermsOfService')" target="_blank">
                  {{ $t('account.agreeTerm') }}
                </a>
                {{ $t('account.agreeTermConditionSuffix') }}
              </el-checkbox>
            </div>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item prop="terms" :label="$t('account.condition')">
            <span v-if="$i18n.locale == 'ja'">
              {{
                mxVm$t({
                  id: 'action.view',
                  values: null
                })
              }}
            </span>
            <a :href="$t('url.advertiserTermsOfService')" target="_blank">
              {{ $t('account.agreeTerm') }}
            </a>
            {{ $t('account.agreeTermConditionSuffix') }}
          </el-form-item>
        </div>
      </el-form>
      <div class="f-tar f-mt-lg">
        <el-button
          v-if="'complete' == mode"
          class="s-limit"
          data-key="button-cancel"
          @click="onCancel"
        >
          {{ mxVm$t('action.cancel') }}
        </el-button>
        <el-button
          type="primary"
          class="s-limit"
          data-key="button-submit"
          @click="onSubmit"
        >
          {{ mxVm$t('action.submit') }}
        </el-button>
      </div>
    </div>
  </el-card>
</template>

<script>
import ReCaptcha from '~/components/forms/ReCaptcha.vue'
import FileUpload from '~/components/forms/FileUpload.vue'
import SuperFormList from '~/components/common/SuperFormListV2.vue'
import SubscriptionTypeItem from '~/components/account/SubscriptionTypeItem'
export default {
  components: {
    ReCaptcha,
    FileUpload,
    SuperFormList,
    SubscriptionTypeItem
  },
  props: {
    mode: {
      type: String,
      default: 'edit'
    }
  },
  data() {
    const dataFields = {
      ...this.$store.state.account.basicInfo,
      timezone: this.mxGetDefaultTimezone('signup' == this.mode),
      ...this.initEmailsHandler(this.$store.state.account.basicInfo)
    }
    if ('create' == this.mode) {
      dataFields.qualification_documents_url = []
    }
    if ('complete' == this.mode) {
      dataFields.currency = undefined
    }
    const dataRules = {
      name: [
        {
          required: true,
          message: this.mxVm$t('rule.isRequired')
        },
        {
          message: this.mxVm$t('rule.usernameRule'),
          regexp: /^[a-zA-Z][a-zA-Z0-9_.]{2,31}$/,
          validator: this.mxValidateByRegexp
        },
        {
          min: 3,
          max: 32,
          message: this.mxVm$t({
            id: 'rule.length',
            values: { min: 3, max: 32 }
          })
        },
        {
          trigger: 'blur',
          api: 'registerCheck',
          skip: value =>
            ('edit' == this.mode || 'complete' == this.mode) &&
            value == this.$store.state.account.basicInfo.name,
          validator: this.mxValidateByServer
        }
      ],
      // company_name: [
      //   {
      //     required: true,
      //     message: this.mxVm$t('rule.isRequired')
      //   },
      //   {
      //     trigger: 'blur',
      //     api: 'registerCheck',
      //     skip: value =>
      //       'edit' == this.mode &&
      //       value == this.$store.state.account.basicInfo.company_name,
      //     validator: this.mxValidateByServer
      //   }
      // ],
      timezone: [
        {
          required: true,
          message: this.mxVm$t('rule.isRequired')
        }
      ],
      invoice_company: [
        {
          required: true,
          message: this.mxVm$t('rule.isRequired')
        }
      ],
      invoice_address: [
        {
          required: true,
          message: this.mxVm$t('rule.isRequired')
        }
      ],
      // registered_business_country: [
      //   {
      //     required: true,
      //     message: this.mxVm$t('rule.isRequired')
      //   }
      // ],
      // registered_business_address: [
      //   {
      //     required: true,
      //     message: this.mxVm$t('rule.isRequired')
      //   }
      // ],
      // headquarter_country: [
      //   {
      //     required: true,
      //     message: this.mxVm$t('rule.isRequired')
      //   }
      // ],
      // headquarter_address: [
      //   {
      //     required: true,
      //     message: this.mxVm$t('rule.isRequired')
      //   }
      // ],
      company_website: [
        {
          validator: this.mxDebounce(this.mxValidateUrl, 500)
        }
      ],
      product_link: [
        {
          validator: this.mxDebounce(this.mxValidateUrl, 500)
        }
      ],
      currency: [
        {
          required: true,
          message: this.mxVm$t('rule.isRequired')
        }
      ],
      qualification_documents_url: [
        {
          required: true,
          message: this.mxVm$t('rule.isRequired')
        }
      ],
      email: [
        {
          required: true,
          message: this.mxVm$t('rule.isRequired')
        },
        {
          trigger: 'blur',
          type: 'email',
          message: this.mxVm$t('rule.email')
        },
        {
          trigger: 'blur',
          skip: value =>
            ('edit' == this.mode || 'complete' == this.mode) &&
            value == this.$store.state.account.basicInfo.email,
          api: 'registerCheck',
          validator: this.mxValidateByServer
        }
      ],
      contact_num: [
        {
          required: true,
          message: this.mxVm$t('rule.isRequired')
        }
      ],
      company_property: [
        {
          required: true,
          message: this.mxVm$t('rule.isRequired')
        }
      ],
      password: [
        {
          required: true,
          message: this.mxVm$t('rule.isRequired')
        },
        {
          validator: this.mxDebounce(this.mxValidatePassword, 500)
        }
      ],
      password_confirmation: [
        {
          required: true,
          message: this.mxVm$t('rule.isRequired')
        },
        {
          trigger: 'blur',
          validator: (rule, value, callback) => {
            const { password } = this.dataFields
            if (value != password) {
              return callback(new Error(this.mxVm$t('account.confirmPassword')))
            }
            return callback()
          }
        }
      ],
      how_to_know: [
        {
          required: true,
          message: this.mxVm$t('rule.isRequired')
        }
      ],
      captcha: [
        {
          required: true,
          message: this.mxVm$t('rule.captcha')
        }
      ],
      is_agree: [
        {
          required: true,
          message: this.mxVm$t('rule.agreement')
        }
      ]
    }

    return {
      dataFields,
      dataRules,
      labelWidth:
        this.$i18n.locale == 'cn'
          ? '200px'
          : this.$i18n.locale == 'ja'
          ? '320px'
          : '280px',
      isLoading: false,
      initDynamicData: false,
      uploadConfig: [
        {
          type: 'application',
          ext: ['pdf'],
          byte: 10 * 1024 * 1024,
          size: '10M'
        },
        {
          type: 'image',
          ext: ['jpg', 'jpeg', 'png', 'bmp', 'gif'],
          byte: 10 * 1024 * 1024,
          size: '10M'
        }
      ],
      needCheckSubscription: false
    }
  },
  computed: {
    ccConfig() {
      return {
        field: 'cc_emails',
        component: 'input',
        placeholder: this.mxVm$t('account.ccemailAddress')
      }
    },
    currency() {
      return this.$store.state.account.basicInfo?.currencySymbol ?? ''
    }
  },
  watch: {
    '$store.state.account.isAuth': {
      handler(newVal, oldVal) {
        if (this.initDynamicData) return
        if (newVal && !oldVal) {
          this.dataFields = {
            ...this.$store.state.account.basicInfo,
            ...this.initEmailsHandler(this.$store.state.account.basicInfo)
          }
          this.initDynamicData = true
        }
      },
      immediate: true
    }
  },
  created() {
    const options = [
      'country',
      'timezone',
      'company-property',
      'captcha-status',
      'hide-local-currency',
      'how-to-know',
      'adv-user-email-subscription-type'
    ]
    this.$store.dispatch('fetchOptions', options).then(() => {
      const flag = this.$store.state.dataOptions.data['hide-local-currency']
      const { mxEnum } = this.$root
      if (flag == mxEnum.No) return
      this.dataFields.currency = mxEnum.Currency.usd
    })
  },
  methods: {
    onSubscriptionTypeChange() {
      if (this.needCheckSubscription) {
        this.$refs.form.validate()
      }
    },
    initEmailsHandler(dataDetail) {
      const { cc_emails, subscription_type_list } = dataDetail
      const emailMap = {}

      if (subscription_type_list) {
        const subscriptionTypeList = JSON.parse(subscription_type_list)
        subscriptionTypeList.forEach(element => {
          emailMap[element.email] = element.subscription_type
        })
      }
      const ccEmailArr = cc_emails ? cc_emails.split(',') : []
      const ccEmailSubscriptions = ccEmailArr.map(item => {
        return {
          email: item,
          subscription_type: emailMap[item] || []
        }
      })
      return {
        h__email: {
          email: dataDetail.email,
          subscription_type: emailMap[dataDetail.email] || []
        },
        h__cc_emails: ccEmailSubscriptions
      }
    },
    getEmailFields(dataFields) {
      const { h__cc_emails, h__email } = dataFields
      const subscription_type_list = [...h__cc_emails]
      subscription_type_list.push(h__email)
      return {
        cc_emails: h__cc_emails.map(item => item.email).join(','),
        subscription_type_list: JSON.stringify(subscription_type_list),
        email: h__email.email
      }
    },
    checkEmailSubscriptionRule() {
      const validFunc = (rule, value, callback) => {
        const { h__cc_emails, h__email } = this.dataFields
        const subscriptionTypes = [...h__email.subscription_type]
        h__cc_emails.forEach(item => {
          subscriptionTypes.push(...item.subscription_type)
        })
        const isValid = subscriptionTypes.find(item => item === 'billing_email')

        if (!isValid) {
          const msg = this.mxVm$t('account.subscribedEmail.validMsg')

          const errs = {
            'h__email.subscription_type': msg
          }

          h__cc_emails.forEach((item, index) => {
            const key = `h__cc_emails.${index}.subscription_type`
            errs[key] = msg
          })
        }
        if (!!isValid) {
          return callback()
        }
        return callback(new Error(rule.message))
      }

      return [
        {
          trigger: 'manual',
          message: this.mxVm$t('account.subscribedEmail.validMsg'),
          validator: validFunc
        }
      ]
    },
    async submitForm() {
      this.isLoading = true
      const method =
        'edit' == this.mode || 'complete' == this.mode ? '$put' : '$post'
      const urlKey =
        'edit' == this.mode
          ? 'accountInfo'
          : 'complete' == this.mode
          ? 'completeInfo'
          : 'register'

      const params = {
        ...this.dataFields,
        ...this.getEmailFields(this.dataFields)
      }
      const p = this.formatValue(params, true)
      const { code } = await this.$axios[method](urlKey, p)
      this.isLoading = false
      if (code == 200) {
        this.$notify({
          type: 'success',
          title: this.mxVm$t('common.success')
        })
        if ('edit' == this.mode) {
          this.$store.commit('account/setBasicInfo', this.dataFields)
        } else if ('complete' == this.mode) {
          this.$store.dispatch('account/auth', 'force')
        } else {
          this.$router.push({ path: this.mxVmLocalePath('/login') })
        }
        this.$emit('after-submit')
      }
      // reset captcha
      if (this.$refs.reCaptcha) {
        this.$refs.reCaptcha.reset()
      }
    },
    onSubmit() {
      this.$refs.form.validate(isValid => {
        this.needCheckSubscription = true
        if (!isValid) return false
        // check success
        this.submitForm()
      })
    },
    async onCancel() {
      this.isLoading = true
      const { id } = this.$store.state.account.basicInfo
      const { code, data } = await this.$axios.$get('accountPlayturbo', {
        params: { adv_user_id: id }
      })
      if (code == 200 && data) {
        const { redirect_url } = data
        window.open(redirect_url, '_blank')
        this.onLogout()
      }
      this.isLoading = false
    },
    async onLogout() {
      // TODO Why
      // console.log(this.$i18n) // right
      await this.$store.dispatch('account/logout')
      // console.log(this.$i18n) // null
      if (!this.$store.state.account.isAuth) {
        // const path = this.mxVmLocalePath('/login') // error: this.$i18n is null
        window.close()
      }
    },
    formatValue(value = {}, isPure) {
      const result = Object.assign({}, value)
      for (const key in result) {
        // remove hidden key for submit
        if (0 == key.indexOf('h__')) {
          delete result[key]
          continue
        }
        const val = result[key]
        if (Array.isArray(val)) {
          if (
            val.length &&
            (Array.isArray(val[0]) || 'object' == typeof val[0])
          ) {
            if (0 == Object.keys(val[0]).length) continue
            result[key] = JSON.stringify(result[key])
          } else {
            result[key] += ''
          }
        } else if ('object' == typeof val && val) {
          result[key] = JSON.stringify(val)
        } else if ('boolean' == typeof val) {
          const { Yes, No } = this.$root.mxEnum
          result[key] = val ? Yes : No
        }
        const rawKeys = []
        if ('' === result[key] && isPure) {
          if (!rawKeys.includes(key)) {
            result[key] = undefined
          }
        } else if (undefined == result[key] && false === isPure) {
          result[key] = ''
        }
      }
      return result
    },
    onRegisterdCountryChange(v) {
      if (!v) {
        this.dataFields.currency = undefined
      } else if (v === 'CN') {
        this.dataFields.currency = 2
      } else {
        this.dataFields.currency = 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
section /deep/ {
  .el-select {
    width: 100%;
  }
}
</style>
