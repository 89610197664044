<template>
  <div v-loading="isLoading" class="target-app-detail">
    <el-row class="f-mb-10">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane name="app" :label="mxVm$t('offer.AppId')">
          <el-input
            ref="appInput"
            v-model="appIds"
            type="textarea"
            :rows="5"
            :placeholder="mxVm$t('offer.AppIdPlaceholder')"
            @blur="formatIds('appIds')"
          >
          </el-input>
        </el-tab-pane>
        <el-tab-pane name="store" :label="mxVm$t('offer.StoreId')">
          <el-input
            ref="storeInput"
            v-model="storeIds"
            type="textarea"
            :rows="5"
            :placeholder="mxVm$t('offer.StoreIdPlaceholder')"
            @blur="onInputBlur('storeIds')"
          >
          </el-input>
          <h3 v-if="errorMsg.length > 0" class="error-msg">{{ errorMsg }}</h3>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
import { isAndroidPackageName, isIOSPackageName } from '~/utils/validator.js'
// let innerActiveTabLock = false
export default {
  name: 'TargetAppDetail',
  props: {
    targetAppIds: {
      type: Array,
      default: () => []
    },
    targetStoreIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoading: false,
      updateLocker: false,
      updateLockerTimer: null,
      errorMsg: '',
      activeName: 'app',
      innerActiveTabLock: false
    }
  },
  computed: {
    appIds: {
      get() {
        return this.targetAppIds.join(',')
      },
      set(val) {
        this.$emit('update:targetAppIds', val.split(','))
      }
    },
    storeIds: {
      get() {
        return this.targetStoreIds.join(',')
      },
      set(val) {
        this.$emit('update:targetStoreIds', val.split(','))
      }
    },
    activeTabLock: {
      get() {
        return this.innerActiveTabLock
      },
      set(val) {
        this.innerActiveTabLock = true
        clearTimeout(this.innerActiveTabLockTimmer)
        this.innerActiveTabLockTimmer = setTimeout(() => {
          this.innerActiveTabLock = false
        }, 2000)
      }
    }
  },
  watch: {
    targetAppIds() {
      this.updateIds()
    },
    targetStoreIds() {
      this.updateIds()
    },
    activeName() {
      this.activeTabLock = true
    }
  },
  updated() {
    this.updateIds()
    this.checkActiveTab()
  },
  mounted() {
    this.updateIds()
    this.checkActiveTab()
  },
  methods: {
    checkActiveTab() {
      if (this.activeTabLock == true) {
        this.activeTabLock = true
        return
      }
      if (
        (this.$refs.appInput &&
          this.$refs.appInput.$el.getElementsByTagName('textarea')[0] ==
            document.activeElement) ||
        (this.$refs.storeInput &&
          this.$refs.storeInput.$el.getElementsByTagName('textarea')[0] ==
            document.activeElement) ||
        !this.updateLocker
      ) {
        return
      }
      if (this.appIds.length > 0 && this.storeIds.length === 0) {
        this.changeTab('app')
      }
      if (this.appIds.length === 0 && this.storeIds.length > 0) {
        this.changeTab('store')
      }
    },
    checkPackageName() {
      const invalidPackageNames = this.targetStoreIds.filter(packageString => {
        const packageName = packageString.trim()
        if (
          packageName &&
          !isAndroidPackageName(packageName) &&
          !isIOSPackageName(packageName)
        ) {
          return true
        }
      })
      if (invalidPackageNames.length > 0) {
        this.changeTab('store')
        this.errorMsg =
          this.mxVm$t('rule.invalidStoreId') +
          ':  ' +
          invalidPackageNames.join(', ')
        return false
      } else {
        this.errorMsg = ''
        return true
      }
    },
    changeTab(tabName) {
      this.activeName = tabName
    },
    onInputBlur(type) {
      this.formatIds(type)
      this.checkPackageName()
    },
    updateIds() {
      if (this.updateLocker) {
        return
      }
      clearTimeout(this.updateLockerTimer)
      this.updateLockerTimer = setTimeout(() => {
        if (
          this.$refs.appInput &&
          this.$refs.appInput.$el.getElementsByTagName('textarea')[0] !==
            document.activeElement
        ) {
          this.formatIds('appIds')
        }
        if (
          this.$refs.storeInput &&
          this.$refs.storeInput.$el.getElementsByTagName('textarea')[0] !==
            document.activeElement
        ) {
          this.formatIds('storeIds')
        }
        this.updateLocker = true
        setTimeout(() => {
          this.updateLocker = false
        }, 200)
      }, 500)
    },
    formatIds(key) {
      let idsString = this[key]
      idsString = idsString.replace(/[\r\n]/g, ',')
      const idsArray = idsString.split(',')
      const formatIdArray = idsArray.map(id => {
        return id.replace(/(^\s*)|(\s*$)/g, '')
      })
      this[key] = formatIdArray
        .filter(id => {
          return id.length > 0
        })
        .join(', ')
      return formatIdArray
    },
    clearErrorMsg() {
      this.errorMsg = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.f-mb-10 {
  margin-bottom: 10px;
}
.error-msg {
  color: #f56c6c !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
</style>
