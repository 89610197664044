export function formatQuery(params, isStringFormat) {
  if (typeof params != 'object' || !params) return
  const query = { ...params }
  let queryStr = ''
  for (const key in query) {
    if (undefined === query[key] || !`${query[key]}`) {
      delete query[key]
      continue
    } else if (Array.isArray(query[key])) {
      query[key] += ''
    }
    queryStr += (queryStr ? '&' : '') + `${key}=${query[key]}`
  }
  return isStringFormat ? queryStr : query
}
