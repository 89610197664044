var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elm-user-bar flex justify-between items-center"},[_c('div',[(_vm.isShowBalance)?_c('span',{staticClass:"float-left ml-[16px] hidden-sm-and-down"},[_c('i',{staticClass:"ss-v2-iconfont ss-v2-icon-finance-wallet font-22 align-middle"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.mxVm$t({ id: 'account.balance' })))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.mxFormatNumber(_vm.$store.state.account.balanceInfo.balance, {
            prepend: _vm.$store.state.account.basicInfo.currencySymbol
          })))])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex items-center gap-6"},[_c('attract-dropdown'),_vm._v(" "),(
          _vm.$store.state.account.basicInfo.pm_name ||
            _vm.$store.state.account.basicInfo.bd_name
        )?_c('contact-us-bar'):_vm._e(),_vm._v(" "),_c('the-dropdown-lang',{staticClass:"inline-block align-top"}),_vm._v(" "),(
          _vm.$store.state.account.basicInfo.account_state == 2 ||
            (!_vm.isStateless && _vm.$store.state.account.basicInfo.name)
        )?_c('div',{staticClass:"inline-block align-top"},[_c('span',{staticClass:"u-text-limit"},[_c('el-dropdown',[_c('span',{staticClass:"el-dropdown-link"},[_vm._v("\n              "+_vm._s(_vm.$store.state.account.basicInfo.name)+"\n              "),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_vm._v(" "),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.onLogout}},[_vm._v(_vm._s(_vm.mxVm$t('common.logout')))])],1)],1)],1)],1)]):_c('span',{staticClass:"inline-block align-top"},[_c('a',{staticClass:"margin-left-8",attrs:{"href":`https://www.mintegral.com/${
              'cn' == _vm.$i18n.locale ? 'cn' : 'en'
            }/selfservice`,"target":"_blank"}},[_vm._v("\n          "+_vm._s(_vm.mxVm$t('login.aboutUs'))+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"u-item-separator"},[_vm._v("|")]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":_vm.mxVmLocalePath('/login')}},[_vm._v("\n          "+_vm._s(_vm.mxVm$t('login.text'))+"\n        ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }