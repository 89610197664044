// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ss-menu.el-menu{border-right:0}.ss-menu .el-menu-item.is-active{color:#fff;background-color:#00a68f}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#00a68f",
	"infoColor": "#4f6475",
	"headerBgColor": "#000",
	"headerTextColor": "#131523",
	"headerMenuActiveTextColor": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
