export default function({ store, route, redirect }) {
  // auto login from protal demand side
  if (route.query.auto_login == 1) {
    // store.commit('account/setAuth', null)
    return store.dispatch('account/auth', 'force')
  } else if (!store.state.account.isAuth) {
    // redirect in global response
    return store.dispatch('account/auth')
  }
}
