<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :title="mxVm$t('common.error')"
    width="500px"
    @close="$router.push({ path: mxVmLocalePath('/') })"
  >
    <p>{{ mxVm$t('error.noPermission') }}</p>
    <template v-slot:footer>
      <el-button
        type="primary"
        class="s-limit"
        @click="$router.push({ path: mxVmLocalePath('/') })"
      >
        {{ mxVm$t('common.backToHome') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true
    }
  }
}
</script>
