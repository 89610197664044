export const state = () => ({
  pageTitle: '',
  dataOptions: {
    isLoading: false,
    data: {}
  },
  webContent: {},
  currentPath: ''
})

export const mutations = {
  setPageTitle(state, pageTitle) {
    state.pageTitle = pageTitle
  },
  setDataOptions(state, { isLoading, data }) {
    state.dataOptions = {
      isLoading:
        undefined != isLoading ? isLoading : state.dataOptions.isLoading,
      data: {
        ...state.dataOptions.data,
        ...data
      }
    }
  },
  resetDataOptions(state) {
    state.dataOptions = {
      isLoading: false,
      data: {}
    }
  },
  webContent(state, webContent) {
    state.webContent = webContent
  },
  setCurrentPath(state, currentPath) {
    state.currentPath = currentPath
  }
}

export const actions = {
  async fetchWebContent({ commit }) {
    const webContentStore = sessionStorage.getItem('local_web_content')
    if (webContentStore) {
      commit('webContent', JSON.parse(webContentStore))
      return
    }

    const { data } = await this.$axios.$get('optionsBatch', {
      params: { query: 'web-content' }
    })
    if (data && data['web-content']) {
      commit('webContent', data['web-content'])
      sessionStorage.setItem(
        'local_web_content',
        JSON.stringify(data['web-content'])
      )
    }
  },
  async fetchOptions({ commit, state }, optionsKeys) {
    let apiKey = 'optionsBatch'
    const finalData = {}
    const keys = []
    const singleFetchKeys = []
    const dataKeys = Object.keys(state.dataOptions.data)
    const isSingleFetch =
      'string' == typeof optionsKeys && /SingleFetch$/.test(optionsKeys)
    if (true === optionsKeys) {
      // refresh all options
      dataKeys.forEach(item => {
        if (/SingleFetch$/.test(item)) {
          singleFetchKeys.push(item)
        } else {
          keys.push(item)
        }
      })
    } else if (Array.isArray(optionsKeys)) {
      optionsKeys.forEach(item => {
        const index = dataKeys.indexOf(item)
        if (index == -1) {
          // use ?refresh to force to fetch new data
          keys.push(item.replace('?refresh', ''))
        }
      })
    } else if (isSingleFetch) {
      // single fetch
      apiKey = optionsKeys.replace(/SingleFetch$/, '')
    }

    if ((!keys.length && !isSingleFetch) || -1 < dataKeys.indexOf(optionsKeys))
      return

    commit('setDataOptions', { isLoading: true })
    if (keys.length || isSingleFetch) {
      const { data } = await this.$axios.$get(apiKey, {
        params: keys.length ? { query: keys + '' } : undefined
      })

      // TODO move to the place that is used
      if (data && data['offer-status']) {
        data['offer-status-map'] = {}
        data['offer-status'].forEach(item => {
          data['offer-status-map'][item.k] = item.v
        })
      }

      if (isSingleFetch) {
        Object.assign(finalData, { [optionsKeys]: data })
      } else {
        Object.assign(finalData, data)
      }
    }

    if (singleFetchKeys.length) {
      // TODO use promise all
      for (let i = 0; i < singleFetchKeys.length; i++) {
        const key = singleFetchKeys[i].replace(/SingleFetch$/, '')
        const { data } = await this.$axios.$get(key)

        Object.assign(finalData, {
          [singleFetchKeys[i]]: data[key]
        })
      }
    }

    commit('setDataOptions', { isLoading: false, data: finalData })
  }
}
