const API = {
  // operation log
  getOperationLogs: '/v1/history/index',
  getOperationLogDetal: '/v1/history/detail',
  getObjectives: '/v1/change-history/objective',
  getOperators: '/v1/change-history/operator',
  exportOperationLogs: '/v1/history/export',

  apkupload: '/v1/apk/upload',
  // upload & creatives
  filesUpload: '/v1/files/upload',
  filesUploadV2: '/v2/file/upload',
  filesDownload: '/v1/files/download',
  creativesUpload: '/v2/creatives/upload',
  creativesCheck: '/v1/creatives/check',
  creativesRelation: '/v1/creatives/relation',
  creatives: '/v1/creatives',
  creativesLibSave: '/v1/creatives/library/save',
  creativesLibValidate: '/v1/creatives/library/validate',
  creativesLibSearch: '/v1/creatives/library/search',
  materialLibSearch: '/v1/material-library',
  materialApplyForCreativeSet: '/v1/material-library/apply',
  materialRemoveFromCreativeSet: '/v1/material-library/remove',
  creativesLibDelete: '/v1/creatives/library/delete',
  creativesLibDimension: '/v1/creatives/library/getDimension',
  creativesLibRule: '/v1/creatives/library/rule',
  // options
  optionsBatch: '/v1/options/_batch',
  options: '/v1/options',
  optionsAuth: '/v1/options/_batch-with-auth',
  settingBatch: '/v1/setting-engine/_batch?query=offer_min_budget',
  // campaigns
  campaigns: '/v1/campaigns',
  appInfo: '/v1/campaigns/app-info',
  previewUrlCheck: '/v1/campaigns/preview-url-check',
  campaignsTargetApp: '/v1/campaigns/{id}/selected-target-app',
  campaignsTargetAppOption: '/v1/campaigns/{id}/target-top-app',
  campaignsStatus: '/v1/campaigns/status',
  campaignUpdateStatus: '/v1/campaigns/update-status', // 新版UE使用
  campaignRefreshStatus: '/v1/campaign/refresh-status',
  // offers
  offers: '/v1/offers',
  offersCheck: '/v1/offers/check',
  offersExport: '/v1/offers-export',
  offersUpdate: '/v1/offers/{id}/update-status',
  offersUpdateStatus: '/v1/offers/update-status', // 新版UE使用
  offerRefreshStatus: '/v1/offer/refresh-status',
  offersTargetApp: '/v1/offers/{id}/selected-target-app',
  offersTargetAppOption: '/v1/offers/{id}/target-top-app',
  offerPackageRunApp: '/v1/offers/{id}/run-app',
  priceProposal: '/v1/raw-rpc?p=/price/proposal',
  budgetProposal: '/v1/raw-rpc?p=/budget/proposal',
  // receivedPriceSchedule: '/v1/offers/{id}/schedule-received-price',
  receivedPriceSchedule: '/v1/raw-rpc?p=/price/adv-offer-price-schedule',
  totalBudgetSchedule: '/v1/offers/{id}/schedule-received-total-budget',
  citySchedule: '/v1/offers/{id}/schedule-city-code',
  getRoasGoalSuggest: '/v1/price/deep-target/goal-suggest',
  allowPackageToRoas: '/v1/price/deep-target/allow-package',
  allowTargetGoalTypes: 'v1/price/deep-target/allow-target-goal-types',
  getEventMappingList: '/v1/price/deep-target/allow-original-events',
  getDataSourceByPackage: '/v1/price/deep-target/allow-data-source',
  // tracking
  trackingLink: '/v1/offers/{id}/tracking-link',
  getTestLink: '/v1/offers/{id}/click-test-link',
  trackingParse: '/v1/raw-rpc?p=/tracking/set-tracking-link',
  getAllMacro: '/v1/raw-rpc?p=/tracking/all-macro',
  getMarketUrl: '/v1/raw-rpc?p=/tracking/get-market-url',
  // runOfferTest: '/v1/offers/{id}/run-test',
  geoBudget: '/v1/offers/{id}/geo-budget',
  scheduleGeoBudget: '/v1/offers/{id}/schedule-geo-budget',
  // price
  priceExport: '/v1/price/{id}/data-export',
  priceImport: '/v1/price/data-import',
  priceConfirm: '/v1/price/data-confirm',
  // reports
  reportsTotal: '/v1/reports/{field}-total',
  reportsExport: '/v1/reports/{field}-export',
  reports: '/v1/reports/{field}',
  createdId: '/v1/search/creative-id',
  createdName: '/v1/search/creative-name',
  creativeGroupName: '/v1/search/customer-creative-group-name',
  campaignPackageName: '/v1/search/campaign-pkg-name',

  searchStoreId: '/v1/search/package-name',
  creativeName: '/v1/search/customer-creative-name',
  dcoPreview: '/v1/reports/dco/preview-url',
  // accounts
  accountInfo: '/v1/base-info',
  accounts: '/v1/accounts',
  balanceInfo: '/v1/balance',
  updatePassword: '/v1/update-password',
  forgetPassword: '/v1/forget-password',
  resetPassword: '/v1/reset-password',
  attributionSetting: '/v1/attribution-setting',
  deleteSubuser: '/v1/accounts/{id}',
  paymentSign: '/v1/payments/sign',
  paymentMethods: '/v1/payments/methods',
  paymentToken: '/v1/payments/token',
  paymentCountry: '/v1/payments/country',
  exchangeRate: '/v1/exchange-rate',
  orders: '/v1/orders',
  billings: '/v1/billings',
  billingsExport: '/v1/billings-export',
  accountTargetApp: '/v1/accounts/selected-target-app',
  accountTargetAppOption: '/v1/accounts/target-top-app',
  accountPlayturbo: '/v1/accounts/login-playturbo',
  getWechatPayInfo: '/v1/orders/wechat-payinfo',
  agreeTerm: '/v1/permission',
  completeInfo: '/v1/register-inter',
  applyInvoice: '/v1/billings/invoice',

  // search
  campaignsOpt: '/v1/search/campaign/name',
  offersOpt: '/v1/search/offer/name',
  uuidOpt: '/v1/search/offer/uuid',
  setNameOpt: '/v1/search/set-name',
  creativeSetOpt: '/v1/search/customer-creative-group-name',
  demandPkgByUser: '/v1/search/demand-pkg-by-user',

  // global
  auth: '/v1/auth',
  login: '/v1/login',
  logout: '/v1/logout',
  register: '/v1/register',
  registerCheck: '/v1/register/check',

  // creatives
  editCreativesName: '/v1/material-library',
  creativesBatch: '/v1/creatives/batch',

  // basic creative
  basicCreativesAdvIndex: '/v1/basic-creatives/adv-index',
  basicCreativesTranslate: '/v1/basic-creatives/translate',
  basicCreativesDetectLanguage: '/v1/basic-creatives/detect-language',

  // playable creative
  playableCreativesDeleted: '/v1/playable/creatives/deleted',
  playableCreativesAdvIndex: '/v1/playable/creatives/adv-index',
  playableCreativesBgmIndex: '/v1/playable/creatives/bgm-index',
  playableCreativesLibIndex: '/v1/playable/creatives/lib-index',
  playableCreativesLibApply: '/v1/playable/creatives/apply',
  playableCreativesLibApplyUpload: '/v1/playable/creatives/apply-with-upload',
  playableCreativesLibApplySelected: '/v1/playable/creatives/apply-no-check',
  playableCreativesUpload: '/v1/playable/creatives/upload',
  playableCreativesOptions: '/v1/playable/query/options', // ?keyword=app,bgm,playable
  playableCreativesPreview: '/v1/playable/creatives/preview',
  playableCreativesUploadUrl: '/v1/playable/creatives/upload-url',

  // dco creative
  dcoCreativesLibIndex: '/v1/dco/creatives/lib-index',
  dcoCreativesUpload: '/v1/dco/creatives/upload',
  dcoCreativesLibApply: '/v1/dco/creatives/apply',
  dcoCreativesLibApplySelected: '/v1/dco/creatives/apply-no-check',
  dcoCreativesAdvIndex: '/v1/dco/creatives/adv-index',
  dcoCreativesDeleted: '/v1/dco/creatives/deleted',

  // manage ab test
  crTestAdvIndex: '/v1/offers/{id}/crtest-index',
  crTestMetrics: '/v1/options/report-crtest-metrics',
  crTestBreakdowns: '/v1/options/report-crtest-breakdowns',
  crTestExport: '/v1/offers/{id}/reports/crtest-export',
  crTestTotal: '/v1/offers/{id}/reports/crtest-total',
  crTest: '/v1/offers/{id}/reports/crtest',
  crTestSearch: '/v1/offers/{id}/crtest-search',

  // creative set
  // setmove: '/v1/creatives/adwords/move',
  // setindex: '/v1/creatives/adwords/index',
  // setalike: '/v1/creatives/adwords/alike',
  // setcreate: '/v1/creatives/adwords/create',
  // setupdate: '/v1/creatives/adwords/update',
  // setdetail: '/v1/creatives/adwords/detail',
  setmove: '/v1/creatives/words/move',
  setindex: '/v1/creatives/words/index',
  setalike: '/v1/creatives/words/alike',
  setcreate: '/v1/creatives/words/create',
  setupdate: '/v1/creatives/words/update',
  setdetail: '/v1/creatives/words/detail',

  biDirectSubNodes: '/v1/raw-rpc?p=/bi/directSubNodes',
  biNodeInfo: '/v1/raw-rpc?p=/bi/nodeInfo',
  biTableData: '/v1/raw-rpc?p=/bi/tableData',
  biPagerData: '/v1/raw-rpc?p=/bi/pagerData',
  biConditionData: '/v1/raw-rpc?p=/bi/conditionData',
  biExportExcel: '/v1/raw-rpc?p=/bi/exportExcel',
  biConditionSearch: '/v1/raw-rpc?p=/bi/conditionSearch',
  biStaticData: '/v1/raw-rpc?p=/bi/staticData',

  advSetIndex: '/v1/creatives/set/index',
  advSetCreate: '/v1/creatives/set/create',
  advSetUpdate: '/v1/creatives/set/update',
  advSetMove: '/v1/creatives/set/move',
  landingPage: '/v1/landing-page',
  landingPageCheckApkUrl: '/v1/landing-page/check',

  offerReceivedPackage: '/v1/raw-rpc?p=/price/received-price/offer-geo-package',
  offerReceivedGeo: '/v1/raw-rpc?p=/price/received-price/offer-geo',

  ogReceivedPriceData: '/v1/raw-rpc?p=/price/og-received-price-index',
  ogReceivedPriceExport:
    '/v1/raw-rpc?p=/price/og-received-price-export&adv_offer_id={adv_offer_id}',
  ogReceivedPriceImport: '/v1/raw-rpc?p=/price/og-received-price-import',

  ogpReceivedPriceData: '/v1/raw-rpc?p=/price/ogp-received-price-index',
  ogpReceivedPriceExport:
    '/v1/raw-rpc?p=/price/ogp-received-price-export&adv_offer_id={adv_offer_id}',
  ogpReceivedPriceImport: '/v1/raw-rpc?p=/price/ogp-received-price-import',

  blockTargetAppImport: '/v1/batch/target-app/import',

  // 临时接口
  tmpPriceSwitch: '/v1/tmp/price-page-switch',

  // event
  userEventData: '/v1/adv/users/events',
  getEventListByUserId: '/v1/adv/event-mapping/list-package-all-events',
  addEventMapping: '/v1/adv/event-mapping/batch-add',
  checkEventExist: '/v1/adv/event-mapping/is-exist',
  eventMapping: '/v1/adv/event-mapping',
  eventList: '/v1/events',
  getThirdEvents: '/v1/adv/third/events',

  gifList: '/v1/endcard/gif',
  gifUpload: '/v1/endcard/gif',
  playturboToken: '/v1/playturbo/ec2gif/token',
  playturboSsid: '/v1/playturbo/ss-id',

  // click_mode
  serverSideClickTrackingMethod: '/v1/options/server-tracking-method',

  subUserData: '/v1/raw-rpc?p=/subAccount/GetAdvSubUser',
  subUserIndex: '/v1/raw-rpc?p=/subAccount/AdvSubUsersIndex',
  subUserAdd: '/v1/raw-rpc?p=/subAccount/AddAdvSubUser',
  subUserUpdate: '/v1/raw-rpc?p=/subAccount/ModifyAdvSubUser',
  subUserParentCampaign: '/v1/raw-rpc?p=/subAccount/GetParentUserCampaignList',
  subUserAssignableAmount:
    '/v1/raw-rpc?p=/subAccount/GetAdvUserAssignableAmount',
  subUserCampaign: '/v1/raw-rpc?p=/subAccount/GetSubUserCampaignList',
  subUserCheck: '/v1/raw-rpc?p=/subAccount/CheckAdvSubUser',
  subUserOpenSS: '/v1/raw-rpc?p=/subAccount/ModifyAdvSubUserIsOpenSS',

  // oss
  ossUploadParam: '/v1/raw-rpc?p=/rtdmp/GetUploadParam',
  audiencesIndex: '/v1/raw-rpc?p=/rtdmp/GetAdvAudienceList',
  updateAudiencesName: '/v1/raw-rpc?p=/rtdmp/UpdateAudienceName',
  deleteAudience: '/v1/raw-rpc?p=/target_audiences/ss-external',
  audiencesSimpleList: '/v1/raw-rpc?p=/rtdmp/GetAdvAudienceSimpleList',
  audiencesOptions: '/v1/raw-rpc?p=/rtdmp/GetAdvAudienceSimpleList',
  calAudiencesSave: '/v1/raw-rpc?p=/rtdmp/AddAdvAudienceCalculate',
  uploadAudience: '/v1/raw-rpc?p=/rtdmp/AddAdvAudienceUpload',
  audienceHit: '/v1/raw-rpc?p=/rtdmp/GetAudienceHits',

  deepTargetSuggest: '/v1/raw-rpc?p=/price/deep-target/suggest',

  mediationPostbackSettingIndex: '/v1/mediation/postback-settings',
  mediationPostbackSettingData: '/v1/mediation/postback-setting',
  // audience
  manageAdvAudienceList:
    '/v1/raw-rpc?p=/target_audience/adv/manageAdvAudienceList',
  advAudienceList: '/v1/raw-rpc?p=/target_audiences/SSAdvAudienceList',
  targetAudiencesIndex: '/v1/raw-rpc?p=/target_audiences/ss-index',
  presignedUploadData:
    '/v1/raw-rpc?p=/target_audiences/ss-presigned-upload-data',
  targetAudiencesAdd: '/v1/raw-rpc?p=/target_audiences/ss-external',
  accountsMessage: '/v1/accounts/message',

  creativeGroupList: '/v1/creative-group/list',
  creativeGroup: '/v1/creative-group',
  creativeLibrarylist: '/v1/creative-group/library-list',
  applyCreativeGroup: '/v1/creative-group/apply',
  checkAdTypeByCreativeGroup: '/v1/creative-group/check-allow-ad-type',
  creativeGroupCopy: '/v1/creative-group/copy'
}

export const getApiPath = (path, options) => {
  if ('string' != typeof path || !path) return
  path = path.split('.')
  let result = API
  for (let i = 0, len = path.length; i < len; i++) {
    result = result[path[i]]
  }
  if (!result) result = path

  const { fullPath, noDebugToken, useFastRailway } = options || {}
  if (fullPath && useFastRailway) {
    result = process.env.uploadBaseUrl + result
  } else if (fullPath) {
    result = process.env.apiBaseUrl + result
  }

  // pass debug token
  const { apiDebugToken } = process.env
  if (apiDebugToken && !noDebugToken && 'function' == typeof URLSearchParams) {
    const urlParams = new URLSearchParams(window.location.search)
    const value = urlParams.get(apiDebugToken)
    if (value) {
      result +=
        (-1 == result.indexOf('?') ? '?' : '&') + `${apiDebugToken}=${value}`
    }
  }

  return result
}

export default API

/**
 *
 * SS api 没有/adv 路径
 */
