<template>
  <el-dialog
    :visible.sync="visible"
    custom-class="nofitication-dialog"
    lock-scroll
    width="900px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onClose"
    @closed="onClosed"
  >
    <div class="content">
      <div v-html="content" class="break-keep"></div>
    </div>
    <div slot="footer" class="footer">
      <span>
        <el-checkbox v-model="noMore" style="line-height: 40px">
          <span
            style="cursor:pointer;font-size: 14px;"
            @click="noMore = !noMore"
          >
            {{ mxVm$t('dialog.pt.nomorereminders') }}
          </span></el-checkbox
        >
      </span>
      <el-button type="primary" @click="onConfirm">
        {{ mxVm$t('common.ok') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
const SS_MESSAGE_LIST_READ = 'SS_MESSAGE_LIST_READ'
export default {
  data() {
    return {
      visible: false,
      messageList: [],
      message: undefined,
      content: '',
      noMore: false
    }
  },
  created() {},
  async mounted() {
    const MarkdownIt = (await import('markdown-it')).default
    this.mdi = new MarkdownIt({
      html: true
    })
    const read = localStorage.getItem(SS_MESSAGE_LIST_READ)
    this.readMessages = read ? JSON.parse(read) : []
    this.fetchAndShow()
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.fetchAndShow()
      }
    }
  },
  methods: {
    onClose() {},
    onClosed() {},
    async fetchAndShow() {
      await this.fetchMessages()
      this.tryShowMessage()
    },
    async fetchMessages() {
      const { code, data } = await this.$axios.$get('accountsMessage', {
        __skipAutoNotify: true
      })
      if (code === 200) {
        this.messageList =
          data
            ?.map(item => ({
              id: item.id,
              name: item.name,
              message: item.msg
            }))
            .filter(item => !this.readMessages.includes(item.id)) ?? []
      }
    },
    tryShowMessage() {
      const msg = this.pickMessage()
      if (msg) {
        this.message = msg
        const text = msg.message
        this.content = this.mdi.render(text)
        this.visible = true
      }
    },
    pickMessage() {
      return this.messageList[this.messageList.length - 1]
    },
    readMessage() {
      if (this.message) {
        this.readMessages.push(this.message.id)
        localStorage.setItem(
          SS_MESSAGE_LIST_READ,
          JSON.stringify(this.readMessages)
        )
      }
    },
    onConfirm() {
      if (this.noMore) {
        this.readMessage()
      }
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  padding: 0 24px 24px;
  justify-content: space-between;
  align-content: center;
}
.title {
  color: #333;
  margin-bottom: 16px;
  line-height: 22px;
  font-weight: 700;
}
.content {
  margin: 24px 4px 0;
  padding: 0 20px;
  box-sizing: border-box;
  color: #333;
  // height: 209px;
  overflow-y: auto;
  font-size: 14px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .mask {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 10px;
    height: 20px;
  }
  .title {
    font-size: 16px;
  }
}
.close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 0.6;
  // font-size: 16px;
  height: 12px;
  width: 12px;
  background-image: url(~/assets/img/dialog/close@2x.png);
  background-size: cover;
  color: #fff;
  cursor: pointer;
}
</style>
<style lang="scss">
.nofitication-dialog {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  color: rgb(161, 167, 196);
  max-width: unset;
  line-height: 1.4;
  & .el-dialog__header {
    display: none;
  }
  & .el-dialog__footer,
  & .el-dialog__body {
    padding: 0;
  }
  & .el-dialog__body {
    color: rgb(161, 167, 196);
  }
}
</style>
