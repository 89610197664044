<template>
  <el-popover :placement="placement" :trigger="trigger" v-bind="$attrs">
    <i slot="reference" class="iconfont" :class="iconClass" :style="{ fontSize: size == 'small' ? '12px' : '16px' }"></i>
    <slot>{{ content }}</slot>
  </el-popover>
</template>
<script>
export default {
  // 其他 prop 同 element ui 组件 el-popover 属性
  props: {
    size: {
      type: String,
      default:  'default'
    },
    iconClass:{
      type: String,
      default:  'iconfont-info'
    },
    content: {
      type: String,
      default: '333'
    },
    placement: {
      type: String,
      default: 'top'
    },
    trigger: {
      type: String,
      default: 'hover'
    }
  }
}
</script>
