<template>
  <div>
    <el-dropdown>
      <div class="text-[24px] text-[#7e84a3] hover:text-[#00a68f]">
        <i class="el-icon-message relative top-[3px]" />
      </div>
      <el-dropdown-menu slot="dropdown" class="contact-dropdown">
        <el-dropdown-item v-if="$store.state.account.basicInfo.pm_name">
          <a :href="'mailto:self-service@mintegral.com'">
            <div
              class="p-2 text-xs rounded hover:bg-[#F5F6FA] w-[240px] cursor-pointer flex"
            >
              <div
                class="w-10 h-10 rounded-lg bg-[#00A68F] leading-10 text-center text-white"
              >
                AM
              </div>
              <div class="ml-2">
                <div class="text-[#333] leading-5 font-semibold">
                  <!-- {{ $store.state.account.basicInfo.pm_name }} -->
                  support(support)
                </div>
                <div class="text-[#999] leading-5">
                  <!-- {{ $store.state.account.basicInfo.pm_email }} -->
                  self-service@mintegral.com
                </div>
              </div>
            </div></a
          >
        </el-dropdown-item>
        <el-dropdown-item v-if="$store.state.account.basicInfo.bd_name">
          <a :href="'mailto:sales@mintegral.com'">
            <div
              class="p-2 text-xs rounded hover:bg-[#F5F6FA] w-[240px] cursor-pointer flex"
            >
              <div
                class="w-10 h-10 rounded-lg bg-[#4f6475] leading-10 text-center text-white"
              >
                BD
              </div>
              <div class="ml-2">
                <div class="text-[#333] leading-5 font-semibold">
                  <!-- {{ $store.state.account.basicInfo.bd_name }} -->
                  mtg_bd_public(mtg_bd)
                </div>
                <div class="text-[#999] leading-5">
                  <!-- {{ $store.state.account.basicInfo.bd_email }} -->
                  sales@mintegral.com
                </div>
              </div>
            </div>
          </a>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showEmail: false
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-bar /deep/ {
  // top: px2rem(160px);
  transition: right ease 0.5s;
  font-size: 0;
  // z-index: 2;

  .contact-bar__icon {
    // background-color: $--color-primary;
    border-radius: $border-radius-base 0 0 $border-radius-base;
    color: #7e84a3;
    width: 32px;
    font-size: 24px;
    &:hover {
      color: #00a68f;
    }
  }

  .contact-bar__info {
    background-color: #fff;
    font-size: 14px;
    border-radius: 0 0 0 $border-radius-base;
    width: 230px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  .contact-bar__info-icon {
    font-size: 10px;
    width: 22px;
    line-height: 22px;
    border-radius: 50%;
    background-color: $--color-primary;
    color: #fff;
  }

  .contact-bar--box-shadow {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .contact-bar__info--border-bottom {
    border-bottom: 1px solid #ebeef5;
  }

  .contact-bar__info--padding {
    padding: 5px 10px;
  }

  .contact-bar__info--line-height {
    line-height: 20px;
  }

  .f-vat {
    vertical-align: top;
  }
}
</style>

<style lang="scss" scoped>
.attract {
  &:hover {
    path {
      fill: #00a68f;
    }
  }
  &.unread {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #ea1b26;
    }
  }
}
.contact-dropdown {
  .el-dropdown-menu__item:not(.is-disabled):hover,
  .el-dropdown-menu__item:focus {
    background-color: unset;
    color: unset;
  }
  li.el-dropdown-menu__item {
    padding: 0;
  }
  &.el-dropdown-menu,
  .el-menu--popup {
    padding: 12px;
    border-radius: 8px;
  }
}
</style>
