import { Notification } from 'element-ui'
import qs from 'qs'
import { getApiPath } from '~/config/api.js'
import { formatQuery } from '~/utils'
import { apiMonitor } from '~/plugins/gio/gio-monitor'
import * as Sentry from '@sentry/vue'
function fullfillUrl(config) {
  if ('object' != typeof config || !config) return
  const { baseURL, url, params } = config
  let result = url
  if (0 != result.indexOf('http') && 0 != result.indexOf('//')) {
    const hasTailSlash = baseURL.length - 1 == baseURL.lastIndexOf('/')
    const hasPrefixSlash = 0 == result.indexOf('/')
    result =
      baseURL + (hasTailSlash && hasPrefixSlash ? result.substring(1) : result)
  }
  if (0 == result.indexOf('//')) {
    result = location.protocol + result
  }
  const query = formatQuery(params, true)
  if (query) {
    result += (-1 == result.indexOf('?') ? '?' : '&') + query
  }
  return result
}

function getStatusCode(status, statusText) {
  return status ? `${status} ${statusText}` : '--'
}

export default function({ $axios, app }) {
  // proxy $axios methods
  const proxyGenerator = function(method) {
    const $method = $axios[`$${method}`]

    $axios[`$${method}`] = async function() {
      try {
        const result = await $method.apply($axios, arguments)
        return result
      } catch (e) {
        const config = {
          baseURL: $axios.defaults.baseURL,
          url: arguments[0],
          params: (arguments[1] || {}).params
        }
        const api = fullfillUrl(config)
        const statusCode = getStatusCode()
        apiMonitor.onResponse(api, statusCode)
        // console.error('requset error', e)
        return e
      }
    }
  }
  const methods = [
    'request',
    'delete',
    'get',
    'head',
    'options',
    'post',
    'put',
    'patch'
  ]
  methods.forEach(proxyGenerator)

  $axios.onRequest(config => {
    // full fill
    if (0 != config.url.indexOf('http') && 0 != config.url.indexOf('//')) {
      config.url = getApiPath(config.url)
    }

    if (!config.url) {
      const errorCode = 404
      Notification.error({
        title: app.i18n.t('error.errorCode').replace('{code}', errorCode),
        message: app.i18n.t('error.clientSideError')
      })
      return
    }

    // add id for RESTful API
    if (config.__endOfUrl) {
      const tmp = config.url.split('?')
      tmp[0] += `/${config.__endOfUrl}`
      config.url = tmp.join('?')
      delete config.__endOfUrl
    }

    if (config.__id) {
      config.url = config.url.replace('{id}', config.__id)
      delete config.__id
    }
    if (config.__field) {
      config.url = config.url.replace('{field}', config.__field)
      delete config.__field
    }

    if (config.method == 'post' || config.method == 'put') {
      // TODO other solution
      if (config.__ctype == 'json') {
        config.headers['Content-Type'] = 'application/json; charset=utf-8'
        config.data = JSON.stringify(config.data)
      } else if (config.__ctype == 'file') {
        config.headers['Content-Type'] = 'multipart/form-data; charset=utf-8'
      } else {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        config.data = qs.stringify(config.data)
      }
    }
    const api = fullfillUrl(config)
    apiMonitor.onRequest(api)
  })

  $axios.onResponse(reponse => {
    const { config, status, statusText, data } = reponse
    if (config.isDownloadFile && status == 200) {
      return
    }
    const api = fullfillUrl(config)
    const statusCode = getStatusCode(status, statusText)
    apiMonitor.onResponse(api, statusCode)
    const { code, msg, message } = data
    const resData = data.data
    const route = app.router.history.current || {}
    const isNotSkipNotify =
      !config.__skipAutoNotify ||
      ('function' == typeof config.__skipAutoNotify &&
        !config.__skipAutoNotify(data))

    if (code != 200 && isNotSkipNotify) {
      delete config.__skipAutoNotify
      // auto notification, skip by setting __skipAutoNotify to true
      if (code == 400) {
        app.store.commit('account/setAuth', null)
        if (
          route.name &&
          !route.name.includes('login') &&
          !route.name.includes('signup')
        ) {
          app.router.push({
            path: app.localePath('login'),
            query: { referrer: route.fullPath }
          })
        }
        return
      }

      if (code == -401) {
        app.router.push('/account/complete-account')
        return
      }

      // 星耀分流
      if (code === -403) {
        const url = new URL(window.location.href)
        url.host = process.env.CREATIVE_GREY_DOMAIN
        window.location.href = url.href
        return
      }
      let mergedMessage = msg || message
      const sentryInfo = {
        api,
        params: config.params,
        data: config.data,
        status,
        statusText,
        code,
        msg,
        errordata: resData
      }

      Sentry.captureMessage(sentryInfo, 'error')

      if (resData) {
        mergedMessage = ''
        const dataType = Object.prototype.toString.call(resData)
        if ('[object Object]' == dataType) {
          for (const key in resData) {
            if ('string' == typeof resData[key]) {
              mergedMessage += `<p>${resData[key]}</p>`
            }
          }
        } else if ('[object Array]' == dataType) {
          mergedMessage = msg
        } else {
          mergedMessage += `<p>${resData}</p>`
        }
      }
      Notification.error({
        title: app.i18n.t('common.error'),
        dangerouslyUseHTMLString: true,
        message: mergedMessage
      })
    }
  })

  $axios.onError(error => {
    if (error && error.message === 'cancel') {
      return
    }
    const { config, status, statusText } = error.response || {}
    const api = fullfillUrl(config)
    const statusCode = getStatusCode(status, statusText)
    apiMonitor.onResponse(api, statusCode)
    Sentry.captureException(error)
    let messageKey = 'error.unknown'
    if (status >= 500) {
      messageKey = 'error.serverSide'
    } else if (status >= 400) {
      messageKey = 'error.clientSide'
    }

    const errorCode = status || statusCode
    Notification.error({
      title: app.i18n.t('error.errorCode').replace('{code}', errorCode),
      message: app.i18n.t(messageKey)
    })
  })
}
