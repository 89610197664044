// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/link/xmp_bg.svg?ignore");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/link/pt_bg.svg?ignore");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-53485566]:export{primaryColor:#00a68f;infoColor:#4f6475;headerBgColor:#000;headerTextColor:#131523;headerMenuActiveTextColor:#fff}.bgicon[data-v-53485566]{background-repeat:no-repeat;background-position:100% 100%}.xmp[data-v-53485566]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.pt[data-v-53485566]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
