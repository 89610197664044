<template>
  <div :class="{ 'f-dinb': !drag }">
    <div
      v-loading="isLoading"
      :class="`elm-file-upload${!drag ? ' f-dinb' : ''}`"
    >
      <div
        v-if="
          !multiple && 'picture-card' == listType && fileList && fileList.length
        "
        class="m-picture-card-item"
      >
        <img :src="fileList[fileList.length - 1][urlKey]" />
      </div>
      <el-upload
        v-if="!disabled"
        ref="upload"
        :with-credentials="true"
        :list-type="listType"
        :action="uploadUrl"
        :data="data"
        :name="name"
        :accept="parsedConfig.accept"
        :multiple="multiple"
        :drag="drag"
        :show-file-list="false"
        :file-list="fileList"
        :before-upload="beforeUpload"
        :on-success="onUploadSuccess"
        :on-error="onUploadError"
        :on-exceed="onExceed"
        :on-change="onChange"
        :before-remove="beforeRemove"
        :on-remove="onRemove"
        class="m-upload-file"
      >
        <div v-if="drag" class="u-upload-drag">
          <i class="el-icon-upload" />
          <div>{{ uploadTips || mxVm$t('action.dragUpload') }}</div>
        </div>
        <div v-else-if="'picture-card' == listType" class="u-upload-plus-txt">
          <i class="el-icon-plus" />
          {{ uploadTips || mxVm$t('action.upload') }}
        </div>
        <div v-else class="f-tal">
          <el-button type="info" size="medium" class="s-limit">
            {{ uploadTips || mxVm$t('action.upload') }}
          </el-button>
        </div>
      </el-upload>
      <ul v-if="showFileList && 'text' == listType" class="m-file-list">
        <li v-for="(item, index) in fileList" :key="index">
          <a
            v-if="item[urlKey]"
            :href="getPreviewLink(item[urlKey])"
            target="_blank"
          >
            {{ item[nameKey] || getCustomName(item[urlKey]) }}
          </a>
          <span v-else>{{ item[nameKey] || getCustomName(item[urlKey]) }}</span>
        </li>
      </ul>
    </div>
    <div v-if="'picture-card' == listType" class="m-upload-config">
      <div v-if="config[0].ext">
        {{ mxVm$t('offer.creativeAdTypeFormat') }}:
        {{ config[0].ext.join(', ') }}
      </div>
      <div v-if="config[0].dimension">
        {{ mxVm$t('offer.creativeDimension') }}:
        {{ config[0].dimension.join(', ') }}
      </div>
      <div v-if="config[0].size">
        {{ mxVm$t('offer.creativeAdTypeSize') }}:
        {{ config[0].size }}
      </div>
    </div>
    <div v-if="showProgress" class="progress-bar f-mt">
      <label>{{ mxVm$t('fileUpload.uploadProgress') }}</label>
      <div class="progress-wrap">
        <el-progress
          :text-inside="true"
          :stroke-width="18"
          :percentage="progressPercent"
          color="#009877"
        ></el-progress>
      </div>
    </div>
    <file-upload-result-dialog
      :data-source="dialogConfig.dataSource"
      :visible.sync="dialogConfig.visible"
    />
  </div>
</template>

<script>
import mime from 'mime'
// import md5 from 'md5'
import md5 from 'md5-webworker'
import { cloneDeep } from 'lodash-es'
import FileUploadResultDialog from './FileUploadResultDialog.vue'
import { getApiPath } from '~/config/api.js'

export default {
  components: {
    FileUploadResultDialog
  },
  props: {
    value: {
      type: [String, Array],
      default: undefined
    },
    useArray: {
      type: Boolean,
      default: false
    },
    actionKey: {
      type: String,
      default: 'filesUpload'
    },
    checkApiKey: {
      type: String,
      default: undefined
    },
    checkApiParams: {
      type: Object,
      default: undefined
    },
    checkValidator: {
      type: Function,
      default: undefined
    },
    data: {
      type: Object,
      default: undefined
    },
    urlKey: {
      type: String,
      default: 'url'
    },
    nameKey: {
      type: String,
      default: 'name'
    },
    listType: {
      type: String,
      default: 'text'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    drag: {
      type: Boolean,
      default: false
    },
    showFileList: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 100
    },
    typeTotal: {
      type: Object,
      default: undefined,
      validator: function(typeTotal = {}) {
        let isValid = true
        for (const type in typeTotal) {
          const total = typeTotal[type]
          if ('number' != typeof total || 0 > total) {
            isValid = false
          }
        }
        return isValid
      }
    },
    config: {
      type: Array,
      default: () => [],
      validator: function(config) {
        let isValid = true
        config.forEach(item => {
          if (!item || 'object' != typeof item) {
            isValid = false
          } else {
            const { type, ext, byte, size, regname, regnameTips } = item
            if (
              (type && 'string' != typeof type) ||
              (ext && !Array.isArray(ext)) ||
              (byte && 'number' != typeof byte) ||
              (size && 'string' != typeof size) ||
              (regname && !(regname instanceof RegExp)) ||
              (regnameTips && 'string' != typeof regnameTips)
            ) {
              isValid = false
            }
          }
        })
        return isValid
      }
    },
    name: {
      type: String,
      default: 'file'
    },
    uploadError: {
      type: Function,
      default: undefined
    },
    isCheckByServer: {
      type: Boolean,
      default: false
    },
    showProgress: {
      type: Boolean,
      default: false
    },
    useFastRailway: {
      type: Boolean,
      default: false
    },
    validateExt: {
      type: Boolean,
      default: false
    },
    isValidateDuration: {
      type: Boolean,
      default: false
    },
    uploadTips: {
      default: '',
      type: String
    }
  },
  data() {
    const handleQueue = []
    const handleConcurrent = 20
    return {
      headers: {
        ContentType: 'multipart/form-data'
      },
      metaList: undefined,
      metaValue: undefined,
      isLoading: false,
      dataResults: {
        counter: 0,
        total: 0,
        errors: [],
        typeTotal: {}
      },
      dialogConfig: {
        dataSource: undefined,
        visible: false
      },
      uploadList: [], // uploading file list
      handleQueue,
      handleConcurrent
    }
  },
  computed: {
    fileList: {
      get() {
        if (this.useArray && this.value) return this.value
        if (this.metaValue === this.value) return this.metaList
        return this.getFileList(this.value) || []
      },
      set(fileList) {
        const list = Object.assign([], fileList)
        const newList = []
        const oldValue = cloneDeep(this.value)
        list.forEach(file => {
          const { response = {} } = file
          if (200 == response.code) {
            Object.assign(file, response.data)
            response.data && newList.push(response.data)
            delete file.response // avoid recover fields
          }
        })

        this.metaList = list
        let data = ''
        if (this.useArray) {
          data = list
        } else {
          data = list.map(item => item[this.urlKey]) + ''
          this.metaValue = data
        }

        // pass value to parent
        this.$emit('change', data, oldValue, newList)
        this.$emit('input', data)
        this.$emit('finish-upload-result', list)
        // trigger validation
        this.dispatchChange(data)
      }
    },
    isMergeResults() {
      return this.multiple && this.drag
    },
    uploadUrl() {
      return getApiPath(this.actionKey, {
        fullPath: true,
        useFastRailway: this.useFastRailway
      })
    },
    parsedConfig() {
      const result = {
        defaultType: (this.config[0] || {}).type,
        accept: [],
        limit: 0
      }
      this.config.forEach(conf => {
        const {
          type,
          ext,
          accept = [],
          byte,
          size,
          dimension,
          regname,
          regnameTips,
          limit,
          nameValidator
        } = conf
        if (!result[type]) {
          result[type] = {
            ext,
            byte: 0,
            size: '',
            dimension: [],
            regname,
            regnameTips,
            limit: 0,
            nameValidator
          }
          result.limit += limit || 0
        }
        if (limit && result[type].limit < limit) {
          result[type].limit = limit
        }
        if (byte && result[type].byte < byte) {
          result[type].byte = byte
          result[type].size = size
        }
        if (dimension) {
          const dimensionArr = result[type].dimension.concat(dimension)
          result[type].dimension = Array.from(new Set(dimensionArr))
        }
        if (regname && !result[type].regname) {
          result[type].regname = regname
        }
        if (regnameTips && !result[type].regnameTips) {
          result[type].regnameTips = regnameTips
        }
        if (this.isValidateDuration && conf.durationLimit && type === 'video') {
          result[type].durationLimit = conf.durationLimit
        }
        if (accept.length) {
          result.accept.push(...accept)
        } else {
          ext.forEach(item => {
            const mimeType = mime.getType(item)
            if (-1 == result.accept.indexOf(mimeType)) {
              result.accept.push(mimeType)
            }
          })
        }
      })
      result.accept = Array.from(new Set(result.accept)) + ''
      return result
    },
    progressPercent() {
      const { counter, total } = this.dataResults
      if (!total) {
        return 0
      } else {
        return parseInt(((total - counter) / total) * 100)
      }
    }
  },
  methods: {
    reset() {
      const { upload } = this.$refs
      this.uploadList &&
        this.uploadList.forEach(file => {
          upload.abort(file)
        })
      upload.clearFiles()
      this.metaList = []
      this.uploadList = []
      this.handleQueue = []
      this.isLoading = false
      this.dataResults = { total: 0, counter: 0, errors: [], typeTotal: {} }
    },
    // setFileList(value) {
    //   const list = this.getFileList(value)
    //   this.metaList = list
    // },
    getFileList(value) {
      if (!value) return
      value = Array.isArray(value) ? value : value.split(',')
      const list = value.map(item => {
        if ('string' == typeof item) {
          item = { [this.urlKey]: item }
        }
        if (!item.uid) item.uid = item[this.urlKey]
        return item
      })
      return list
    },
    dispatchChange(params) {
      const componentName = 'ElFormItem'
      const eventName = 'el.form.change'
      let parent = this.$parent || this.$root
      let name = parent.$options.componentName
      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent
        if (parent) {
          name = parent.$options.componentName
        }
      }
      if (parent) {
        parent.$emit.apply(parent, [eventName].concat(params))
      }
    },
    getCustomName(url) {
      const urlSplits = (url && url.split('/')) || []
      return urlSplits[urlSplits.length - 1]
    },
    getPreviewLink(url) {
      if (url && 0 != url.indexOf('http') && 0 != url.indexOf('//')) {
        return '/files/preview?url=' + url
      }
      return url
    },
    getFileIndex(file, fileList) {
      let index = 0
      while (index < fileList.length) {
        if (file.uid == fileList[index].uid) break
        ++index
      }
      return index == fileList.length ? -1 : index
    },
    /**
     * 必须把video appendChild到document，不然在挂起的tab下,video不会去download资源。
     */
    getDimension(file) {
      return new Promise((resolve, reject) => {
        if (0 == file.type.indexOf('image')) {
          let img = new Image()
          img.onload = function() {
            resolve({
              width: this.naturalWidth,
              height: this.naturalHeight
            })
            img.src = ''
            img = null
          }
          img.onerror = reject
          img.src = URL.createObjectURL(file)
        } else {
          const self = this
          let video = document.createElement('video')
          video.width = 0
          video.height = 0
          video.style.display = 'none'
          video.onloadedmetadata = function() {
            if (!this.videoWidth || !this.videoHeight) {
              reject(new Error(self.mxVm$t('common.error')))
            } else {
              resolve({
                width: this.videoWidth,
                height: this.videoHeight
              })
            }
            document.body.removeChild(video)
            video = null
          }
          video.muted = true
          video.preload = 'meta'
          video.onerror = e => {
            document.body.removeChild(video)
            video = null
            reject(e)
          }
          video.src = URL.createObjectURL(file)
          document.body.appendChild(video)
        }
      })
    },
    checkDimension(file, dimension) {
      return new Promise((resolve, reject) => {
        this.getDimension(file)
          .then(({ width, height }) => {
            width = Math.round(width)
            height = Math.round(height)
            const d = `${width}x${height}`
            const checkList = [
              d, // original one placed first
              `${width}x${height + 1}`,
              `${width}x${height - 1}`,
              `${width + 1}x${height}`,
              `${width + 1}x${height + 1}`,
              `${width + 1}x${height - 1}`,
              `${width - 1}x${height}`,
              `${width - 1}x${height + 1}`,
              `${width - 1}x${height - 1}`
            ]
            let checkResult = false
            for (let i = 0; i < checkList.length; ++i) {
              if (-1 < dimension.indexOf(checkList[i])) {
                checkResult = true
                if (0 < i) {
                  // console.log(`${checkList[0]} => ${checkList[i]}`)
                }
                break
              }
            }
            if (!checkResult) {
              // error dimension
              resolve(d)
            } else {
              resolve()
            }
          })
          .catch(error => {
            // still pass to server
            console.log(error)
            resolve()
          })
      })
    },
    getFileSuffix(file) {
      const { name } = file
      return (name.split('.').pop() || '').toLowerCase()
    },
    getFileType(file, defaultType = 'text') {
      const type = file.type || (file.raw && file.raw.type)
      return type ? type.split('/')[0] : defaultType
    },
    onExceed(file, type) {
      let id = 'rule.uploadExceed'
      let values = {}
      const { parsedConfig = {} } = this || {}
      if ('string' == typeof type) {
        // type exceed
        values.num = (parsedConfig[type] || {}).limit || parsedConfig.limit
        if ('image' == type) {
          id = 'rule.uploadExceedImage'
        } else if ('video' == type) {
          id = 'rule.uploadExceedVideo'
        }
      } else if (parsedConfig.image && parsedConfig.video) {
        // type exceed
        id = 'rule.uploadExceedCreatives'
        values = {
          pictureNum: parsedConfig.image.limit,
          videoNum: parsedConfig.video.limit
        }
      } else {
        // normal exceed
        values = {
          num: this.limit
        }
      }
      const error = this.mxVm$t({ id, values })
      this.onUploadError(error, file)
    },
    checkByServer(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        // const fileByteArray = []
        fileReader.onloadend = async event => {
          if (FileReader.DONE == event.target.readyState) {
            // const arrayBuffer = event.target.result
            // const array = new Uint8Array(arrayBuffer)
            // const fileByteArray = []
            // for (let i = 0; i < array.length; i++) {
            //   fileByteArray.push(array[i])
            // }
            const hash = await md5(file)
            const response = await this.$axios.$get(this.checkApiKey, {
              params: {
                ...(this.checkApiParams || {}),
                file_md5: hash,
                file_size: file.size,
                file_name: file.name
              }
            })
            resolve(response)
          } else {
            reject(new Error(this.mxVm$t('fileUpload.readFileError')))
          }
        }
        fileReader.readAsArrayBuffer(file)
      })
    },
    getDuration(file) {
      return new Promise((resolve, reject) => {
        let video = document.createElement('video')
        video.width = 0
        video.height = 0
        video.style.display = 'none'
        video.onloadedmetadata = function() {
          if (!this.duration) {
            reject(new Error('Error'))
          } else {
            resolve(parseInt(this.duration))
          }
          document.body.removeChild(video)
          video = null
        }
        video.preload = 'meta'
        video.onerror = e => {
          document.body.removeChild(video)
          video = null
          reject(e)
        }
        video.src = URL.createObjectURL(file)
        document.body.appendChild(video)
      })
    },
    checkDuration(file, duration) {
      return new Promise((resolve, reject) => {
        this.getDuration(file)
          .then(res => {
            if (res > duration) {
              // error duration
              resolve(res)
            } else {
              resolve()
            }
          })
          .catch(error => {
            // still pass to server
            resolve()
          })
      })
    },
    beforeUpload(file) {
      const { accept, defaultType } = this.parsedConfig
      const type = this.getFileType(file, defaultType)
      const suffix = this.getFileSuffix(file)
      if (!this.dataResults.typeTotal[type]) {
        this.dataResults.typeTotal[type] = 0
      }
      ++this.dataResults.typeTotal[type]
      ++this.dataResults.total
      ++this.dataResults.counter
      const promise = new Promise((resolve, reject) => {
        this.$nextTick(async () => {
          const {
            byte,
            size,
            dimension,
            regname,
            regnameTips,
            limit,
            nameValidator,
            ext = [],
            durationLimit
          } = this.parsedConfig[type] || {}
          if (nameValidator) {
            try {
              await nameValidator(file)
            } catch (e) {
              this.onUploadError(e.message, file)
              return reject(e)
            }
          }
          const checkFileBeforeUpload = async () => {
            if (
              this.validateExt &&
              (!ext ||
                -1 ===
                  ext.findIndex(item => (item || '').toLowerCase() === suffix))
            ) {
              this.onLimitTypeError(file, accept)
              return reject(
                new Error(
                  this.mxVm$t({
                    id: 'fileUpload.fileTypeError',
                    values: { type: accept }
                  })
                )
              )
            }

            const typeTotal =
              ((this.typeTotal && this.typeTotal[type]) || 0) +
              this.dataResults.typeTotal[type]
            if (limit && limit < typeTotal) {
              this.onExceed(file, type)
              return reject(
                new Error(
                  this.mxVm$t({
                    id: 'fileUpload.typeLimitError',
                    values: {
                      type,
                      limit
                    }
                  })
                )
              )
            }

            if (regname && !regname.test(file.name.replace(/\.[^/.]+$/, ''))) {
              // error filename
              this.onLimitFilenameError(file, regnameTips)
              return reject(
                new Error(
                  this.mxVm$t({
                    id: 'fileUpload.fileNameError',
                    values: { name: file.name }
                  })
                )
              )
            }

            if (byte && byte < file.size) {
              // error size
              this.onLimitSizeError(file, size)
              return reject(
                new Error(
                  this.mxVm$t({
                    id: 'fileUpload.sizeError',
                    values: { size }
                  })
                )
              )
            }
            if (dimension && dimension.length) {
              const dimensionError = await this.checkDimension(file, dimension)
              if (dimensionError) {
                this.onLimitDimensionError(file, dimension.join(', '))
                return reject(
                  new Error(
                    this.mxVm$t({
                      id: 'fileUpload.dimensionError',
                      values: { dimensionError }
                    })
                  )
                )
              }
            }
            if (this.isValidateDuration && durationLimit) {
              const durationError = await this.checkDuration(
                file,
                durationLimit
              )
              if (durationError) {
                this.onLimitDurationError(file, durationLimit)
                return reject(new Error(`Dimension error: ${durationError}`))
              }
            }

            if (this.isCheckByServer) {
              return this.checkServer(file)
                .then(() => {
                  // prevent upload after close dialog
                  if (this.handleQueue.length) {
                    resolve()
                  } else {
                    this.isLoading = false
                    reject(new Error('Uplaod close!'))
                  }
                })
                .catch(reject)
            }

            this.isLoading = true

            return resolve()
          }
          const next = {
            file,
            callback: checkFileBeforeUpload,
            running: false
          }
          this.handleQueue.push(next)
          if (this.handleQueue.length <= this.handleConcurrent) {
            this.runCheck(next)
          }
        })
      })
        .then(() => {
          this.uploadList.push(file)
        })
        .finally(() => {
          this.runNextCheck(file)
        })
      return promise
    },
    // run next check callback
    runNextCheck(file) {
      if (!this.handleQueue.length) return
      const fileIndex = this.handleQueue.findIndex(item => file === item.file)
      if (-1 !== fileIndex) {
        this.handleQueue.splice(fileIndex, 1)
      }
      if (this.handleQueue.length) {
        const next = this.handleQueue.find(item => item.running === false)
        next && this.runCheck(next)
      }
    },
    runCheck(next) {
      const { callback } = next
      next.running = true
      callback()
    },
    checkServer(file) {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        this.checkByServer(file).then(
          res => {
            const { code, msg, data } = res || {}
            if (200 == code && data) {
              // reset field
              const str = 'YYYY-MM-DD HH:mm:ss'
              const { mxMoment } = this.$root
              data.created_at = mxMoment(new Date()).format(str)
              file.response = res
              file.status = 'reuse'
              const error = `Existing file: ${file.name}`
              return reject(new Error(error))
            } else {
              if (200 != code) {
                console.log('not existing file', msg)
              }
              return resolve()
            }
          },
          error => {
            console.log(error)
            return resolve()
          }
        )
      })
    },
    onLimitFilenameError(file, nameTips) {
      const error = nameTips
      return this.onUploadError(error, file)
    },
    onLimitSizeError(file, size) {
      const error = this.mxVm$t({
        id: 'rule.uploadMaxSize',
        values: { size }
      })
      this.onUploadError(error, file)
    },
    onLimitDimensionError(file, dimension) {
      const error = this.mxVm$t({
        id: 'rule.uploadDimension',
        values: { requirement: dimension }
      })
      this.onUploadError(error, file)
    },
    onLimitDurationError(file, duration) {
      const error = this.mxVm$t({
        id: 'rule.uploadDuration',
        values: { requirement: duration }
      })
      this.onUploadError(error, file)
    },
    // TODO check format
    onLimitTypeError(file, type) {
      const error = this.mxVm$t({
        id: 'rule.uploadType',
        values: { requirement: type }
      })
      this.onUploadError(error, file)
    },
    onUploadError(error, file, fileList) {
      // handle event instance when network error
      if (error instanceof Event) {
        const errorMsg = this.mxVm$t('common.uploadNetworkError')
        error = {
          msg: errorMsg,
          data: {
            value: errorMsg
          }
        }
      }
      if (!this.isMergeResults) {
        this.$notify({
          type: 'error',
          title: 'Error',
          message: error.msg || error
        })
      }

      if (fileList) {
        const index = this.getFileIndex(file, fileList)
        if (-1 < index) {
          fileList.splice(index, 1) // remove error file
        }
        this.fileList = fileList
      }
      this.dataResults.errors.push({
        name: file.name,
        error: error.data || error.msg || error
      })
      this.updateUploadList(file)
      this.checkFinishUpload()
    },
    onUploadSuccess(res, file, fileList) {
      const { code, msg, data } = res
      if (200 != code || !data) {
        this.onUploadError({ msg, data }, file, fileList)
      } else {
        if (!this.multiple) {
          // keep last one for single file mode
          this.fileList = fileList.slice(fileList.length - 1)
        } else {
          this.fileList = fileList
        }
        this.updateUploadList(file)
        this.checkFinishUpload()
      }
    },
    checkFinishUpload() {
      if (0 == --this.dataResults.counter) {
        // finish
        this.isLoading = false
        const results = Object.assign({}, this.dataResults)
        this.$emit('finish-upload', results)
        if (this.isMergeResults) {
          this.dialogConfig = {
            visible: true,
            dataSource: results
          }
        }
        this.dataResults = { total: 0, counter: 0, errors: [], typeTotal: {} }
      }
    },
    beforeRemove(file, fileList) {
      if (!file) return
      if (file.raw.status == 'reuse') {
        Object.assign(file, file.raw)
        if (
          !this.checkValidator ||
          this.checkValidator(file, file.response.data)
        ) {
          file.raw.status = 'success'
          this.onUploadSuccess(file.response, file, fileList)
          return false // keep file
        }
        const error = this.mxVm$t('common.duplicateUpload')
        this.onUploadError(error, file, fileList)
      }
    },
    onRemove(file, fileList) {
      // console.log('Remove: ', file, fileList)
    },
    onChange(file, fileList) {
      // console.log('Change: ', file, fileList)
    },
    updateUploadList(file) {
      const index = this.getFileIndex(file, this.uploadList)
      if (-1 < index) {
        this.uploadList.splice(index, 1) // remove error file from uploadList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.elm-file-upload /deep/ {
  .m-file-list {
    margin: 10px 0;
    padding: 0;
    list-style: none;
    line-height: 1.5;
  }

  .el-upload--text {
    display: block;
  }

  .m-picture-card-item,
  .el-upload-list--picture-card .el-upload-list__item,
  .el-upload--picture-card {
    width: 80px;
    height: 80px;
  }

  .m-picture-card-item {
    float: left;
    border-radius: $border-radius-base;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }

    + .m-upload-file {
      float: left;
      margin-left: 10px;
    }
  }

  .el-upload-list__item-status-label {
    display: none;
  }

  .el-upload-dragger {
    width: 100%;
  }

  .u-upload-drag {
    padding: 15px;
    color: $--border-color-base;

    .el-icon-upload {
      font-size: px2rem(68px);
    }

    &:hover {
      border-color: $--color-primary;
      color: $--border-color-hover;
    }
  }

  .el-upload--picture-card {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1.5;
    border-radius: $border-radius-base;
    border: 1px dashed $--border-color-base;
    overflow: hidden;
    color: $--border-color-base;
    text-align: center;
    cursor: pointer;

    &:hover {
      border-color: $--color-primary;
      color: $--border-color-hover;
      background-color: $--upload-bg-color;
    }

    .u-upload-plus-txt {
      margin: auto;
      font-size: 12px;
      line-height: 20px;
      .el-icon-plus {
        display: block;
        font-size: 14px;
        margin-top: 16px;
        margin-bottom: 10px;
        color: inherit;
      }
    }

    img {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      margin: auto;
    }
  }
}

.m-upload-config /deep/ {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  line-height: 1.5;
}

.progress-bar {
  display: flex;
  line-height: 20px;
  label {
    display: block;
    margin-right: 8px;
  }
  .progress-wrap {
    flex: 1;
  }
}
</style>
